import React, { FC } from 'react';
import cx from 'classnames';
import { API } from '@xbto/api-client';

export interface PasswordProgressProps {
  entropy: API.PasswordEntropyResponse | null;
  cls?: string;
}

const getVisualProgress = (strength: string) => {
  const blankStrength = {
    percent: 0,
    color: 'text-black',
    bgColor: 'bg-black',
  };

  switch (strength) {
    case 'Blank':
      return blankStrength;
    case 'Very Weak':
      return { percent: 25, color: 'text-failure', bgColor: 'bg-failure' };
    case 'Weak':
      return { percent: 50, color: 'text-failure', bgColor: 'bg-failure' };
    case 'Good':
      return { percent: 75, color: 'text-good', bgColor: 'bg-good' };
    case 'Strong':
      return { percent: 100, color: 'text-success', bgColor: 'bg-success' };
    default:
      return blankStrength;
  }
};

export const PasswordProgress: FC<PasswordProgressProps> = ({
  entropy,
  cls = '',
}) => {
  const { percent, color, bgColor } = getVisualProgress(
    entropy?.strength || ''
  );
  const strength = entropy?.strength || '';

  return (
    <div className={cx('h-10', cls)}>
      <div className="w-full bg-gray-50 mb-1 mt-2 rounded-2xl">
        <span
          className={cx('block rounded-2xl h-2', bgColor)}
          style={{
            width: percent + '%',
          }}
        />
      </div>
      <div className="text-gray-400 text-xs">
        Password strength:{' '}
        <span className={cx('font-semibold', color)}>{strength}</span>
      </div>
    </div>
  );
};
