var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement, useCallback, useMemo } from 'react';
import { Platform, TextInput as RNTextInput, View, } from 'react-native';
import { colors, createStyles, theme } from '../../theme';
import { Row } from '../row';
import { fontBoldStyle, fontMediumStyle } from '../text';
import { useInputFocusManager } from '../../hooks';
import { isWeb } from '../../utils';
const hitMargin = theme.spacing['5'];
const inputHitSlop = {
    top: hitMargin,
    bottom: hitMargin,
    left: hitMargin,
    right: hitMargin,
};
export const TextInput = props => {
    const { value, onValueChange, maxLength = 256, placeholder = 'Search', style = undefined, } = props;
    /**
     * Hooks
     */
    const { isFocused, onFocus, onBlur } = useInputFocusManager(props);
    /**
     * Methods
     */
    const handleOnChange = useCallback((newValue) => {
        if (!onValueChange) {
            return;
        }
        if (newValue === value) {
            return;
        }
        onValueChange(newValue);
    }, [value, onValueChange]);
    /**
     * DOM
     */
    const computedInputStyles = useMemo(() => {
        const styles = [$.textInput];
        if (isFocused && isWeb) {
            styles.push({
                // @ts-expect-error - web specific property
                outline: 'none',
            });
        }
        if (value === null || value === void 0 ? void 0 : value.length) {
            styles.push($.value);
        }
        else {
            styles.push($.placeholder);
        }
        return styles;
    }, [isFocused, value]);
    return (_jsx(InputLabel, Object.assign({ style: $.label }, { children: _jsx(Row.L, Object.assign({ px: "s", py: "xs", style: [
                $.inputContainer,
                style,
                isFocused && $.focused,
                // TODO(Hadrien): Manage disabled && inputStyles.disabled,
                // TODO(Hadrien): Manage error && inputStyles.error,
            ] }, { children: _jsx(RNTextInput, { style: computedInputStyles, value: value, onChangeText: handleOnChange, maxLength: maxLength, placeholder: placeholder, keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, keyboardAppearance: "dark", numberOfLines: 1, 
                // TODO(@benoit/ @hadrien): once we have tokens
                placeholderTextColor: "#B8B8B8" // theme.color.text.placeholder
                , selectionColor: colors.onTextInputEm, underlineColorAndroid: colors.transparent, onBlur: onBlur, onFocus: onFocus, hitSlop: inputHitSlop }) })) })));
};
// eslint-disable-next-line react/prop-types
const InputLabel = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return Platform.OS === 'web' ? (createElement('label', props, children)) : (_jsx(View, Object.assign({}, props, { children: children })));
};
// TODO: use theme / design token
const $ = createStyles({
    inputContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'rad1',
        backgroundColor: 'surfaceAlt',
        borderColor: 'outline',
        borderWidth: 1,
        maxHeight: Platform.select({ web: 52, default: 48 }),
        paddingVertical: Platform.select({ web: 16, default: 12 }),
        paddingHorizontal: Platform.select({ web: 16, default: 14 }),
    },
    textInput: {
        fontSize: Platform.select({ web: 14, default: 12 }),
        lineHeight: Platform.select({ web: 20, default: 16 }),
        height: 20,
        color: 'onTextInput',
        flexGrow: 1,
        backgroundColor: 'surfaceAlt',
        borderColor: 'transparent',
        borderRadius: 'rad1',
    },
    // @ts-expect-error web specific property
    label: Platform.select({ web: { cursor: 'pointer' }, default: {} }),
    focused: {
        borderColor: 'primary',
    },
    value: Object.assign({}, fontMediumStyle),
    placeholder: Object.assign({}, fontBoldStyle),
});
