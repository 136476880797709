import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { API } from '@xbto/api-client';
import { TextBody, Row } from '../../atoms';
import { Icon } from '../icon';
import { colors, theme } from '../../theme';
import { Hypertext } from '../hypertext';
function getTextColorName(variant) {
    // Brand
    if (variant === API.MessageTemplateAlertLevel.Brand) {
        return 'onBrandSurface';
    }
    // Error
    if (variant === API.MessageTemplateAlertLevel.Error) {
        return 'onErrorSurface';
    }
    // Success
    if (variant === API.MessageTemplateAlertLevel.Success) {
        return 'onSuccessSurface';
    }
    // Warning
    if (variant === API.MessageTemplateAlertLevel.Warning) {
        return 'onWarningSurface';
    }
    // Info (default)
    return 'onInfoSurface';
}
function getBackgroundColor(variant) {
    // Brand
    if (variant === API.MessageTemplateAlertLevel.Brand) {
        return colors.brandSurface;
    }
    // Error
    if (variant === API.MessageTemplateAlertLevel.Error) {
        return colors.errorSurface;
    }
    // Success
    if (variant === API.MessageTemplateAlertLevel.Success) {
        return colors.successSurface;
    }
    // Warning
    if (variant === API.MessageTemplateAlertLevel.Warning) {
        return colors.warningSurface;
    }
    // Info (default)
    return colors.infoSurface;
}
export const Banner = memo(({ id, level = API.MessageTemplateAlertLevel.Info, message, onDismiss, title, url, urlText = 'More information', }) => {
    // Vars
    const isStacked = ((title === null || title === void 0 ? void 0 : title.length) || 0) + ((message === null || message === void 0 ? void 0 : message.length) || 0) > 35;
    const backgroundColor = getBackgroundColor(level);
    const textColor = getTextColorName(level);
    const dismissable = !!onDismiss;
    // Handlers
    const handleDismiss = useCallback(() => {
        onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss(id);
    }, [id, onDismiss]);
    // Render
    return (_jsxs(View, Object.assign({ style: [$.container, { backgroundColor }] }, { children: [_jsxs(View, Object.assign({ style: isStacked ? $.contentStacked : $.content }, { children: [!!title && (_jsx(TextBody, Object.assign({ em: "600", color: textColor }, { children: title }))), _jsx(TextBody, Object.assign({ color: textColor, style: { flexWrap: 'wrap' }, numberOfLines: 2, ellipsizeMode: "tail" }, { children: message }))] })), _jsxs(Row.R, { children: [!!url && (_jsx(Hypertext, Object.assign({ color: textColor, style: $.link, numberOfLines: 1, ellipsizeMode: "tail", href: url, hrefAttrs: { target: '_blank' } }, { children: urlText }))), dismissable && (_jsx(Pressable, Object.assign({ onPress: handleDismiss, style: $.close }, { children: _jsx(Icon, { name: "close-x", size: "sm", color: textColor }) })))] })] })));
});
const $ = StyleSheet.create({
    container: {
        paddingVertical: theme.spacing['5'],
        paddingHorizontal: theme.spacing['6'],
        borderRadius: theme.radius.sm,
        margin: theme.spacing['6'],
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: theme.spacing['4'],
        maxWidth: '65%',
    },
    contentStacked: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '65%',
    },
    link: {
        maxWidth: 80,
    },
    close: {
        paddingLeft: theme.spacing['6'],
    },
});
