import is from 'is';
import { ERROR_MESSAGES } from '../constants';
import { API } from '@xbto/api-client';
import { ApiError } from '../store/api-error';

export const getApiErrorMessage = (
  error: Error | string | null | API.ApiResponse | ApiError | unknown
) => {
  if (error instanceof ApiError) {
    return error.message;
  }

  if (!error) {
    return ERROR_MESSAGES.DEFAULT;
  }

  if (is.string(error)) {
    return error as string;
  }
  if (
    is.object(error) &&
    // eslint-disable-next-line
    (error as API.ApiResponse).hasOwnProperty('errorMessage')
  ) {
    return (error as API.ApiResponse).errorMessage as string;
  }

  if (
    // eslint-disable-next-line
    (error as Error).hasOwnProperty('message')
  ) {
    return (error as Error).message;
  }

  return ERROR_MESSAGES.DEFAULT;
};
