import { API } from '@xbto/api-client';

export interface EnrichedActivityType {
  type: API.FilteringActivityLabel;
  label: string;
}

export const getActivityLabel = (type: API.FilteringActivityLabel): string => {
  return type.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ') as string;
};

export const ignoredActivityType = (type: API.FilteringActivityLabel) => {
  return type === API.FilteringActivityLabel.Payment;
};

export const getActivityTypes = () => {
  return Object.values(API.FilteringActivityLabel).map(
    type =>
      ({
        type,
        label: getActivityLabel(type),
      }) as EnrichedActivityType
  );
};

export const filterIgnoredActivityTypes = (
  payload: Partial<API.GetActivityTransactionsRequest>
) => {
  if (payload.activityLabels) {
    return {
      ...payload,
      activityLabels: payload.activityLabels.filter(
        value => !ignoredActivityType(value)
      ),
    };
  }

  return payload;
};

export const ACTIVITY_LABELS = Object.values(API.FilteringActivityLabel).filter(
  value => !ignoredActivityType(value)
);
