import { API } from '@xbto/api-client';
import {
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  isBefore,
} from 'date-fns';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import {
  factories,
  createBalanceChange,
  getApiErrorCode,
  getApiErrorMessage,
  runApi,
  createBalancesByListAccounts,
  createBalancesByAccount,
} from '../../utils';
import { DataModel } from '../data-store';
import {
  AdditionalOptionsXHR,
  ApiThunk,
  Injections,
  ThunkResult,
} from '../types';
import parseNumber from 'multi-number-parse';
import {
  AccountLevelSettings,
  ActionId,
  ChartSeriesType,
  ChartTimeRange,
  Enriched,
  EnrichedAccountDetail,
  EnrichedAllHoldings,
  EnrichedAssetHoldings,
  EnrichedCurrencyInformation,
  TradingViewChartData,
  BalanceChange,
  Balances,
} from '../../types';
import { PortfolioAccountsByType } from './types';
import {
  getBalanceHistoryChartingData,
  getChartingOptions,
} from '../../utils/charting-utils';
import {
  getAccountsByCapabilities,
  workflowMap,
} from '../../hooks/workflows/allowed-actions-and-accounts';
import { BaseModel, createBaseModel } from '../base-store';
import { API_ERROR_CODES, ERROR_MESSAGES } from '../../constants';

export interface PortfolioModel extends BaseModel {
  // reset
  resetStore: Thunk<PortfolioModel, undefined, Injections, DataModel>;

  allowedActions: Computed<PortfolioModel, ActionId[], DataModel>;

  // >> `<api>/list-accounts`
  _listAccounts: API.ListAccounts | null;
  _setListAccounts: Action<PortfolioModel, API.ListAccounts | null>;
  balanceChange: Computed<PortfolioModel, BalanceChange | null, DataModel>;
  getPortfolio: Thunk<
    PortfolioModel,
    AdditionalOptionsXHR & {
      accountId?: string;
      impersonatedAccountId?: string;
    },
    Injections,
    DataModel,
    Promise<void>
  >;

  // >> `<api>/list-all-assets-holdings`
  _allHoldings: API.GetAllAssetsHoldingsResponse | null;
  _setAllHoldings: Action<
    PortfolioModel,
    API.GetAllAssetsHoldingsResponse | null
  >;
  getAllHoldings: ApiThunk<
    PortfolioModel,
    void,
    API.GetAllAssetsHoldingsResponse
  >;
  allHoldings: Computed<PortfolioModel, EnrichedAllHoldings | null, DataModel>;
  getHoldingsCsv: Thunk<
    PortfolioModel,
    { accountId: string; impersonatedAccountId?: string },
    Injections,
    DataModel,
    Promise<ThunkResult<{ filename: string | undefined; blob: Blob }>>
  >;
  // balances
  portfolioBalances: Computed<PortfolioModel, Balances | null, DataModel>;
  accountBalances: Computed<PortfolioModel, Balances | null, DataModel>;
  /**
   * >> `accountsByType` - computed from internal state `_listAccounts`
   * This is a [] of pair type / accounts of this type
   * Eg:
   * [
   *  [accountType1, Enriched.ListAccountItem[]]
   *  [accountType2, Enriched.ListAccountItem[]]
   * ]
   * where `accountType` could be `interest`, `custody` etc.,
   */
  accountsByType: Computed<
    PortfolioModel,
    PortfolioAccountsByType | null,
    DataModel
  >;
  totalBalanceUsd: Computed<PortfolioModel, number, DataModel>;
  hasBalance: Computed<PortfolioModel, boolean>;
  accountIds: Computed<PortfolioModel, string[]>;
  accountNumbers: Computed<PortfolioModel, string[]>;
  accounts: Computed<PortfolioModel, Enriched.ListAccountItem[], DataModel>;
  findAccount: (
    accounts: Enriched.ListAccountItem[],
    accountId: string
  ) => Enriched.ListAccountItem | null;
  hasMultipleAccounts: Computed<PortfolioModel, boolean, DataModel>;
  hasAccountOfTypeCustody: Computed<PortfolioModel, boolean>;
  hasAccountOfTypeTrading: Computed<PortfolioModel, boolean>;
  hasAccountOfTypeFund: Computed<PortfolioModel, boolean>;
  hasAccountOfTypeProTrading: Computed<PortfolioModel, boolean, DataModel>;

  // >> `<api>/account-details`
  _accountDetail: API.AccountDetail | null;
  setAccountDetail: Action<PortfolioModel, API.AccountDetail | null>;
  getAccountDetail: Thunk<
    PortfolioModel,
    {
      accountId: string;
      impersonatedAccountId?: string;
    } & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<ThunkResult<EnrichedAccountDetail>>
  >;
  _accountLevelSettings: AccountLevelSettings | null;
  _setAccountLevelSettings: Action<PortfolioModel, AccountLevelSettings | null>;
  _getAccountLevelSettings: Thunk<
    PortfolioModel,
    { accountId: string } & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<void>
  >;

  accountBalanceHistorySeriesTypes: ChartSeriesType[];
  disableAccountBalanceHistorySelection: Computed<
    PortfolioModel,
    boolean,
    DataModel
  >;
  accountBalanceHistory: TradingViewChartData | null;
  _setAccountBalanceHistory: Action<
    PortfolioModel,
    TradingViewChartData | null
  >;
  getAccountBalanceHistory: Thunk<
    PortfolioModel,
    { accountId: string } & AdditionalOptionsXHR & {
        timeRange: ChartTimeRange | undefined;
        seriesType: ChartSeriesType | undefined;
      },
    Injections,
    DataModel,
    Promise<void>
  >;
  accountBalanceHistoryTimeRanges: Computed<
    PortfolioModel,
    ChartTimeRange[],
    DataModel
  >;
  accountDetail: Computed<
    PortfolioModel,
    EnrichedAccountDetail | null,
    DataModel
  >;

  // >> `<api>/list-asset-holdings`
  _assetHoldings: API.GetAssetHoldingsResponse | null;
  setAssetHoldings: Action<PortfolioModel, API.GetAssetHoldingsResponse | null>;
  getAssetHoldings: Thunk<
    PortfolioModel,
    {
      currencyCode: string;
    } & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<void>
  >;
  assetHoldings: Computed<
    PortfolioModel,
    EnrichedAssetHoldings | null,
    DataModel
  >;

  // >> `<api>/account/label/update`
  accountLabelsErrors: Record<string, string | null>;
  setAccountLabelError: Action<
    PortfolioModel,
    { accountId: string; error: string | null }
  >;
  updateAccountName: Thunk<
    PortfolioModel,
    {
      accountId: string;
      accountName: string;
    } & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<ThunkResult<void>>
  >;

  // >> `<api>/account-statement/list`
}

export const porfolioModel: PortfolioModel = {
  ...createBaseModel(),

  // reset
  resetStore: thunk(actions => {
    actions._setListAccounts(null);
    actions._setAllHoldings(null);
    actions.setAccountDetail(null);
    actions._setAccountLevelSettings(null);
    actions.setAssetHoldings(null);
  }),

  allowedActions: computed(
    [s => s.accounts, s => s.assetHoldings?.accounts],
    (portfolioAccounts, assetAccounts) => {
      const allowedActions: ActionId[] = [];

      for (const action in ActionId) {
        if (
          getAccountsByCapabilities({
            capabilities: workflowMap[action],
            portfolioAccounts,
            assetAccounts,
          })?.length
        ) {
          allowedActions.push(action as ActionId);
        }
      }
      return allowedActions;
    }
  ),

  // >> `<api>/list-accounts`
  _listAccounts: null,
  _setListAccounts: action((state, payload) => {
    state._listAccounts = payload;
  }),
  balanceChange: computed(
    [
      s => s._listAccounts,
      (_s, storeState) => storeState.metaData.fiatCurrencyCodes,
    ],
    (listAccounts, fiatCurrencyCodes) => {
      if (listAccounts) {
        return createBalanceChange(
          listAccounts.balanceChange24HoursUsd,
          listAccounts.balanceChange24HoursPercent,
          fiatCurrencyCodes
        );
      }

      return null;
    }
  ),
  getPortfolio: thunk(
    async (
      actions,
      { isBackgroundXHR = false, accountId, impersonatedAccountId },
      { injections: { apiClient }, getStoreActions }
    ) => {
      const storeActions = getStoreActions();

      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
        }

        const additionalHeaders = {
          ...(!!accountId && {
            'x-account-id': accountId,
          }),
          ...(!!impersonatedAccountId && {
            'x-impersonated-account-id': impersonatedAccountId,
          }),
        };
        apiClient.setAdditionalHeaders(additionalHeaders);
        const { isSuccessful, errorMessage, result } =
          await apiClient.organizationListAccounts();
        if (!isSuccessful || !result) {
          if (!isBackgroundXHR) {
            storeActions.setError(errorMessage);
          }
          return;
        }
        actions._setListAccounts(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),

  // >> `<api>/list-all-assets-holdings`
  _allHoldings: null,
  _setAllHoldings: action((state, payload) => {
    state._allHoldings = payload;
  }),
  getAllHoldings: thunk(async (actions, _payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getAllAssetsHoldings();
      },
      result => {
        actions._setAllHoldings(result);
      }
    );
  }),
  allHoldings: computed(
    [
      s => s._allHoldings,
      (_s, storeState) => storeState.metaData.currencies,
      (_s, storeState) => storeState.metaData.fiatCurrencyCodes,
      (_s, storeState) => storeState.settings.globalAppSettings,
      (_s, storeState) => storeState.client,
    ],
    (_holdings, currencies, fiatCurrencyCodes, globalAppSettings, client) => {
      return factories.enrichAllHoldings(
        _holdings,
        currencies,
        fiatCurrencyCodes,
        globalAppSettings,
        client
      );
    }
  ),
  getHoldingsCsv: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();

      try {
        storeActions.setBusy(true);
        storeActions.setError(null);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': payload.accountId,
          ...(!!payload.impersonatedAccountId && {
            'x-impersonated-account-id': payload.impersonatedAccountId,
          }),
        });

        const response = await injections.apiClient.getAccountHoldingsCsv();

        storeActions.setBusy(false);
        return {
          isSuccessful: true,
          result: response as unknown as {
            filename: string | undefined;
            blob: Blob;
          },
        };
      } catch (error) {
        const errorMessage = getApiErrorMessage(error);
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return {
          isSuccessful: false,
          errorMessage,
        };
      }
    }
  ),
  portfolioBalances: computed([s => s._listAccounts], data => {
    if (!data) {
      return null;
    }

    return createBalancesByListAccounts(data);
  }),
  accountBalances: computed([s => s.accountDetail], accountDetail => {
    if (!accountDetail) {
      return null;
    }

    return createBalancesByAccount(accountDetail);
  }),
  accountsByType: computed(
    [
      s => s._listAccounts,
      (_state, storeState) => storeState.metaData.fiatCurrencyCodes,
    ],
    (_listAccounts, fiatCurrencyCodes) => {
      if (!_listAccounts?.accounts || !_listAccounts.accounts.length) {
        return null;
      }
      const result = _listAccounts.accounts.reduce((out, accountItem) => {
        const accountType = accountItem.account?.accountType;
        if (!accountType) {
          return out;
        }
        if (!out.closed) {
          out['closed' as const] = [];
        }
        if (!out[accountType]) {
          out[accountType as API.AccountType] = [];
        }
        out[accountItem.account?.closed ? 'closed' : accountType].push(
          factories.enrichListAccountItem(accountItem, fiatCurrencyCodes)
        );
        return out;
      }, {});
      return [...Object.keys(API.AccountType), 'closed']
        .map(type => {
          return [type, result[type]];
        })
        .filter(
          ([, accounts]) => !!accounts?.length
          // TODO(Hadrien): Fix type
        ) as PortfolioAccountsByType;
    }
  ),
  totalBalanceUsd: computed([s => s._listAccounts], _listAccounts => {
    return parseNumber(_listAccounts?.totalPortfolioBalanceUsd ?? '0');
  }),
  hasBalance: computed([s => s.accounts], accounts => {
    return accounts.some(_account => _account.hasBalance);
  }),
  accountIds: computed([s => s.accounts], accounts => {
    return accounts
      .filter(_account => !!_account.account?.accountId)
      .map(_account => _account.account?.accountId) as string[];
  }),
  accountNumbers: computed([s => s.accounts], accounts => {
    return accounts
      .filter(_account => !!_account.account?.accountNumber)
      .map(_account => _account.account?.accountNumber) as string[];
  }),
  accounts: computed(
    [
      s => s._listAccounts,
      (_state, storeState) => storeState.metaData.fiatCurrencyCodes,
    ],
    (_listAccounts, fiatCurrencyCodes) => {
      return (_listAccounts?.accounts ?? [])
        .map(_account =>
          factories.enrichListAccountItem(_account, fiatCurrencyCodes)
        )
        .sort((a, b) => {
          const aClosed = Number(a.account?.closed ?? false);
          const bClosed = Number(b.account?.closed ?? false);

          return aClosed - bClosed;
        });
    }
  ),
  findAccount: (accounts, accountId) =>
    (accounts.find(
      ({ account }) =>
        account &&
        (account.accountNumber === accountId || account.accountId === accountId)
    ) as Enriched.ListAccountItem) || null,
  hasMultipleAccounts: computed([s => s.accounts], _accounts => {
    return (_accounts || []).length > 1;
  }),
  hasAccountOfTypeCustody: computed([s => s.accounts], accounts => {
    return !!accounts.find(
      a =>
        a.account?.accountType === API.AccountType.Custody ||
        a.account?.accountType === API.AccountType.Vault
    );
  }),
  hasAccountOfTypeTrading: computed(
    [s => s.accounts],
    accounts =>
      !!accounts.find(a => a.account?.accountType === API.AccountType.Interest)
  ),
  hasAccountOfTypeProTrading: computed(
    [(_store, storeState) => storeState.user.identity],
    _identity => {
      return !!_identity?.enableTalosTrading;
    }
  ),
  hasAccountOfTypeFund: computed(
    [s => s.accounts],
    accounts =>
      !!accounts.find(a => a.account?.accountType === API.AccountType.Fund)
  ),

  // >> `<api>/account-details`
  _accountDetail: null,
  setAccountDetail: action((state, payload) => {
    state._accountDetail = payload;
    state.accountBalanceHistory = null;
  }),
  getAccountDetail: thunk(
    async (
      actions,
      { accountId, impersonatedAccountId = undefined, isBackgroundXHR = false },
      { injections: { apiClient }, getState, getStoreActions, getStoreState }
    ) => {
      const storeState = getStoreState();
      const storeActions = getStoreActions();
      const state = getState();
      try {
        actions.setBusy(true);

        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
        }
        const additionalHeaders = {
          'x-account-id': accountId,
          ...(!!impersonatedAccountId && {
            'x-impersonated-account-id': impersonatedAccountId,
          }),
        };
        apiClient.setAdditionalHeaders(additionalHeaders);
        // TODO: use payload to pass accountNumber to API
        const { isSuccessful, errorMessage, result } =
          await apiClient.getAccountDetails();

        if (isSuccessful && result) {
          actions.setAccountDetail(result);
          actions._getAccountLevelSettings({
            accountId,
            isBackgroundXHR,
          });
          actions.getAccountBalanceHistory({
            accountId,
            isBackgroundXHR,
            timeRange:
              state.accountBalanceHistory?.timeRange || ChartTimeRange.M1,
            seriesType:
              state.accountBalanceHistory?.seriesType || ChartSeriesType.Area,
          });

          const enrichedResult = factories.enrichAccountDetail(
            storeState.client,
            result,
            storeState.metaData.currencies,
            storeState.metaData.fiatCurrencyCodes,
            state.accounts,
            storeState.settings.globalAppSettings,
            state._accountLevelSettings
          );

          return {
            isSuccessful: true,
            result: enrichedResult,
          };
        }

        if (!isBackgroundXHR) {
          storeActions.setError(errorMessage);
        }

        return {
          isSuccessful: false,
          errorMessage,
        };
      } catch (error) {
        const message = getApiErrorMessage(error);

        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
        if (
          (error as any)?.status === 400 &&
          [
            API_ERROR_CODES.INVALID_ACCOUNT_ID,
            API_ERROR_CODES.UNKNOWN_ACCOUNT_ID,
          ].includes((error as any)?.code)
        ) {
          actions.setError(ERROR_MESSAGES.INVALID_ACCOUNT_ID);
        }

        return {
          isSuccessful: false,
          errorMessage: message,
        };
      } finally {
        storeActions.setBusy(false);
        actions.setBusy(false);
      }
    }
  ),
  _accountLevelSettings: null,
  _setAccountLevelSettings: action((state, payload) => {
    state._accountLevelSettings = payload;
  }),
  _getAccountLevelSettings: thunk(
    async (
      actions,
      { accountId, isBackgroundXHR = false },
      { injections: { apiClient }, getStoreActions }
    ) => {
      const storeActions = getStoreActions();
      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
        }

        apiClient.setAdditionalHeaders({
          'x-account-id': accountId,
        });
        const { isSuccessful, errorMessage, result } =
          await apiClient.getAccountSettings();

        if (!isSuccessful || !result) {
          if (!isBackgroundXHR) {
            storeActions.setError(errorMessage);
          }
          return;
        }

        const settings: AccountLevelSettings = {
          canEarnYield: result.canEarnYield,
          showYieldPrompt: result.showYieldPrompt,
          hasDepositedEver: result.hasDepositedEver,
          hasDepositedCryptoEver: result.hasDepositedCryptoEver,
          hasDepositedFiatEver: result.hasDepositedFiatEver,
          showFireblocksAlert: result.showFireblocksAlert,
          allowStabletag: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
          ask2FaForFiatWithdrawals: result.ask2FaForFiatWithdrawals,
          allowDeposits: result.allowDeposits,
        };
        actions._setAccountLevelSettings(settings);
      } catch (error) {
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),

  accountBalanceHistorySeriesTypes: [ChartSeriesType.Area],
  disableAccountBalanceHistorySelection: computed(
    [state => state.accountBalanceHistory],
    data => {
      return !(data && !data.errored && data.areaChartDataPoints);
    }
  ),
  accountBalanceHistory: null,
  _setAccountBalanceHistory: action((state, payload) => {
    state.accountBalanceHistory = payload;
  }),
  getAccountBalanceHistory: thunk(
    async (
      actions,
      { isBackgroundXHR = false, accountId, timeRange, seriesType },
      { injections: { apiClient }, getStoreActions, getState, getStoreState }
    ) => {
      const storeActions = getStoreActions();

      const state = getState();
      const storeState = getStoreState();
      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
        }

        apiClient.setAdditionalHeaders({
          'x-account-id': accountId,
        });
        const options = getChartingOptions(
          timeRange,
          'balance'
        ) as API.GetAccountBalanceHistoryRequest;

        if (state.accountDetail?.balanceHistoryStartDate) {
          const { isSuccessful, errorMessage, result } =
            await apiClient.getAccountBalanceHistory({
              ...options,
              startDate: isBefore(
                new Date(options.startDate),
                new Date(state.accountDetail?.balanceHistoryStartDate)
              )
                ? state.accountDetail?.balanceHistoryStartDate
                : options.startDate,
            });

          if (!isSuccessful || !result) {
            if (!isBackgroundXHR) {
              storeActions.setError(errorMessage);
            }
            return;
          }

          const chartData = getBalanceHistoryChartingData(
            result,
            state.accountDetail?.totalBalanceIncludingPendingUsd,
            storeState.metaData.fiatCurrencyCodes
          );
          const data: TradingViewChartData = {
            priceAxisTitle: '',
            timeRange,
            areaChartDataPoints: chartData?.areaChartDataPoints || null,
            decimalPrecision: chartData?.decimalPrecision,
            errored: false,
            seriesType,
          };
          actions._setAccountBalanceHistory(data);
        }
      } catch (error) {
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),
  accountBalanceHistoryTimeRanges: computed(
    [s => s.accountDetail],
    accountDetail => {
      const result: ChartTimeRange[] = [];
      if (!accountDetail) {
        return result;
      }

      const today = new Date();
      const startDate = new Date(accountDetail.balanceHistoryStartDate);

      if (!accountDetail.isAccountOfTypeFund) {
        const hours = differenceInHours(today, startDate);
        if (hours >= 1) {
          result.push(ChartTimeRange.H1);
        }
      }

      if (!accountDetail.isAccountOfTypeFund) {
        const days = differenceInDays(today, startDate);
        if (days >= 1) {
          result.push(ChartTimeRange.H24);
        }
      }

      if (!accountDetail.isAccountOfTypeFund) {
        const weeks = differenceInWeeks(today, startDate);
        if (weeks >= 1) {
          result.push(ChartTimeRange.W1);
        }
      }

      const months = differenceInMonths(today, startDate);
      if (months >= 1) {
        result.push(ChartTimeRange.M1);
      }
      if (months >= 3) {
        result.push(ChartTimeRange.M3);
      }

      const years = differenceInYears(today, startDate);
      if (months >= 1) {
        result.push(ChartTimeRange.Y1);
      }
      if (years >= 3) {
        result.push(ChartTimeRange.Y3);
      }

      return result;
    }
  ),

  accountDetail: computed(
    [
      (_s, storeState) => storeState.client,
      s => s._accountDetail,
      (_s, storeState) => storeState.metaData.currencies,
      s => s._accountLevelSettings,
      (_s, storeState) => storeState.metaData.fiatCurrencyCodes,
      s => s.accounts,
      (_s, storeState) => storeState.settings.globalAppSettings,
    ],
    (
      client,
      _accountDetail,
      currencies,
      _accountLevelSettings,
      fiatCurrencyCodes,
      accounts,
      globalAppSettings
    ) => {
      if (!_accountDetail || !currencies) {
        return null;
      }

      return factories.enrichAccountDetail(
        client,
        _accountDetail,
        currencies,
        fiatCurrencyCodes,
        accounts,
        globalAppSettings,
        _accountLevelSettings
      );
    }
  ),

  // >> `<api>/list-asset-holdings`
  _assetHoldings: null,
  setAssetHoldings: action((state, payload) => {
    state._assetHoldings = payload;
  }),
  getAssetHoldings: thunk(
    async (
      actions,
      { isBackgroundXHR = false, currencyCode },
      { injections: { apiClient }, getStoreActions, getState }
    ) => {
      const storeActions = getStoreActions();
      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
        }

        const { assetHoldings } = getState();

        if (currencyCode !== assetHoldings?.currency.code) {
          actions.setAssetHoldings(null);
        }

        // TODO: use payload to pass accountNumber to API
        const { isSuccessful, errorMessage, result } =
          await apiClient.getAssetHoldings({
            currencyCode,
          });

        if (!isSuccessful || !result) {
          storeActions.setError(errorMessage);
          return;
        }
        actions.setAssetHoldings(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),
  assetHoldings: computed(
    [
      s => s._assetHoldings,
      (_s, storeState) => storeState.metaData.currencies,
      (_s, storeState) => storeState.metaData.fiatCurrencyCodes,
    ],
    (
      _assetHoldings,
      currencies: EnrichedCurrencyInformation[],
      fiatCurrencyCodes
    ) => {
      return factories.enrichAssetHoldings(
        _assetHoldings,
        currencies,
        fiatCurrencyCodes
      );
    }
  ),

  // << `<api>/account/label/update`
  accountLabelsErrors: {},
  setAccountLabelError: action((state, payload) => {
    state.accountLabelsErrors[payload.accountId] = payload.error;
  }),
  updateAccountName: thunk(
    async (
      actions,
      { isBackgroundXHR = false, accountId, accountName },
      { injections: { apiClient }, getStoreActions }
    ) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setError(null);
        actions.setAccountLabelError({
          accountId,
          error: null,
        });
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
        }

        apiClient.setAdditionalHeaders({
          'x-account-id': accountId,
        });

        const { isSuccessful, errorMessage } = await apiClient.updateLabel({
          label: accountName,
        });
        await actions.getPortfolio({ isBackgroundXHR: true });

        if (!isSuccessful || errorMessage) {
          storeActions.setError(errorMessage);
          actions.setAccountLabelError({
            accountId,
            error: errorMessage,
          });
          return {
            isSuccessful: false,
            errorMessage,
          };
        }

        return {
          isSuccessful,
        };
      } catch (error) {
        const errorCode = getApiErrorCode(error);
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        actions.setAccountLabelError({
          accountId,
          error: message,
        });
        return {
          isSuccessful: false,
          errorCode,
          errorMessage: message,
        };
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),
};
