import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, Pressable, View } from 'react-native';
import { shift, useFloating } from '@floating-ui/react-native';
import debounce from 'lodash.debounce';
import { TIMERS } from 'common';
import { createStyles } from '../../theme';
import { drop2 } from '../../utils/box-shadow';
import { TextInput, TextBody } from '../../atoms';
/**
 * /!\ React Native Web forces a zIndex:0 on all views,
 * making stacking context VERY complicated to handle/break free of on web :
 * https://github.com/necolas/react-native-web/discussions/2547#discussioncomment-9327102
 *
 * This component requires its parents to have a zIndex > 0 to allow floating ui popover to display correctly
 * */
export const SearchSuggestInput = ({ maxLength, minLength = 3, onChange, onSelect, style, initialValue, suggestions, }) => {
    const { refs, floatingStyles } = useFloating({
        middleware: [shift()],
    });
    /**
     * State
     */
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [innerValue, setInnerValue] = useState(initialValue);
    /**
     * Methods
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(debounce(onChange, TIMERS.INPUT_DEBOUNCE), [onChange]);
    const handleValueChange = useCallback((newValue) => {
        setInnerValue(newValue);
        if (newValue.length >= minLength) {
            debouncedOnChange(newValue);
        }
    }, [debouncedOnChange, minLength]);
    const onFocus = useCallback(() => setSuggestionsOpen(true), [setSuggestionsOpen]);
    const timeout = useRef();
    const onBlur = useCallback(() => {
        clearTimeout(timeout.current);
        // this timeout is to allow state update before closing the list on web
        timeout.current = setTimeout(() => setSuggestionsOpen(false), 200);
    }, [setSuggestionsOpen]);
    useEffect(() => {
        return () => clearTimeout(timeout.current);
    }, []);
    const onSelectSuggestion = useCallback((suggestion) => () => {
        setInnerValue(suggestion.label);
        onSelect === null || onSelect === void 0 ? void 0 : onSelect(suggestion);
        Keyboard.dismiss();
    }, [onSelect]);
    /**
     * DOM
     */
    return (_jsxs(View, Object.assign({ ref: refs.setReference, collapsable: false, style: $.suggestionContainer }, { children: [_jsx(TextInput, { value: innerValue, maxLength: maxLength, style: style, onValueChange: handleValueChange, onFocus: onFocus, onBlur: onBlur }), suggestionsOpen && !!(suggestions === null || suggestions === void 0 ? void 0 : suggestions.length) && (_jsx(View, Object.assign({ ref: refs.setOffsetParent, collapsable: false }, { children: _jsx(View, Object.assign({ ref: refs.setFloating, collapsable: false, style: [floatingStyles, $.suggestions] }, { children: suggestions === null || suggestions === void 0 ? void 0 : suggestions.map(suggestion => (_jsx(Pressable, Object.assign({ onPress: onSelectSuggestion(suggestion) }, { children: _jsx(TextBody, Object.assign({ color: "onTextInput" }, { children: suggestion.label })) }), suggestion.id))) })) })))] })));
};
// TODO: use theme / design token
const $ = createStyles({
    // zIndex needed for mobile floating-ui to overlay on top
    suggestionContainer: { position: 'relative', zIndex: 1 },
    suggestions: Object.assign({ width: '100%', rowGap: 8, marginVertical: 2, padding: 16, backgroundColor: 'onSurfaceInv', borderRadius: 'rad1', borderColor: 'outline', borderWidth: 1 }, drop2),
});
