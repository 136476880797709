import { API } from '@xbto/api-client';
import { ERROR_MESSAGES } from '../../../constants';
import { validateAmountWithInBalance } from '../../validations/amount-within-balance';
import { WithdrawCashFormikField, WithdrawCashFormikProps } from './types';
import { isCountryAuthorized } from '../../lists/countries';

const validate = async ({
  fromAsset,
  amount,
  bankAccount,
  description,
}: WithdrawCashFormikProps) => {
  const errors: Record<string, unknown> = {};

  // from fund
  if (fromAsset) {
    const hasBalance = !!fromAsset.hasBalance;
    if (!hasBalance) {
      errors[WithdrawCashFormikField.fromAsset] =
        ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  // amount
  if (amount) {
    if (Number(amount) <= 0) {
      errors[WithdrawCashFormikField.amount] =
        ERROR_MESSAGES.AMOUNT_MORE_THAN_0;
    }
    const isWithinBalance = validateAmountWithInBalance(amount, fromAsset);
    if (!isWithinBalance) {
      errors[WithdrawCashFormikField.amount] =
        ERROR_MESSAGES.INSUFFICIENT_BALANCE;
    }
  }

  // bank account
  if (bankAccount) {
    if (bankAccount.status !== API.CustomerBankAccountStatus.Ready) {
      errors[WithdrawCashFormikField.bankAccount] =
        ERROR_MESSAGES.INVALID_BANK_ACCOUNT_NOT_VERIFIED;
    }

    if (bankAccount.bankAccountDetails) {
      if (!isCountryAuthorized(bankAccount.bankAccountDetails.bankCountry)) {
        errors[WithdrawCashFormikField.bankAccount] =
          ERROR_MESSAGES.UNAUTHORIZED_COUNTRY;
      }
    }
  }

  if (description) {
    if (description.length > 255) {
      errors[WithdrawCashFormikField.description] =
        ERROR_MESSAGES.MAX_255_CHARACTERS;
    }
  }

  return errors;
};

export const validateWithdrawCashFormik = validate;
