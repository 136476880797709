import { Helpers } from 'easy-peasy';
import { DataModel } from '../store/data-store';
import { Injections } from '../store/types';
import { runThunkUnmanaged, ICallback, Response } from './run-thunk-unmanaged';

/**
 * @deprecated
 */
export const runThunk = async <TResponse extends Response>(
  helpers: Helpers<object, DataModel, Injections>,
  callback: ICallback<TResponse>,
  setBusy = true,
  clearError = true,
  headers: Record<string, unknown> | null = null
) => {
  return await runThunkUnmanaged(
    helpers,
    {
      execute: async () => {
        const response = (await callback.execute()) as TResponse;
        const { isSuccessful, errorMessage, result, errorCode } = response;

        if (!isSuccessful || !result) {
          if (callback.onError) {
            callback.onError(errorMessage!, errorCode!);
          }
        } else {
          callback.onSucccess(response as TResponse);
        }

        return { isSuccessful, errorMessage, errorCode };
      },
      onError: (message, errorCode) => {
        if (callback.onError) {
          callback.onError(message, errorCode);
        }
      },
    },
    setBusy,
    clearError,
    headers
  );
};
