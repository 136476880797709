import { API } from '@xbto/api-client';
import { humanize } from './string-utils';

export const invoiceTypes = Object.values(API.InvoiceItemType);

export const invoiceTypeLabels: string[] = [];

export const invoiceTypeOptions = invoiceTypes.map(value => {
  const label = humanize(value);

  invoiceTypeLabels.push(label);

  return { label, value };
});
