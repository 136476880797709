import { API } from '@xbto/api-client';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getApiErrorMessage } from '../../utils/get-api-error-message';
import { DataModel } from '../data-store';
import { Injections } from '../types';
import { AddCashFormikProps } from '../../utils/formik/add-cash/types';
import { Enriched, EnrichedCurrency } from '../../types';
import { sortCurrencies } from '../../utils/sort-funds';
import { SORT_ASSETS_BY, SORT_DIR } from '../../config';
import { BaseModel, createBaseModel } from '../base-store';
import { factories } from '../../utils';
import { getAccountsByWorkflow } from '../../hooks/workflows/allowed-actions-and-accounts';

export interface AddCashModel extends BaseModel {
  // state
  formValues: AddCashFormikProps | null;
  details: API.FiatDepositDetails | null;
  checkoutSimulationCounter: number; // prevents stale value from being displayed if a request takes longer than expected

  // computed
  allowedCurrencies: Computed<AddCashModel, EnrichedCurrency[], DataModel>;
  allowedCanAddCashAccounts: Computed<
    AddCashModel,
    Enriched.ListAccountItem[],
    DataModel
  >;

  // actions
  setCounter: Action<AddCashModel, number>;
  setFormValues: Action<AddCashModel, AddCashFormikProps | null>;
  setDetails: Action<AddCashModel, API.FiatDepositDetails | null>;

  // thunk
  resetState: Thunk<AddCashModel, undefined, Injections, DataModel>;
  getDetails: Thunk<
    AddCashModel,
    {
      currencyCode: string;
      accountId: string | undefined;
      impersonatedAccountId?: string;
    },
    Injections,
    DataModel
  >;

  // >> `<api>/list-fiat-depositable-currencies`
  _listFiatDepositableCurrencies: API.ListDepositableCurrenciesResponse | null;
  _setListFiatDepositableCurrencies: Action<
    AddCashModel,
    API.ListDepositableCurrenciesResponse | null
  >;
  getFiatDepositableCurrencies: Thunk<
    AddCashModel,
    { accountId?: string },
    Injections,
    DataModel
  >;
}

export const addCashModel: AddCashModel = {
  ...createBaseModel(),

  // state
  formValues: null,
  details: null,
  checkoutSimulationCounter: 0,

  // computed
  allowedCurrencies: computed(
    // TODO(Hadrien): Adjust when we'll handle pagination : https://stablehouse.atlassian.net/browse/SH-9737
    [_state => _state._listFiatDepositableCurrencies?.currencies],
    currencies => {
      if (!currencies || !currencies.length) {
        return [];
      }
      const result = currencies.map(ccy => {
        return factories.enrichCurrency(ccy);
      });
      return sortCurrencies(result, SORT_ASSETS_BY.CODE, SORT_DIR.ASC);
    }
  ),
  allowedCanAddCashAccounts: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-add-cash',
      })
  ),

  // action,
  setFormValues: action((state, payload) => {
    state.formValues = payload;
  }),
  setCounter: action((state, payload) => {
    state.checkoutSimulationCounter = payload;
  }),
  setDetails: action((state, payload) => {
    state.details = payload;
  }),
  // thunk
  resetState: thunk(actions => {
    actions.setError(null);
    actions.setFormValues(null);
    actions.setDetails(null);
    actions._setListFiatDepositableCurrencies(null);
  }),
  getDetails: thunk(async (actions, payload, { injections, getStoreState }) => {
    const storeState = getStoreState();
    try {
      actions.setBusy(true);
      actions.setError(null);

      injections.apiClient.setAdditionalHeaders({
        'x-account-id': payload.accountId || storeState.user.defaultAccountId,
        ...(!!payload.impersonatedAccountId && {
          'x-impersonated-account-id': payload.impersonatedAccountId,
        }),
      });

      const response = await injections.apiClient.getFiatDepositDetails({
        currencyCode: payload.currencyCode,
      });
      const { isSuccessful, errorMessage, result } = response;
      if (!isSuccessful) {
        actions.setError(errorMessage);
        return;
      }
      actions.setDetails(result);
    } catch (error) {
      const message = getApiErrorMessage(error);
      actions.setError(message);
    } finally {
      actions.setBusy(false);
    }
  }),

  // >> `<api>/list-fiat-depositable-currencies`
  _listFiatDepositableCurrencies: null,
  _setListFiatDepositableCurrencies: action((state, payload) => {
    state._listFiatDepositableCurrencies = payload;
  }),
  getFiatDepositableCurrencies: thunk(
    async (actions, { accountId }, { injections, getStoreState }) => {
      try {
        actions.setBusy(true);
        actions.setError(null);
        actions._setListFiatDepositableCurrencies(null);

        const storeState = getStoreState();
        const accId =
          accountId || storeState.portfolio.accountDetail?.account?.accountId;
        injections.apiClient.setAdditionalHeaders({
          'x-account-id': accId,
        });

        const response =
          await injections.apiClient.listFiatDepositableCurrencies({
            search: null,
            count: 50,
          });
        const { isSuccessful, errorMessage, result } = response;
        if (!isSuccessful) {
          actions.setError(errorMessage);
          return;
        }

        actions._setListFiatDepositableCurrencies(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        actions.setError(message);
      } finally {
        actions.setBusy(false);
      }
    }
  ),
};
