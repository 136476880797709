import parseNumber from 'multi-number-parse';
import { SORT_ASSETS_BY, SORT_ASSETS_FIELDS, SORT_DIR } from '../config';
import {
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  EnrichedCurrency,
  EnrichedAllHolding,
} from '../types';
import _orderBy from 'lodash.orderby';

export const sortFunds = <
  T extends
    | EnrichedAccountDetailAsset
    | EnrichedAssetHolding
    | EnrichedAllHolding,
>(
  funds: T[] = [],
  sortBy: SORT_ASSETS_BY,
  sortDir: SORT_DIR = SORT_DIR.DESC
) => {
  if (sortBy === SORT_ASSETS_BY.CODE) {
    return _orderBy(funds, ['currency.code'], [sortDir]) as T[];
  }

  if (
    sortBy === SORT_ASSETS_BY.PRICE ||
    sortBy === SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_DAY ||
    sortBy === SORT_ASSETS_BY.PRICE_CHANGE_1_DAY ||
    sortBy === SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_WEEK ||
    sortBy === SORT_ASSETS_BY.PRICE_CHANGE_1_WEEK ||
    sortBy === SORT_ASSETS_BY.PERCENTAGE_CHANGE_1_MONTH ||
    sortBy === SORT_ASSETS_BY.PRICE_CHANGE_1_MONTH ||
    sortBy === SORT_ASSETS_BY.APY
  ) {
    return _orderBy(
      funds,
      [`currency.${SORT_ASSETS_FIELDS[sortBy]}`, 'currency.code'],
      [sortDir, 'asc']
    ) as T[];
  }

  return _orderBy(
    funds,
    [
      (_fund: EnrichedAccountDetailAsset) =>
        parseNumber(_fund[SORT_ASSETS_FIELDS[sortBy]] ?? '0'),
      'currency.code',
    ],
    [sortDir, 'asc']
  ) as T[];
};

export const sortCurrencies = <T extends EnrichedCurrency>(
  coins: T[] = [],
  sortBy: SORT_ASSETS_BY,
  sortDir: SORT_DIR = SORT_DIR.DESC
) => {
  if (sortBy === SORT_ASSETS_BY.CODE) {
    return _orderBy(coins, ['code'], [sortDir]) as T[];
  }
  return coins;
};
