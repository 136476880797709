import { FC, memo, useCallback, useMemo } from 'react';
import {
  dateRanges,
  TransferFilterFormikProps,
  TransferFilterFormRangeProps,
  DateRangeKey,
} from 'common';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormikProps } from 'formik';
import { Error } from '../../common/error';
import { DateFilter } from '~/components/app-table-filters/filters/date-filter';
import { FilterInput } from './filter-input';
import { startCase } from 'lodash';
import { TransactionFilterSteps } from './steps';
import {
  FilterProps,
  TRANSACTIONS_SORT_PARAMS_KEYS,
  useFilterTransactions,
} from './use-filter-transactions';
import { API } from '@xbto/api-client';
import { DataStore } from '~/store';
import cx from 'classnames';
import { getAccountInfo } from '~/utils/get-account-details';
import { XIcon } from '@heroicons/react/outline';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { handleNavigation } from '~/components/shared/url-params/url-params';

export const FilterMain: FC<
  Partial<StepWizardChildProps & FormikProps<TransferFilterFormikProps>> &
    FilterProps
> = memo(({ goToNamedStep, onClose }) => {
  /**
   * Store
   */
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  /**
   * Hooks
   */
  const {
    error,
    formValues,
    onResetForm,
    buttonFilter,
    preFormValues,
    clientUserType,
    handleApplyFilters,
    handleDateSelection,
    currencyCodeFilterItems,
  } = useFilterTransactions();
  const { applyParams } = handleNavigation<TRANSACTIONS_SORT_PARAMS_KEYS>();

  if (!goToNamedStep) {
    return null;
  }

  /**
   * DOM
   */
  const onNextButtonClicked = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      const curatedFilters: Pick<
        API.GetActivityHistoryRequest,
        | 'page'
        | 'dateEnd'
        | 'dateStart'
        | 'statusFilter'
        | 'activityLabels'
        | 'currencyCodeFilter'
      > = {
        page: 1,
        dateEnd: preFormValues?.filterEndDate,
        statusFilter: preFormValues?.filterStatus,
        dateStart: preFormValues?.filterStartDate,
        currencyCodeFilter: preFormValues?.filterAssetList,
        activityLabels: preFormValues?.filterType?.map(item =>
          item.replace(/\s/g, '')
        ) as API.ActivityLabel[],
      };
      applyParams(curatedFilters, {
        append: true,
        callBack: handleOnApplyData,
      });
    },
    [applyParams, preFormValues]
  );

  const handleOnApplyData = useCallback(() => {
    handleApplyFilters(preFormValues);
    onClose(false);
  }, [onClose, handleApplyFilters]);

  const assets = useMemo(() => {
    return (preFormValues?.filterAssetList || formValues?.filterAssetList)?.map(
      item =>
        currencyCodeFilterItems.find(
          currency =>
            currency.code.toLocaleLowerCase() === item.toLocaleLowerCase()
        )?.displayCode
    ) as string[];
  }, [
    currencyCodeFilterItems,
    formValues?.filterAssetList,
    preFormValues?.filterAssetList,
  ]);

  return (
    <div data-testid="send-form" className="flex flex-col">
      {/* header  */}
      <DashboardHeader asStickyHeader headerPadding="pt-14">
        <div className="px-8 sm:px-10 mt-10 mb-5 flex flex-row gap-x-2 w-3/4">
          <span className="absolute top-9">
            <h3 className="font-bold typo-20 sm:typo-24">
              Filter Transactions
            </h3>

            {clientUserType !== 'admin' && (
              <div className="text-grey-darker text-sm font-normal">
                {getAccountInfo(accountDetail?.account)}
              </div>
            )}
          </span>
          <button
            onClick={() => onClose(true)}
            type="button"
            aria-label="go back"
          >
            {/*// TODO(Hadrien): replace with close-button icon ?*/}
            <XIcon className="w-6 h-6 absolute right-8 top-10 z-30" />
          </button>
        </div>
      </DashboardHeader>

      {/* api errors  */}
      <Error message={error} cls="mx-10 mb-3" />
      <div className="px-8 sm:px-10">
        {/* filter asset  */}
        <FilterInput
          label={'Asset'}
          displayNumber={4}
          placeHolder="Choose asset"
          values={assets}
          handleClick={() => goToNamedStep(TransactionFilterSteps.FilterAsset)}
        />

        {/* filter date */}
        <div className="flex flex-col flex-1 mb-7">
          <DateFilter
            dateStart={preFormValues?.filterStartDate}
            dateEnd={preFormValues?.filterEndDate}
            onChange={selectedDates =>
              handleDateSelection(
                selectedDates as TransferFilterFormRangeProps,
                null
              )
            }
          />
          <div className="flex whitespace-nowrap flex-wrap pt-2 gap-2">
            {Object.entries(dateRanges).map(([key, item]) => {
              const { text, ...rest } = item;
              return (
                <button
                  key={key}
                  type="button"
                  className={cx(
                    {
                      'app-button-outline': buttonFilter !== key,
                      'app-button-primary': buttonFilter === key,
                    },
                    'button-xs text-xs md:text-sm snap-center'
                  )}
                  onClick={() =>
                    handleDateSelection(
                      {
                        startDate: rest.startDate as Date,
                        endDate: rest.endDate as Date,
                      },
                      key as DateRangeKey
                    )
                  }
                >
                  {startCase(text as string)}
                </button>
              );
            })}
          </div>
        </div>

        {/* filter type  */}
        <FilterInput
          label={'Type'}
          placeHolder="Choose type"
          values={preFormValues?.filterType?.map(item => startCase(item))}
          handleClick={() => goToNamedStep(TransactionFilterSteps.FilterType)}
        />

        {/* filter status */}
        <FilterInput
          cls="mb-0"
          label={'Status'}
          displayNumber={3}
          placeHolder="Choose status"
          values={preFormValues?.filterStatus}
          handleClick={() => goToNamedStep(TransactionFilterSteps.FilterStatus)}
        />
      </div>

      {/* actions  */}
      <div className="flex flex-col sticky bottom-0 sm:flex-row gap-4 border-t bg-gray-100 rounded-b py-6 px-8 sm:px-10 mt-10">
        {Object.values(
          (({ filterDateRange, ...keep }) => keep)(preFormValues) || {}
        ).some(v => (Array.isArray(v) ? v?.length : v)) && (
          <button
            type="reset"
            className="app-button-outline button-lg sm:button-xl text-md sm:text-lg w-full sm:mr-3 flex-1"
            onClick={() => onResetForm()}
          >
            Clear selection
          </button>
        )}
        {/* preview  */}
        <button
          role="button"
          type="button"
          className="app-button-primary button-lg sm:button-xl text-md sm:text-lg w-full flex-1"
          onClick={onNextButtonClicked}
        >
          Apply filters
        </button>
      </div>
    </div>
  );
});
