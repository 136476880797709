import { action, Action } from 'easy-peasy';
import { API } from '@xbto/api-client';
import { getApiErrorMessage } from '../utils/get-api-error-message';
import { ApiError } from './api-error';

type ErrorArg = Error | string | null | API.ApiResponse | ApiError | unknown;

export interface BaseModel {
  // state
  error: string | null;
  busy: boolean;
  // actions
  setError: Action<BaseModel, ErrorArg>;
  setBusy: Action<BaseModel, boolean>;
}

export function createBaseModel(): BaseModel {
  return {
    error: null,
    busy: false,
    setError: action((state, payload: ErrorArg) => {
      if (payload) {
        const err = getApiErrorMessage(payload);
        state.error = err;
      } else {
        state.error = null;
      }
    }),
    setBusy: action((state, payload) => {
      state.busy = payload;
    }),
  };
}
