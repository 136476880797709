var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef } from 'react';
import { Animated, Platform } from 'react-native';
import { formatters } from 'common';
import { TextBody, TextCaption } from '../../atoms/text';
const fadeOutConfig = {
    toValue: 0,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web',
};
const fadeInConfig = {
    toValue: 1,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web',
};
export const _AmountFormat = memo((_a) => {
    var { animated = false, currencyCode, decimals, fiat, decipherPrecision, shorten = false, signed = false, percentColored = false, small = false, trimMantissa = false, unit = '', value } = _a, rest = __rest(_a, ["animated", "currencyCode", "decimals", "fiat", "decipherPrecision", "shorten", "signed", "percentColored", "small", "trimMantissa", "unit", "value"]);
    // Vars
    const opacity = useRef(new Animated.Value(1)).current;
    const isMounted = useRef(false);
    const amount = useMemo(() => {
        if (currencyCode) {
            return formatters.getCurrency(value, decimals, currencyCode, [], false, { average: shorten, fiat, decipherPrecision });
        }
        return formatters.getAmount(value, decimals, false, {
            average: shorten,
            trimMantissa,
        });
    }, [
        currencyCode,
        decimals,
        shorten,
        value,
        fiat,
        decipherPrecision,
        trimMantissa,
    ]);
    const textContent = useMemo(() => {
        if (value === null) {
            return '-';
        }
        let _content = '';
        // Prefix
        if (signed && value > 0) {
            _content += '+';
        }
        // Amount (formatted)
        _content += shorten ? amount.toUpperCase() : amount;
        // Suffix
        _content += unit;
        return _content;
    }, [amount, shorten, signed, unit, value]);
    // Effects
    useEffect(() => {
        if (!isMounted.current) {
            // Skip animation on first render
            isMounted.current = true;
        }
        else if (animated) {
            // Fade OUT
            Animated.timing(opacity, fadeOutConfig).start(() => {
                // Fade IN
                Animated.timing(opacity, fadeInConfig).start();
            });
        }
    }, [animated, textContent, opacity]);
    // Render
    const TextEl = small ? TextCaption : TextBody;
    let colorName;
    if (value && (signed || percentColored)) {
        colorName = value < 0 ? 'onSurfaceNegative' : 'onSurfacePositive';
    }
    const content = (_jsx(TextEl, Object.assign({}, rest, { color: colorName }, { children: textContent })));
    if (animated) {
        return _jsx(Animated.View, Object.assign({ style: { opacity } }, { children: content }));
    }
    return content;
});
