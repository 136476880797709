import { FC, memo, useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import {
  addressItemTemplate,
  addressSelectedItemTemplate,
  singleWithdrawableItemTemplate,
  singleWithdrawableSelectedItemTemplate,
} from '../../app-selector/templates';
import {
  AppIcon,
  BlockchainNetworkName,
  CurrencyIcon,
  decimalNumberValidation,
  DEFAULTS,
  EnrichedAccountDetailAsset,
  FormHeader,
  getSendFees,
  Note,
  NUMBER_FORMAT,
  NumberInput,
  SendDestinationType,
  SelectInput,
  TextInput,
  useSendForm,
} from 'common';
import { SendSteps } from './steps';
import { AppStore, DataStore } from '../../../store';
import { Error } from '../../common/error';
import { NotAllowed } from '../shared/not-allowed';
import { getAccountInfo, getAccountType } from '~/utils/get-account-details';
import { API } from '@xbto/api-client';
import { AssetBalanceBreakdown } from '../shared/asset-balance-breakdown';
import { ImitationSelectField } from '~/components/lib';
import { FeeNote } from '~/components/fee-note';
import { apiClient } from '~/api/client';
import { TextAreaInput } from '~/components/forms/textarea-input';
import { FormControl } from '~/components/forms/form-control';

type InputEvent = React.ChangeEvent<HTMLInputElement>;

type TextAreaEvent = React.ChangeEvent<HTMLTextAreaElement>;

type ButtonEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

interface AddressLabelAddonProps {
  onClick: (ev: ButtonEvent) => void;
}

const AddressLabelAddon = ({ onClick }: AddressLabelAddonProps) => (
  <button
    type="button"
    onClick={onClick}
    className="flex flex-row items-center gap-1"
  >
    <AppIcon icon="plus" size={18} bg="bg-transparent" />
    <span className="text-primary font-bold text-sm">Add new address</span>
  </button>
);

export const Form: FC<Partial<StepWizardChildProps>> = memo(
  ({ goToNamedStep }) => {
    /**
     * Store
     */
    const accountDetail = DataStore.useStoreState(
      s => s.portfolio.accountDetail
    );
    const assetBalances = DataStore.useStoreState(s => s.send.assetBalances);
    const allowedAssets = DataStore.useStoreState(s => s.send.allowedAssets);
    const destinationTypes = DataStore.useStoreState(
      s => s.send.destinationTypes
    );
    const getBankAccounts = DataStore.useStoreActions(
      a => a.settings.getBankAccounts
    );

    const {
      asset,
      error,
      errors,
      isValid,
      isLoading: busy,
      values,
      setAmount,
      setCurrencyCode,
      setDescription,
      setDestination,
      setDestinationType,
      setWithdrawalAddress,
      simulation,
      touch,
      withdrawalAddress,
      withdrawalAddresses,
      isPreviewDisabled,
      collectKyt,
    } = useSendForm({ DataStore, apiClient });

    const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
    const setSelectedDialogType = AppStore.useStoreActions(
      a => a.setDashboardSelectedDialogType
    );

    /**
     * Handlers
     */
    const handleChangeAmount = useCallback(
      (value: string) => {
        setAmount(decimalNumberValidation(value));
      },
      [setAmount]
    );

    const handleChangeDestination = useCallback(
      (ev: InputEvent) => {
        setDestination(ev.target.value);
      },
      [setDestination]
    );

    const handleChangeAsset = useCallback(
      (newValue: EnrichedAccountDetailAsset) => {
        setCurrencyCode(newValue.currency.code);
      },
      [setCurrencyCode]
    );

    const handleChangeDescription = useCallback(
      (ev: TextAreaEvent) => {
        setDescription(ev.target.value);
      },
      [setDescription]
    );

    const handlePressMax = useCallback(() => {
      if (asset) {
        setAmount(asset.withdrawableQuantity ?? '');
      }
    }, [asset, setAmount]);

    const handlePressNext = useCallback(
      async (ev: ButtonEvent) => {
        ev.preventDefault();
        if (collectKyt) {
          goToNamedStep?.(SendSteps.Kyt);
          return;
        }
        goToNamedStep?.(SendSteps.Preview);
      },
      [goToNamedStep, collectKyt]
    );

    const handlePressCancel = useCallback(
      (e: ButtonEvent) => {
        e.preventDefault();
        setSelectedDialogType(null);
      },
      [setSelectedDialogType]
    );

    const handleAddAddress = useCallback(
      (e: ButtonEvent) => {
        e.preventDefault();
        goToNamedStep?.('add-crypto-address');
      },
      [goToNamedStep]
    );

    /**
     * Effects
     */
    useEffectOnce(() => {
      getBankAccounts();
    });

    /**
     * DOM
     */
    if (!accountDetail) {
      return null;
    }
    if (!allowedAssets.length) {
      return (
        <NotAllowed
          message={`There are no assets in your account ${getAccountType(
            accountDetail?.account
          )} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }
    if (!!asset?.currency.isAssetOfTypeFiat) {
      return (
        <NotAllowed
          message={`Send operation is not allowed for ${asset.currency.displayCode}`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }

    if (
      asset &&
      !allowedAssets.find(a => a.currency.code === asset.currency.code)
    ) {
      // chosen asset is not in account!
      return (
        <NotAllowed
          message={`There is no ${
            asset.currency.displayCode
          } in your account ${getAccountType(accountDetail?.account)} ${
            accountDetail?.account?.accountNumber
          } to perform this operation`}
          onClose={() => setSelectedDialogType(null)}
        />
      );
    }

    /**
     * Form DOM
     */
    const feeLabel = getSendFees(
      asset?.currency.displayCode,
      values,
      simulation
    );

    const feeNote =
      values.amount && simulation && !busy && isValid ? (
        values.destinationType === SendDestinationType.transfer ? null : (
          <>
            {simulation.feeMode ===
            API.WithdrawalFeeChargeMode.ChargedImmediately ? (
              <FeeNote
                feeLabel={feeLabel}
                extraLine={
                  <>
                    <span>You send:</span>{' '}
                    <span className="font-bold text-primary">
                      {simulation.formatted.sendingAmountWithCurrencyCode}
                    </span>{' '}
                    <span className="text-primary">
                      ({simulation.formatted.sendingAmountUsdWithCurrencyCode})
                    </span>
                  </>
                }
              />
            ) : (
              <FeeNote
                feeLabel={feeLabel}
                feeFirst={false}
                extraLine={
                  <div className="text-grey-darker">
                    Value:{' '}
                    <b className="text-primary">
                      {simulation.formatted.amountUsdWithCurrencyCode}
                    </b>
                  </div>
                }
              />
            )}
          </>
        )
      ) : null;
    return (
      <div data-testid="send-form" className="flex flex-col">
        {/* header  */}
        <FormHeader
          title={`Send ${values.fromAsset?.currency.displayCode || ''}`}
          accountInfo={getAccountInfo(accountDetail.account, 'From')}
        />
        <div className="px-6 md:px-10">
          {/* Asset */}
          <FormControl label="Asset" error={errors.currencyCode}>
            <SelectInput
              values={allowedAssets}
              getSelectedItemTemplate={fund =>
                singleWithdrawableSelectedItemTemplate(fund)
              }
              getItemTemplate={(fund, selected: boolean, active: boolean) =>
                singleWithdrawableItemTemplate(fund, selected, active)
              }
              onChange={handleChangeAsset}
              value={asset}
              errored={!!errors.currencyCode}
              onBlur={touch('currencyCode')}
            />
            <AssetBalanceBreakdown
              availableToTitle="Available to send"
              balances={assetBalances}
              breakdownType="pendingOutgoing"
            />
          </FormControl>

          {/* withdraw method  */}
          {accountDetail.canWithdrawToNonWhitelistedAddress && (
            <FormControl label="Send method" error={errors.destinationType}>
              <SelectInput
                disabled={destinationTypes.length < 2}
                onChange={setDestinationType}
                values={destinationTypes}
                value={values.destinationType}
                errored={!!errors.destinationType}
                onBlur={touch('destinationType')}
              />
            </FormControl>
          )}

          {/* method = crypto  */}
          <div
            className={cx({
              hidden:
                values.destinationType !== SendDestinationType.crypto ||
                !values.network,
            })}
          >
            {/* network dropdown  */}
            <FormControl label="Network" error={errors.network}>
              <SelectInput
                disabled
                values={Object.values(BlockchainNetworkName)}
                value={values.network ?? null}
                errored={!!errors.network}
              />
            </FormControl>
          </div>

          {/* method = crypto or tag  */}
          {/* destination address/ tag /book */}

          {!accountDetail.canWithdrawToNonWhitelistedAddress && (
            <FormControl
              label="Address"
              labelAddon={<AddressLabelAddon onClick={handleAddAddress} />}
              error={errors.withdrawalAddressId}
            >
              <SelectInput
                disabled={!withdrawalAddresses.length}
                values={withdrawalAddresses}
                getItemTemplate={addressItemTemplate}
                getSelectedItemTemplate={addressSelectedItemTemplate}
                onChange={setWithdrawalAddress}
                value={withdrawalAddress}
                errored={!!errors.withdrawalAddressId}
                onBlur={touch('withdrawalAddressId')}
              />
              {!withdrawalAddresses.length && (
                <Note cls="px-2 mt-4 font-semibold">
                  {` There are no whitelisted addresses configured${
                    clientUserType !== 'none'
                      ? `, Contact your Apex representative.`
                      : '.'
                  }`}
                </Note>
              )}
            </FormControl>
          )}
          {accountDetail.canWithdrawToNonWhitelistedAddress && (
            <div className="flex flex-col">
              {values.destinationType === SendDestinationType.book ? (
                <FormControl
                  label="Address"
                  labelAddon={<AddressLabelAddon onClick={handleAddAddress} />}
                >
                  <ImitationSelectField
                    onClick={() => {
                      goToNamedStep?.(SendSteps.AddressBook);
                    }}
                  >
                    {values.destination || (
                      <span className="text-grey">Please select</span>
                    )}
                  </ImitationSelectField>
                </FormControl>
              ) : (
                <FormControl
                  label={
                    values.destinationType === SendDestinationType.transfer
                      ? 'Enter Stablehouse user'
                      : 'Address'
                  }
                  labelAddon={<AddressLabelAddon onClick={handleAddAddress} />}
                  error={errors.destination}
                >
                  <TextInput
                    spellCheck={false}
                    placeholder={
                      values.destinationType === SendDestinationType.transfer
                        ? ''
                        : 'e.g. 36dexYmEWM4ShowE9he9hstoPwa1oPhYkt'
                    }
                    disabled={!asset?.balance}
                    autoComplete="off"
                    onBlur={touch('destination')}
                    onChange={handleChangeDestination}
                    errored={!!errors.destination}
                    value={values.destination}
                  />
                </FormControl>
              )}

              {values.destinationType === SendDestinationType.crypto &&
                !!values.destination &&
                !errors?.destination && (
                  <Note cls="text-sm flex flex-col gap-2 px-4 py-3">
                    <span className="font-bold text-primary">
                      Please verify the address before proceeding
                    </span>
                    <span className="text-grey-darker">
                      This address isn&#39;t stored in your address book. Ensure
                      its accuracy before continuing with your transaction.
                    </span>
                  </Note>
                )}
            </div>
          )}

          {/* amount  */}
          <div className="flex flex-col">
            <FormControl label="Amount" error={errors.amount}>
              <NumberInput
                placeholder="Enter amount"
                allowNegative={false}
                decimalScale={
                  asset?.currency?.decimals || DEFAULTS.DECIMAL_SCALE
                }
                disabled={!asset?.hasBalance}
                autoComplete="off"
                thousandSeparator={NUMBER_FORMAT.THOUSANDS_SEPARATOR}
                leftAddon={
                  asset?.currency ? (
                    <CurrencyIcon
                      currencyCode={asset?.currency.code}
                      apy={asset?.currency.apy}
                    />
                  ) : null
                }
                rightAddon={
                  <button
                    type="button"
                    className="text-sm font-bold underline focus:outline-none relative mb-1"
                    onClick={handlePressMax}
                  >
                    Max
                  </button>
                }
                onChange={handleChangeAmount}
                onBlur={touch('amount')}
                errored={!!errors.amount}
                value={values.amount ?? ''}
              />
            </FormControl>

            {feeNote}
          </div>

          {/* description  */}
          <FormControl label="Description" error={errors.description}>
            <TextAreaInput
              placeholder="Add description"
              maxLength={1024}
              onChange={handleChangeDescription}
              onBlur={touch('description')}
              errored={!!errors.description}
              value={values.description ?? ''}
            />
          </FormControl>
        </div>

        {/* api errors  */}
        <Error message={error} cls="mx-6 md:mx-10" />

        {/* actions  */}
        <div className="flex flex-col gap-y-4 gap-x-3 sm:flex-row border-t border-grey-bright bg-gray-100 rounded-b py-6 px-6 md:px-10 mt-12">
          {/* cancel button  */}
          <button
            className="app-button-outline w-full flex-1"
            onClick={handlePressCancel}
          >
            Cancel
          </button>
          {/* preview  */}
          <button
            role="button"
            disabled={isPreviewDisabled}
            // disabled={false}
            type="button"
            className="app-button-primary w-full flex-1"
            onClick={handlePressNext}
          >
            Preview
          </button>
        </div>
      </div>
    );
  }
);
