import React, { FC } from 'react';
import cx from 'classnames';
import { BaseComponentProps } from '../../types';

export interface Props extends BaseComponentProps {
  rightAddOn?: JSX.Element;
  orderContentCls?: string;
  withBorder?: boolean;
  bg?: string;
  cls?: string;
  containerCls?: string;
  containerWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  bgColor?: boolean;
  wrapperCls?: string;
  fullHeight?: boolean;
}

export enum ContainerWidthSizes {
  xs = 'max-w-sm',
  sm = 'max-w-3xl',
  md = 'max-w-5xl',
  lg = 'max-w-6xl',
  xl = 'max-w-7xl',
}

export const AppContainer: FC<Props> = ({
  children,
  rightAddOn,
  containerCls,
  cls = 'px-4 py-8',
  containerWidth = 'lg',
  withBorder = false,
  orderContentCls = 'order-first',
  bgColor = true,
  wrapperCls,
  fullHeight = false,
  ...rest
}) => {
  const bg = withBorder ? 'bg-white' : rest?.bg;
  return (
    <div
      className={cx(wrapperCls, {
        'bg-grey-brightest': bgColor,
        'h-full': fullHeight,
      })}
    >
      <div
        className={cx(
          'flex flex-1 md:flex-row flex-col gap-4 container mx-auto justify-between',
          bg,
          cls,
          ContainerWidthSizes[containerWidth],
          {
            'border rounded bright-gray': withBorder,
          }
        )}
      >
        <div className={cx('flex-1 min-w-sm', containerCls, orderContentCls)}>
          {children}
        </div>
        {!!rightAddOn && (
          <div className={cx(containerCls, 'flex-1 md:max-w-sm w-full')}>
            {rightAddOn}
          </div>
        )}
      </div>
    </div>
  );
};
