import { API } from '@xbto/api-client';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getApiErrorMessage } from '../../utils/get-api-error-message';
import { DataModel } from '../data-store';
import { Injections } from '../types';
import { ReceiveFormikProps } from '../../utils/formik/receive/types';
import { Enriched, EnrichedCurrency } from '../../types';
import { sortCurrencies } from '../../utils/sort-funds';
import { SORT_ASSETS_BY, SORT_DIR } from '../../config';
import { BaseModel, createBaseModel } from '../base-store';
import { factories } from '../../utils';
import { getAccountsByWorkflow } from '../../hooks/workflows/allowed-actions-and-accounts';

export interface ReceiveModel extends BaseModel {
  // state
  formValues: ReceiveFormikProps | null;
  details: API.GetDepositAddressResponse | null;
  confirmed: boolean;

  // computed
  allowedCurrencies: Computed<ReceiveModel, EnrichedCurrency[], DataModel>;
  allowedCanReceiveAccounts: Computed<
    ReceiveModel,
    Enriched.ListAccountItem[],
    DataModel
  >;

  // actions
  setFormValues: Action<ReceiveModel, ReceiveFormikProps | null>;
  setDetails: Action<ReceiveModel, API.GetDepositAddressResponse | null>;
  setConfirmed: Action<ReceiveModel, boolean>;

  // thunk
  resetState: Thunk<ReceiveModel, undefined, Injections, DataModel>;
  getDetails: Thunk<
    ReceiveModel,
    {
      currencyCode: string;
      accountId: string;
      impersonatedAccountId?: string;
    },
    Injections,
    DataModel
  >;

  // >> `<api>/list-depositable-currencies`
  _listDepositableCurrencies: API.ListDepositableCurrenciesResponse | null;
  _setListDepositableCurrencies: Action<
    ReceiveModel,
    API.ListDepositableCurrenciesResponse | null
  >;
  getDepositableCurrencies: Thunk<
    ReceiveModel,
    { accountId?: string; search?: string },
    Injections,
    DataModel
  >;
  // >> `<api>/list-fiat-depositable-currencies`
  _listFiatDepositableCurrencies: API.ListDepositableCurrenciesResponse | null;
  _setListFiatDepositableCurrencies: Action<
    ReceiveModel,
    API.ListDepositableCurrenciesResponse | null
  >;
  getFiatDepositableCurrencies: Thunk<
    ReceiveModel,
    void,
    Injections,
    DataModel
  >;
}

export const receiveModel: ReceiveModel = {
  ...createBaseModel(),

  // state
  formValues: null,
  details: null,
  confirmed: false,

  // computed
  allowedCurrencies: computed(
    // TODO(Hadrien): Adjust when we'll handle pagination : https://stablehouse.atlassian.net/browse/SH-9737
    [_state => _state._listDepositableCurrencies?.currencies ?? []],
    currencies => {
      const result = currencies.map(ccy => {
        return factories.enrichCurrency(ccy);
      });
      return sortCurrencies(result, SORT_ASSETS_BY.CODE, SORT_DIR.ASC);
    }
  ),
  allowedCanReceiveAccounts: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-receive',
      })
  ),
  // action,

  setFormValues: action((state, payload) => {
    state.formValues = payload;
  }),
  setDetails: action((state, payload) => {
    state.details = payload;
  }),
  setConfirmed: action((state, payload) => {
    state.confirmed = payload;
  }),

  // thunk
  resetState: thunk(actions => {
    actions.setError(null);
    actions.setFormValues(null);
    actions.setDetails(null);
    actions._setListDepositableCurrencies(null);
    actions._setListFiatDepositableCurrencies(null);
    actions.setConfirmed(false);
  }),
  getDetails: thunk(
    async (
      actions,
      { currencyCode, accountId, impersonatedAccountId },
      { injections }
    ) => {
      try {
        actions.setBusy(true);
        actions.setError(null);
        actions.setDetails(null);

        injections.apiClient.setAdditionalHeaders({
          'x-account-id': accountId,
          ...(!!impersonatedAccountId && {
            'x-impersonated-account-id': impersonatedAccountId,
          }),
        });

        const response = await injections.apiClient.getDepositAddress({
          currencyCode,
        });
        const { isSuccessful, errorMessage, result } = response;
        if (!isSuccessful) {
          actions.setError(errorMessage);
          return;
        }
        actions.setDetails(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        actions.setError(message);
      } finally {
        actions.setBusy(false);
      }
    }
  ),

  // >> `<api>/list-depositable-currencies`
  _listDepositableCurrencies: null,
  _setListDepositableCurrencies: action((state, payload) => {
    state._listDepositableCurrencies = payload;
  }),
  getDepositableCurrencies: thunk(
    async (actions, { accountId, search }, { injections, getStoreState }) => {
      try {
        actions.setBusy(true);
        actions.setError(null);
        actions._setListDepositableCurrencies(null);

        const storeState = getStoreState();
        const accId =
          accountId || storeState.portfolio.accountDetail?.account?.accountId;
        injections.apiClient.setAdditionalHeaders({
          'x-account-id': accId,
        });

        const response = await injections.apiClient.listDepositableCurrencies({
          search: search || '',
          count: 100,
        });
        const { isSuccessful, errorMessage, result } = response;
        if (!isSuccessful) {
          actions.setError(errorMessage);
          return;
        }
        actions._setListDepositableCurrencies(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        actions.setError(message);
      } finally {
        actions.setBusy(false);
      }
    }
  ),

  // TODO(Hadrien): Move to add-cash-store.tsx
  // >> `<api>/list-fiat-depositable-currencies`
  _listFiatDepositableCurrencies: null,
  _setListFiatDepositableCurrencies: action((state, payload) => {
    state._listFiatDepositableCurrencies = payload;
  }),
  getFiatDepositableCurrencies: thunk(
    async (actions, _payload, { injections }) => {
      try {
        actions.setBusy(true);
        actions.setError(null);
        actions._setListFiatDepositableCurrencies(null);

        const response =
          await injections.apiClient.listFiatDepositableCurrencies({
            search: null,
            count: 50,
          });
        const { isSuccessful, errorMessage, result } = response;
        if (!isSuccessful) {
          actions.setError(errorMessage);
          return;
        }

        actions._setListFiatDepositableCurrencies(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        actions.setError(message);
      } finally {
        actions.setBusy(false);
      }
    }
  ),
};
