import { API } from '@xbto/api-client';

export enum QuorumOperationKindValue {
  AddBankAccount = 'Create bank account',
  AddWithdrawalAddress = 'Create crypto address',
  DeleteBankAccount = 'Delete bank account',
  ToggleAccountStatus = 'Toggle account status',
  ToggleUserStatus = 'Toggle user status',
}

export enum QuorumOperationStatusValue {
  WaitingForApproval = 'Pending',
  Executed = 'Executed',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
}

export enum QuorumOperationModalKind {
  AddBankAccount = 'AddBankAccount',
  AddWithdrawalAddress = 'AddWithdrawalAddress',
  ConfirmDeleteWithdrawalAddress = 'ConfirmDeleteWithdrawalAddress',
  RequestSentForApproval = 'RequestSentForApproval',
  ConfirmDisableFund = 'ConfirmDisableFund',
  ConfirmDisableUser = 'ConfirmDisableUser',
}

export enum QuorumOperationModalType {
  approved = 'approved',
  rejected = 'rejected',
}

export enum QuorumOperationSortByMenuValue {
  requesterName = 'Requester name',
  newestToOldest = 'Newest to oldest',
  pendingStatusFirst = 'Pending status first',
  fundName = 'Fund name',
}

export enum FundAddressTableHeader {
  Asset = 'Asset',
  AccountName = 'Account name',
  Address = 'Address',
  Status = 'Status',
}

export enum FundAddressSettingsTableHeader {
  Asset = 'Asset',
  AccountName = 'Account name',
  Address = 'Address',
}

export enum FundBankTableHeader {
  CurrencyAndName = 'Currency & Name',
  BankDetails = 'Bank details',
  Status = 'Status',
}

export enum PendingApprovalsHeader {
  ActionType = 'Action Type',
  Fund = 'Fund',
  ApprovalDate = 'Date',
  Requester = 'Requester',
  ApprovalStatus = 'Approval Status',
}

export enum QuorumToggleStatusValue {
  EnableUser = 'Enable user',
  DisableUser = 'Disable user',
  EnableAccount = 'Enable account',
  DisableAccount = 'Disable account',
}

export enum FundDetailsValue {
  CryptoDetails = 'crypto-details',
  BankDetails = 'bank-details',
}

export type FundShowDepositDetails = {
  fundDetailsType?: FundDetailsType | null;
  onClose?: () => void;
  fund?: API.ApexFund;
};

export type FundDetailsType =
  | 'bank-details'
  | 'crypto-details'
  | 'disable-fund'
  | 'disable-account';
