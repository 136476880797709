var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable, Linking } from 'react-native';
import { TextUnderline } from '../../atoms';
export function Hypertext(_a) {
    var { href, onPress } = _a, rest = __rest(_a, ["href", "onPress"]);
    // Handlers
    const handlePress = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!href) {
            onPress === null || onPress === void 0 ? void 0 : onPress();
        }
        else if (yield Linking.canOpenURL(href)) {
            yield Linking.openURL(href);
        }
        else {
            // Could not open URL may as well give the callback to onPress()
            onPress === null || onPress === void 0 ? void 0 : onPress();
        }
    }), [href, onPress]);
    return (_jsx(Pressable, Object.assign({ onPress: handlePress, style: ({ pressed }) => ({ opacity: pressed ? 0.5 : 1 }) }, { children: _jsx(TextUnderline, Object.assign({ href: href }, rest)) })));
}
