import { API } from '@xbto/api-client';
import { ApiThunk } from './types';
import { action, Action, computed, Computed, thunk } from 'easy-peasy';
import { EnrichedInvoice } from '../types';
import {
  createEnrichedInvoice,
  runApi,
  FilterProps,
  formatters,
} from '../utils';
import { DataModel } from './data-store';
import { BaseModel, createBaseModel } from './base-store';

const defaultGetInvoicesRequest: API.GetInvoicesRequest = {
  page: null,
  pageSize: null,
  invoiceType: null,
  dateStart: null,
  dateEnd: null,
};

export interface InvoicesModel extends BaseModel {
  // state
  _invoices: API.Invoice[] | null;
  totalOwed: Computed<InvoicesModel, number>;
  formatted: Computed<
    InvoicesModel,
    {
      totalOwed: string;
    }
  >;
  showPayInvoices: boolean;
  invoiceToPay: EnrichedInvoice | null;
  payInvoiceResult: EnrichedInvoice | null;
  filters: FilterProps | null;
  // computed
  invoices: Computed<InvoicesModel, EnrichedInvoice[], DataModel>;
  findInvoiceById: Computed<
    InvoicesModel,
    (id: string) => EnrichedInvoice | undefined
  >;
  isLoaded: Computed<InvoicesModel, boolean>;
  // actions
  setInvoices: Action<InvoicesModel, API.Invoice[] | null>;
  setShowPayInvoices: Action<InvoicesModel, boolean>;
  setInvoiceToPay: Action<InvoicesModel, EnrichedInvoice | null>;
  setPayInvoiceResult: Action<InvoicesModel, EnrichedInvoice | null>;
  setFilters: Action<InvoicesModel, FilterProps | null>;
  // thunk
  getInvoices: ApiThunk<
    InvoicesModel,
    Partial<API.GetInvoicesRequest> | undefined,
    API.Invoice[]
  >;
  getInvoicePdf: ApiThunk<InvoicesModel, string, API.GetInvoicePdfResponse>;
  payInvoice: ApiThunk<
    InvoicesModel,
    API.PayInvoiceRequest | undefined,
    API.Invoice
  >;
}

export const invoicesModel: InvoicesModel = {
  ...createBaseModel(),

  // state
  _invoices: null,
  totalOwed: computed([s => s._invoices ?? []], invoices => {
    return invoices
      .map(
        ({ amount, amountPaid }) =>
          Number(amount ?? '0') - Number(amountPaid ?? '0')
      )
      .reduce((acc, value) => acc + value, 0);
  }),
  formatted: computed([s => s.totalOwed], totalOwed => {
    return {
      totalOwed: formatters.getFiatAmount(totalOwed),
    };
  }),
  showPayInvoices: false,
  invoiceToPay: null,
  payInvoiceResult: null,
  filters: null,
  // computed
  isLoaded: computed([s => s._invoices], invoices => {
    return invoices !== null;
  }),
  invoices: computed(
    [
      s => s._invoices ?? [],
      (_state, storeState) => storeState.metaData.currencies,
    ],
    (invoices, currencies) => {
      return invoices.map(_invoice =>
        createEnrichedInvoice(_invoice, currencies)
      );
    }
  ),
  findInvoiceById: computed([s => s.invoices], invoices => {
    return (id: string) => invoices.find(_invoice => _invoice.id === id);
  }),

  // actions
  setInvoices: action((state, payload) => {
    state._invoices = payload;
  }),
  setShowPayInvoices: action((state, payload) => {
    state.showPayInvoices = payload;
  }),
  setInvoiceToPay: action((state, payload) => {
    state.invoiceToPay = payload;
  }),
  setPayInvoiceResult: action((state, payload) => {
    state.payInvoiceResult = payload;
  }),
  setFilters: action((state, payload) => {
    state.filters = payload;
  }),
  // thunk
  getInvoicePdf: thunk((actions, id, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getInvoicePdf({ id });
      },
      undefined,
      {
        'x-account-id': helpers.getStoreState().user.defaultAccountId,
      }
    );
  }),
  getInvoices: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getInvoices({
          ...defaultGetInvoicesRequest,
          ...payload,
        });
      },
      result => {
        actions.setInvoices(result);
      },
      {
        'x-account-id': helpers.getStoreState().user.defaultAccountId,
      }
    );
  }),
  payInvoice: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.payInvoice(payload);
      },
      result => {
        const storeState = helpers.getStoreState();

        actions.setPayInvoiceResult(
          createEnrichedInvoice(result, storeState.metaData.currencies)
        );
      }
    );
  }),
};
