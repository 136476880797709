import { API } from '@xbto/api-client';

export const getFundBalance = (
  fund: API.SingleFund | API.FiatFund | null | undefined,
  currencies: API.CurrencyInformation[] | null | undefined
) => {
  if (!currencies || !fund) {
    return 0;
  }
  const ccyInfo = currencies?.find(ccy => ccy.code === fund?.currencyCode);
  if (!ccyInfo) {
    return 0;
  }

  return ccyInfo?.type === API.CurrencyType.Fiat
    ? (fund as API.FiatFund).balance
    : (fund as API.SingleFund).availableBalance;
};
