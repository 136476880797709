import { Platform } from 'react-native';
const FontFamilies = {
    InterWeb: { fontFamily: 'Inter' },
    InterRegular: { fontFamily: 'Inter-Regular' },
    InterBold: { fontFamily: 'Inter-Bold' },
};
export const PlatformsFontRegular = Platform.select({
    android: FontFamilies.InterRegular,
    ios: FontFamilies.InterRegular,
    web: FontFamilies.InterWeb,
});
export const PlatformsFontBold = Platform.select({
    android: FontFamilies.InterBold,
    ios: FontFamilies.InterBold,
    web: FontFamilies.InterWeb,
});
