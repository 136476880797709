import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { API } from '@xbto/api-client';
import { DataStoreType } from '../store/data-store';
import { initialMarketFilters, MarketFilters } from '../store/markets/store';
import { TIMERS } from '../constants';
import { Enriched } from '../types';

type AssetCategory = {
  id: string;
  label: string;
  filters: MarketFilters;
};

type EnrichedMarketAssetTypes = {
  displayName: string;
  type: API.MarketAssetType;
}[];

const assetTypes: EnrichedMarketAssetTypes = [
  {
    displayName: 'Crypto',
    type: API.MarketAssetType.Crypto,
  },
  {
    displayName: 'Funds',
    type: API.MarketAssetType.Fund,
  },
  {
    displayName: 'Bonds',
    type: API.MarketAssetType.Bond,
  },
  {
    displayName: 'Fiat',
    type: API.MarketAssetType.Fiat,
  },
];

type MarketSortFilters = Pick<MarketFilters, 'sortColumn' | 'sortDirection'>;

const defaultSortByAssetType: Record<API.MarketAssetType, MarketSortFilters> = {
  [API.MarketAssetType.Crypto]: {
    sortColumn: API.MarketListAssetsSortType.MarketCap,
    sortDirection: API.SortDirection.Descending,
  },
  [API.MarketAssetType.Fund]: {
    sortColumn: API.MarketListAssetsSortType.DisplayCode,
    sortDirection: API.SortDirection.Ascending,
  },
  [API.MarketAssetType.Bond]: {
    sortColumn: API.MarketListAssetsSortType.DisplayCode,
    sortDirection: API.SortDirection.Ascending,
  },
  [API.MarketAssetType.Fiat]: {
    sortColumn: API.MarketListAssetsSortType.DisplayCode,
    sortDirection: API.SortDirection.Ascending,
  },
};

export type UseMarketType = {
  init: () => void;

  // main asset type
  assetTypes: EnrichedMarketAssetTypes;
  activeAssetType: API.MarketAssetType;
  isActiveAssetType: (type: API.MarketAssetType) => boolean;
  isActiveAssetOfTypeFund: boolean;
  onAssetTypeChanged: (type: API.MarketAssetType, skipFetch?: boolean) => void;

  // asset sub categories
  categoriesByAssetType: {
    [key in API.MarketAssetType]: AssetCategory[];
  };
  isActiveAssetCategory: (filters: MarketFilters) => boolean;
  showCategoriesAndSearch: boolean;

  filters: MarketFilters;
  onFiltersChanged: (filters: MarketFilters) => void;
  onSortBy: (id: API.MarketListAssetsSortType, dir: API.SortDirection) => void;

  search: string;
  onSearchChanged: (search: string) => void;
  onPageChanged: (page: number) => void;

  isLoading: boolean;
  error: string | null;
  data: Enriched.MarketData | null;
  hasNoData: boolean;
  showPagination: boolean;

  mobile: {
    searchCollapsed: boolean;
    toggleSearchCollapsed: () => void;
  };
};

export const useMarkets = (DataStore: DataStoreType): UseMarketType => {
  /**
   * Store
   */
  const staticData = DataStore.useStoreState(a => a.metaData.staticData);

  const _filters = DataStore.useStoreState(a => a.markets._filters);
  const _setFilters = DataStore.useStoreActions(a => a.markets._setFilters);

  const isLoading = DataStore.useStoreState(a => a.markets.busy);
  const error = DataStore.useStoreState(a => a.markets.error);
  const data = DataStore.useStoreState(a => a.markets.data);

  const getData = DataStore.useStoreActions(a => a.markets.getData);

  /**
   * State
   */
  const [searchCollapsed, setSearchCollapsed] = useState(true);

  const marketsData = staticData?.markets;
  const categoriesByAssetType = useMemo(() => {
    return assetTypes.reduce(
      (acc, tab) => {
        const all = {
          id: 'all',
          label: 'All',
          filters: {
            ...initialMarketFilters,
            types: [API.MarketAssetType[tab.type]],
            categories: [],
          },
        };

        const categoriesPerAssetType =
          marketsData?.categoriesPerAssetType?.find(it => it.type === tab.type)
            ?.categories || ([] as API.MarketAssetCategory[]);

        acc[tab.type] = categoriesPerAssetType.map(cat => ({
          id: cat,
          label: cat + (cat === 'Trending' ? ` 🔥` : ''),
          filters: {
            ...initialMarketFilters,
            types: [API.MarketAssetType[tab.type]],
            categories: [cat],
          },
        }));

        if (acc[tab.type].length) {
          acc[tab.type].unshift(all);
        }
        return acc;
      },
      {} as UseMarketType['categoriesByAssetType']
    );
  }, [marketsData]);

  const categoriesFilter = _filters?.categories;
  const isActiveAssetCategory = useCallback(
    (filters: MarketFilters) => {
      const isAllSelected =
        !categoriesFilter?.length && !filters.categories?.length;
      return (
        isAllSelected ||
        !!filters.categories?.some(t => categoriesFilter?.includes(t))
      );
    },
    [categoriesFilter]
  );

  const toggleSearchCollapsed = useCallback(() => {
    setSearchCollapsed(c => !c);
  }, [setSearchCollapsed]);

  const _getData = useCallback(
    (filters: MarketFilters, skipFetch = false) => {
      // Allow for smooth transition of the highlighted Tab/Tag before API call
      requestAnimationFrame(() => {
        getData({
          body: filters,
          reFetch: !skipFetch,
        });
      });
    },
    [getData]
  );

  const onFiltersChanged = useCallback(
    (filters: MarketFilters, skipFetch = false) => {
      _setFilters(filters);
      _getData(filters, skipFetch);
    },
    [getData]
  );

  const _search = useCallback((searchFilters: MarketFilters) => {
    // Important: `searchFilters`
    // In order for the `_search` method not to be re-generated everytime,
    // the useCallback should not have dependencies.
    // That's why we pass the lastest filters (`searchFilters`) as arg, instead of relying on store._filters.

    // Reset types & categories when searching (global "Crypto" search)
    const newFilters = {
      ...searchFilters,
      categories: [],
      types: [API.MarketAssetType.Crypto],
    };

    _setFilters(newFilters);
    _getData(newFilters);
  }, []);

  const _debouncedSearch = useCallback(
    debounce(_search, TIMERS.INPUT_DEBOUNCE),
    [_search]
  );

  const init = useCallback(() => {
    onFiltersChanged({ ...initialMarketFilters });
  }, []);

  const setSortBy = useCallback(
    (
      sortColumn: API.MarketListAssetsSortType,
      sortDirection: API.SortDirection
    ) => {
      onFiltersChanged({ ..._filters, sortDirection, sortColumn });
    },
    [_filters]
  );

  const activeAssetType = _filters?.types?.[0] || API.MarketAssetType.Crypto;

  const onAssetTypeChanged = useCallback(
    (assetType: API.MarketAssetType, skipFetch = false) => {
      onFiltersChanged(
        {
          ...initialMarketFilters,
          ...defaultSortByAssetType[assetType],
          types: [assetType],
        },
        skipFetch
      );
    },
    []
  );

  const onSearchChanged = useCallback(
    (search: string) => {
      const searchFilters = { ..._filters, search };

      // Set latest search input as User types
      _setFilters(searchFilters);

      // But debounce the search
      _debouncedSearch(searchFilters);
    },
    [_filters]
  );

  const onPageChanged = useCallback(
    (page: number) => {
      onFiltersChanged({ ..._filters, page });
    },
    [_filters]
  );

  const hasNoData = !isLoading && !error && !data?.rows?.length;
  return {
    init,

    // main asset type
    assetTypes,
    activeAssetType,
    isActiveAssetType: (type: API.MarketAssetType) =>
      !!_filters?.types?.includes(type),
    isActiveAssetOfTypeFund: activeAssetType === API.MarketAssetType.Fund,
    onAssetTypeChanged,

    // asset sub categories
    categoriesByAssetType,
    isActiveAssetCategory,
    showCategoriesAndSearch: activeAssetType === API.MarketAssetType.Crypto,

    filters: _filters,
    onFiltersChanged,
    onSortBy: setSortBy,
    onPageChanged,

    search: _filters.search,
    onSearchChanged,

    isLoading,
    error,
    data,
    hasNoData,
    showPagination: !hasNoData && (data?.pagination.totalPages || 0) > 0,
    mobile: {
      searchCollapsed,
      toggleSearchCollapsed,
    },
  };
};
