import { useCallback, useState } from 'react';
export const useInputValueManager = () => {
    /**
     * State
     */
    const [value, setValue] = useState('');
    /**
     * Methods
     */
    const onValueChanged = useCallback(newValue => {
        if (value === newValue) {
            return;
        }
        setValue(newValue);
    }, [value]);
    /**
     * Result
     */
    return {
        value,
        onValueChanged,
    };
};
