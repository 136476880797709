import { API } from '@xbto/api-client';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { DataModel } from '../data-store';
import { Injections, AdditionalOptionsXHR, ThunkResult } from '../types';
import parseNumber from 'multi-number-parse';
import { SORT_ASSETS_BY, SORT_DIR } from '../../config';
import { shallowEqualObjects } from 'shallow-equal';
import {
  excludePropInObj,
  sortFunds,
  getApiErrorMessage,
  WithdrawCashFormikProps,
  WithdrawCashFormikField,
  factories,
  getApiErrorCode,
  createBalancesByAsset,
} from '../../utils';
import { ApiError } from '../api-error';
import { API_HTTP_CODES, ERROR_MESSAGES } from '../../constants';
import { BaseModel, createBaseModel } from '../base-store';
import { Balances, Enriched, EnrichedAccountDetailAsset } from '../../types';
import { getAccountsByWorkflow } from '../../hooks';

export type CreateWithdrawCashPayload = WithdrawCashFormikProps;

const initialFormValues = {
  fromAsset: null,
  bankAccount: null,
  amount: null,
  description: null,
};

export interface WithdrawCashModel extends BaseModel {
  // state
  formValues: WithdrawCashFormikProps | null;
  withdrawal: Enriched.FiatWithdrawal | null;
  simulation: Enriched.FiatWithdrawalSimulation | null;
  // computed
  assetBalances: Computed<WithdrawCashModel, Balances | null>;
  allowedAssets: Computed<
    WithdrawCashModel,
    EnrichedAccountDetailAsset[],
    DataModel
  >;
  allowedCanWithdrawCashAccounts: Computed<
    WithdrawCashModel,
    Enriched.ListAccountItem[],
    DataModel
  >;
  asset: Computed<
    WithdrawCashModel,
    EnrichedAccountDetailAsset | null,
    DataModel
  >;
  bankAccounts: Computed<WithdrawCashModel, API.BankAccount[], DataModel>;
  // actions
  setFormValues: Action<WithdrawCashModel, WithdrawCashFormikProps | null>;
  setWithdrawal: Action<WithdrawCashModel, Enriched.FiatWithdrawal | null>;
  setSimulation: Action<
    WithdrawCashModel,
    Enriched.FiatWithdrawalSimulation | null
  >;
  setAsset: Action<WithdrawCashModel, EnrichedAccountDetailAsset | null>;
  // thunk
  resetState: Thunk<WithdrawCashModel, undefined, Injections, DataModel>;
  create: Thunk<
    WithdrawCashModel,
    CreateWithdrawCashPayload,
    Injections,
    DataModel,
    Promise<ThunkResult<Enriched.FiatWithdrawal | null>>
  >;
  simulate: Thunk<
    WithdrawCashModel,
    AdditionalOptionsXHR & WithdrawCashFormikProps,
    Injections,
    DataModel,
    Promise<Enriched.FiatWithdrawalSimulation | null>
  >;
}

export const withdrawCashModel: WithdrawCashModel = {
  ...createBaseModel(),

  // state
  formValues: null,
  withdrawal: null,
  simulation: null,
  // computed
  assetBalances: computed([s => s.formValues?.fromAsset ?? null], asset => {
    if (!asset) {
      return null;
    }

    return createBalancesByAsset(asset);
  }),
  allowedAssets: computed(
    [(_state, storeState) => storeState.portfolio.accountDetail?.assets],
    assets => {
      if (!assets || !assets.length) {
        return [];
      }
      // only show fiat with balance & not hidden
      const result = assets.filter(a => {
        return !!(!!a.hasBalance && !!a.canSendCash && !a.currency.hidden);
      });
      return sortFunds(result, SORT_ASSETS_BY.CODE, SORT_DIR.ASC);
    }
  ),
  allowedCanWithdrawCashAccounts: computed(
    [
      (_state, storeState) => storeState.portfolio.accounts,
      (_state, storeState) => storeState.portfolio.assetHoldings?.accounts,
    ],
    (portfolioAccounts, assetAccounts) =>
      getAccountsByWorkflow({
        assetAccounts,
        portfolioAccounts,
        workflowType: 'workflow-withdraw-cash',
      })
  ),
  asset: computed([s => s.formValues], values => values?.fromAsset ?? null),
  bankAccounts: computed(
    [
      (_state, storeState) => storeState.settings.bankAccounts ?? [],
      s => s.asset?.currency.code ?? null,
    ],
    (bankAccounts, currencyCode) => {
      if (!currencyCode) {
        return bankAccounts;
      }

      return bankAccounts.filter(
        ({ currency, status }) =>
          status === API.CustomerBankAccountStatus.Ready &&
          currency?.code === currencyCode
      );
    }
  ),
  // actions
  setFormValues: action((state, payload) => {
    state.formValues = payload;
  }),
  setAsset: action((state, payload) => {
    state.formValues = {
      ...(state.formValues ?? initialFormValues),
      fromAsset: payload,
    };
  }),
  setWithdrawal: action((state, payload) => {
    state.withdrawal = payload;
  }),
  setSimulation: action((state, payload) => {
    state.simulation = payload;
  }),
  // thunk
  resetState: thunk(actions => {
    actions.setError(null);
    actions.setFormValues(null);
    actions.setSimulation(null);
    actions.setWithdrawal(null);
  }),
  create: thunk(async (actions, values, { injections, getStoreState }) => {
    const storeState = getStoreState();

    try {
      actions.setBusy(true);
      actions.setError(null);

      const amount = values.amount;
      const bankAccountId = values.bankAccount?.id;
      const currencyCode = values.fromAsset?.currency.code;

      if (!amount || !bankAccountId || !currencyCode) {
        throw new ApiError(
          API_HTTP_CODES.UNPROCESSABLE_ENTITY,
          ERROR_MESSAGES.UNPROCESSABLE_ENTITY,
          -1
        );
      }

      injections.apiClient.setAdditionalHeaders({
        'x-account-id': storeState.portfolio.accountDetail?.account?.accountId,
      });

      const balance = parseNumber(values?.fromAsset?.quantity || '0');
      const isMax = parseNumber(amount) >= balance;

      const res = await injections.apiClient.createFiatWithdrawal({
        amount,
        bankAccountId,
        currencyCode,
        description: values.description,
        googleAuthenticatorCode: null, // deprecated (Auth0)
        subtractFeeFromAmount: isMax,
        appAuthenticatorCode: null,
      });

      if (!res.isSuccessful) {
        actions.setError(res.errorMessage);
        return {
          isSuccessful: false,
          errorMessage: res.errorMessage,
          errorCode: res.errorCode,
          resutl: null,
        };
      }

      const enrichedResult = factories.enrichFiatWithdrawal(
        res.result,
        storeState.metaData.fiatCurrencyCodes
      );

      actions.setWithdrawal(enrichedResult);
      return {
        isSuccessful: true,
        resutl: enrichedResult,
      };
    } catch (error) {
      const errorMessage = getApiErrorMessage(error);
      const errorCode = getApiErrorCode(error);
      actions.setError(errorMessage);
      return {
        isSuccessful: false,
        errorMessage,
        errorCode,
        resutl: null,
      };
    } finally {
      actions.setBusy(false);
    }
  }),
  simulate: thunk(
    async (
      actions,
      { isBackgroundXHR = false, ...formProps },
      { injections, getStoreState, getState }
    ) => {
      const state = getState();
      const storeState = getStoreState();
      try {
        if (
          shallowEqualObjects(
            excludePropInObj<WithdrawCashFormikProps | null>(
              state.formValues,
              WithdrawCashFormikField.description
            ),
            excludePropInObj<WithdrawCashFormikProps>(
              formProps,
              WithdrawCashFormikField.description
            )
          )
        ) {
          actions.setFormValues(formProps);
          return null;
        }

        actions.setError(null);
        actions.setSimulation(null);
        actions.setFormValues(formProps);

        const balance = parseNumber(formProps.fromAsset?.quantity || 0);
        const amount = parseNumber(formProps.amount || 0);
        const isMax = amount >= balance;

        const body: API.SimulateFiatWithdrawalRequest = {
          currencyCode: formProps.fromAsset?.currency.code as string,
          amount: formProps.amount as string,
          subtractFeeFromAmount: isMax,
          description: formProps.description as string,
          bankAccountId: formProps.bankAccount?.id as string,
        };

        if (!isBackgroundXHR) {
          actions.setBusy(true);
        }

        injections.apiClient.setAdditionalHeaders({
          'x-account-id':
            storeState.portfolio.accountDetail?.account?.accountId,
        });

        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.simulateFiatWithdrawal(body);
        if (!isSuccessful || !result) {
          actions.setError(errorMessage);
          if (!isBackgroundXHR) {
            actions.setBusy(false);
          }
          return null;
        }

        if (!isBackgroundXHR) {
          actions.setBusy(false);
        }
        const enrichedResult = factories.enrichFiatWithdrawaSimulation(
          result,
          storeState.metaData.currencies ?? [],
          storeState.metaData.fiatCurrencyCodes
        );
        actions.setSimulation(enrichedResult);
        return enrichedResult;
      } catch (error) {
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          actions.setBusy(false);
        }
        actions.setError(message);
        return null;
      }
    }
  ),
};
