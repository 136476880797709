import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { enrichTransaction } from './enrich-transaction';

export const enrichActivities = (
  activities: API.ActivityTransaction[],
  fiatCurrencyCodes: string[]
): Enriched.ActivityTransaction[] => {
  return activities.map(activity =>
    enrichTransaction(activity, fiatCurrencyCodes)
  );
};

export const enrichActivitiesPaginatedResponse = (
  res: API.ActivityTransactionArrayPaginatedApiResponse,
  fiatCurrencyCodes: string[]
): Enriched.ActivityTransactionArrayPaginatedApiResponse => {
  const enrichedActivities = enrichActivities(
    res.result ?? [],
    fiatCurrencyCodes
  );

  return {
    ...res,
    result: enrichedActivities,
  };
};
