import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { _KytWrapper } from './_kyt-wrapper';
import { Button, Col, TextInput } from '../../atoms';
import { useInputValueManager } from '../../hooks';
import { defaultWidthForInputsAndButtons } from './shared';
const KytOwnerCompanyInner = ({ onNext }) => {
    /**
     * Hooks
     */
    const { value: companyName, onValueChanged: onCompanyNameChanged } = useInputValueManager();
    /**
     * Methods
     */
    const handleOnNext = useCallback(() => {
        onNext({ companyName });
    }, [companyName, onNext]);
    /**
     * DOM
     */
    const isNextDisabled = companyName.length <= 0;
    return (_jsxs(_Fragment, { children: [_jsx(TextInput, { placeholder: "Enter name of company", style: defaultWidthForInputsAndButtons, value: companyName, onValueChange: onCompanyNameChanged }), _jsx(Col, Object.assign({ mt: "xs" }, { children: _jsx(Button, { variant: "secondary", label: "Next", disabled: isNextDisabled, onPress: handleOnNext }) }))] }));
};
export const KytOwnerCompany = ({ onNext }) => {
    /**
     * DOM
     */
    return (_jsx(_KytWrapper, Object.assign({ title: "Enter the name of the wallet owner", subtitle: "To adhere to rules within your jurisdiction we need to know who owns the wallet you are withdrawing to" }, { children: _jsx(KytOwnerCompanyInner, { onNext: onNext }) })));
};
