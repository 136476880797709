import { API } from '@xbto/api-client';
import { StablehousePusherChannel } from './subscribe-pusher';

const getAccountIdFromChannelName = (
  channelName: StablehousePusherChannel,
  env: string,
  accountIds: string[],
  userId: string
): string | null => {
  const curatedChannelName = channelName
    // user profile -> status's
    .replace(`${StablehousePusherChannel.USER_EMAIL_VERIFICATION}-`, '')

    // meta data
    .replace(`${StablehousePusherChannel.FX_RATES}-`, '')

    // user account -> updates
    .replace(`${StablehousePusherChannel.ACCOUNT_DETAILS_STATUS}-`, '')
    .replace(`${StablehousePusherChannel.USER_BALANCE_ORGANIZATION}-`, '')
    .replace(`${StablehousePusherChannel.WITHDRAWAL_STATUS}-`, '')
    .replace(`${StablehousePusherChannel.TRANSFER_STATUS}-`, '')
    .replace(`${StablehousePusherChannel.FIAT_WITHDRAWAL_STATUS}-`, '')

    // userId
    .replace(userId, '')

    // env
    .replace(`-${env}`, '');

  const id = accountIds.find(id => {
    return curatedChannelName.includes(id);
  });

  return id || null;
};

/**
 * Helper fn to authorize pusherks
 * @param channel
 */
export const authorizePusher = (
  channel: any,
  apiClient: API.StablehouseClient,
  env: string,
  accountIds: string[],
  userId: string
) => {
  return {
    authorize: async (socketId: string, callback: any) => {
      const channelName = channel.name as StablehousePusherChannel;

      if (channelName.startsWith(`public-`)) {
        callback(null, true);
        return;
      }

      // eslint-disable-line
      const request = {
        channelName,
        socket: socketId,
      };

      const accId = getAccountIdFromChannelName(
        channelName,
        env,
        accountIds,
        userId
      );
      if (accId) {
        apiClient.setAdditionalHeaders({
          'x-account-id': accId,
        });
      }

      try {
        const { isSuccessful, result, errorMessage, errorCode } =
          await apiClient.authenticateToRealtimeChannels(request);
        if (!isSuccessful) {
          callback(errorMessage || errorCode || `Authorize pusher failed!`);
          return;
        }
        callback(null, result);
      } catch (error) {
        callback(error);
      }
    },
  };
};
