import { FC, useEffect } from 'react';
import cx from 'classnames';
import { API } from '@xbto/api-client';
import { countryNamesByCode, CurrencyIcon } from 'common';
import { DataStore } from '../../store';

interface DataPointProps {
  title: string;
  value: string | JSX.Element;
  w?: number;
}

const DataPoint: FC<DataPointProps> = ({ title, value, w = 1 }) => {
  return (
    <div
      className={cx('mb-6 px-1', {
        'w-full': w === 2,
        'w-1/2': w === 1,
      })}
    >
      <p className="text-gray-400">{title}</p>
      {typeof value === 'string' ? (
        <p className="font-bold break-words">{value}</p>
      ) : (
        value
      )}
    </div>
  );
};

interface Props {
  bankAccount?: API.BankAccount;
  id?: string | null;
  accountId?: string | null;
}

export const ShowBankAccountDetails: FC<Props> = ({
  bankAccount,
  accountId,
  id,
}) => {
  /**
   * Store
   */
  const getBankAccount = DataStore.useStoreActions(
    s => s.settings.getBankAccount
  );
  const accountStore = DataStore.useStoreState(s => s.settings.bankAccount);
  const addHeader = DataStore.useStoreActions(s => s.addHeader);
  const removeHeader = DataStore.useStoreActions(s => s.removeHeader);

  useEffect(() => {
    if (id && accountId) {
      (async () => {
        addHeader({
          'x-impersonated-account-id': accountId,
        });
        await getBankAccount({ request: { id: id }, isBackgroundXHR: false });
        removeHeader('x-impersonated-account-id');
      })();
    }
  }, [id, accountId]);
  const account = accountStore || bankAccount;
  if (!account || !account.bankAccountDetails) {
    return null;
  }
  const details = account.bankAccountDetails;
  return (
    <>
      {id && (
        <h4 className="text-md md:text-xl font-bold mb-10">
          {details?.bankName}
        </h4>
      )}
      <div className="flex flex-row flex-wrap">
        <DataPoint
          title="Bank country"
          value={
            countryNamesByCode[details.bankCountry || ''] ?? details.bankCountry
          }
        />
        <DataPoint
          title="Account currency"
          value={<CurrencyIcon currencyCode={account.currency?.code || ''} />}
        />

        <DataPoint
          title="BIC / Swift code"
          value={details?.bankIdentifier || ''}
        />
        <DataPoint
          title="Account number"
          value={details?.accountNumber || ''}
        />

        <DataPoint
          title="Intermediary bank"
          value={details?.intermediaryBankIdentifier || ''}
        />
        <DataPoint
          title="Intermediary account number"
          value={details?.intermediaryAccountNumber || ''}
        />

        <DataPoint
          title="Account holder name"
          value={details?.accountHolderName || ''}
          w={2}
        />

        <DataPoint
          title="Personal address"
          value={details?.recipientAddress1 || ''}
          w={2}
        />

        <DataPoint title="City or town" value={details?.recipientCity || ''} />
        <DataPoint title="State" value={details?.recipientState || '-'} />

        <DataPoint
          title="Postal or zip code"
          value={details?.recipientPostalCode || ''}
        />
      </div>
    </>
  );
};
