import {
  action,
  Action,
  actionOn,
  ActionOn,
  createContextStore,
  thunk,
  Thunk,
} from 'easy-peasy';
import { API } from '@xbto/api-client';
import { createBaseModel, BaseModel } from './base-store';
import { UserModel, userModel } from './user-store';
import { transactionsModel, TransactionsModel } from './transactions-store';
import { transferModel, TransferModel } from './transfer-store';
import { settingsModel, SettingsModel } from './settings-store';
import { buySellModel, BuySellModel } from './buy-sell-store';
import { addCashModel, AddCashModel } from './add-cash';
import {
  ApiTenant,
  AdditionalOptionsXHR,
  Injections,
  AdditionalHeaderKeys,
  AdditionalHeaderTypes,
} from './types';
import { sendModel, SendModel } from './send/store';
import { withdrawCashModel, WithdrawCashModel } from './withdraw-cash';
import { receiveModel, ReceiveModel } from './receive';
import { quorumModel, QuorumModel } from './quorum-store';
import { AppTenant, MarketsDataAggregator } from '../utils';
import { UserAlertsModel, userAlertsModel } from './user-alerts-store';
import { AppStorage } from '../types';
import { porfolioModel, PortfolioModel } from './portfolio';
import { MetaDataModel, metaDataModel } from './meta-data-store';
import { AdminModel, adminModel } from './admin-store';
import { DEFAULTS } from '../constants';
import { statementsModel, StatementsModel } from './statements';
import { invoicesModel, InvoicesModel } from './invoices-store';
import { marketsModel, MarketsModel } from './markets';

const marketsDataAggregator = MarketsDataAggregator();

type CurrentViewName = 'markets-list' | 'other';

export interface DataModel extends BaseModel {
  // reset
  resetStore: Thunk<DataModel, undefined, Injections, DataModel>;

  currentViewName: CurrentViewName;
  setCurrentViewName: Action<DataModel, CurrentViewName>;

  // state variables
  client: 'web' | 'mobile';

  booted: boolean | null;
  bootedPusher: boolean | null;
  additionalHeaders: AdditionalHeaderKeys;
  tenant: AppTenant | null;
  // nested store models
  portfolio: PortfolioModel;
  markets: MarketsModel;
  metaData: MetaDataModel;
  admin: AdminModel;
  // flows
  addCash: AddCashModel;
  user: UserModel;
  transactions: TransactionsModel;
  statements: StatementsModel;
  invoices: InvoicesModel;
  settings: SettingsModel;
  receive: ReceiveModel;
  buySell: BuySellModel;
  transfer: TransferModel;
  send: SendModel;
  withdrawCash: WithdrawCashModel;
  quorum: QuorumModel;
  alerts: UserAlertsModel;
  // actionOn
  onTenant: ActionOn<DataModel, DataModel>;
  onGlobalAppSettings: ActionOn<DataModel, DataModel>;
  // actions
  setClient: Action<DataModel, 'web' | 'mobile'>;
  setBooted: Action<DataModel, boolean>;
  setBootedPusher: Action<DataModel, boolean>;
  addHeader: Action<DataModel, AdditionalHeaderKeys>;
  removeHeader: Action<DataModel, AdditionalHeaderTypes>;
  removeHeaders: Action<DataModel>;
  setTenant: Action<DataModel, AppTenant | null>;
  // thunks
  init: Thunk<
    DataModel,
    ApiTenant &
      AdditionalOptionsXHR & {
        client: 'web' | 'mobile';
        queryParams?: { [key: string]: string };
      },
    Injections,
    DataModel,
    Promise<void>
  >;

  // actionOn
  onBusy: ActionOn<DataModel, DataModel>;
}

export const dataModel: DataModel = {
  // reset
  resetStore: thunk((actions, _payload, { getStoreActions, injections }) => {
    const storeActions = getStoreActions();

    storeActions.metaData.resetStore();
    storeActions.portfolio.resetStore();
    storeActions.user.resetStore();

    actions.removeHeaders();
    actions.setBooted(false);
    actions.setBootedPusher(false);
    actions.setError(null);
    actions.setBusy(false);

    injections.apiClient.setDefaultAccountId(null);
  }),

  // state variables
  ...createBaseModel(),

  currentViewName: 'other',
  setCurrentViewName: action((state, payload) => {
    state.currentViewName = payload;
  }),

  client: 'web',
  booted: null,
  bootedPusher: null,
  additionalHeaders: {},
  tenant: null,
  // nested store models
  portfolio: porfolioModel,
  markets: marketsModel,
  metaData: metaDataModel,
  admin: adminModel,
  // flows
  addCash: addCashModel,
  user: userModel,
  transactions: transactionsModel,
  statements: statementsModel,
  invoices: invoicesModel,
  settings: settingsModel,
  receive: receiveModel,
  buySell: buySellModel,
  transfer: transferModel,
  send: sendModel,
  withdrawCash: withdrawCashModel,
  quorum: quorumModel,
  alerts: userAlertsModel,
  // actions
  setClient: action((state, payload) => {
    state.client = payload;
  }),
  setBooted: action((state, payload) => {
    state.booted = payload;
  }),
  setBootedPusher: action((state, payload) => {
    state.bootedPusher = payload;
  }),
  addHeader: action((state, payload) => {
    Object.keys(payload).forEach(value => {
      state.additionalHeaders[value] = payload[value];
    });
  }),
  removeHeader: action((state, payload) => {
    if (state.additionalHeaders[payload]) {
      delete state.additionalHeaders[payload];
    }
  }),
  removeHeaders: action(state => {
    Object.keys(state.additionalHeaders).forEach(value => {
      delete state.additionalHeaders[value];
    });
  }),
  setTenant: action((state, payload) => {
    state.tenant = payload;
  }),
  // actionOn
  onTenant: actionOn(
    (_actions, storeActions) => storeActions.setTenant,
    state => {
      state.user.isClient = state.tenant !== 'Stablehouse';
    }
  ),
  onGlobalAppSettings: actionOn(
    (_actions, storeActions) => storeActions.settings.setGlobalAppSettings,
    (state, target) => {
      const tenant = state.tenant;
      const accountSettings = target.payload;
      if (!tenant || !accountSettings) {
        state.user.clientUserType = null;
        return;
      }
      if (
        state.user.isClient &&
        accountSettings?.userType === API.UserType.Regular
      ) {
        state.user.clientUserType = 'fund-manager';
        return;
      }
      if (
        accountSettings?.userType === API.UserType.ClientAdminReadOnly ||
        accountSettings?.userType === API.UserType.ClientAdminReadWrite
      ) {
        state.user.clientUserType = 'admin';
        return;
      }

      state.user.clientUserType = 'none';
    }
  ),
  // thunks
  init: thunk(
    async (
      actions,
      { client, tenant, isBackgroundXHR = false, throwOnError = false },
      { getStoreActions }
    ) => {
      try {
        actions.setClient(client);

        const storeActions = getStoreActions();
        const accSettings = await storeActions.settings.getAccountSettings({
          isBackgroundXHR,
          throwOnError,
        });
        const isAdmin = !!(
          accSettings?.userType === API.UserType.ClientAdminReadOnly ||
          accSettings?.userType === API.UserType.ClientAdminReadWrite
        );

        const necessaryApiCalls = [
          storeActions.alerts.getMessages(),
          storeActions.metaData.getCurrencies({
            isBackgroundXHR,
            tenant,
            throwOnError,
          }),
          storeActions.metaData.getFxRates({ isBackgroundXHR, throwOnError }),
          storeActions.metaData.getStaticData({
            isBackgroundXHR,
            throwOnError,
          }),
        ];
        if (isAdmin) {
          necessaryApiCalls.push(
            storeActions.admin.getFundAccounts({
              page: DEFAULTS.PAGE,
              pageSize: DEFAULTS.PAGE_SIZE,
              search: '',
            })
          );
        } else {
          necessaryApiCalls.push(
            storeActions.portfolio.getPortfolio({
              isBackgroundXHR,
            })
          );
        }
        await Promise.all(necessaryApiCalls);

        if (!isAdmin) {
          // these are backgrounded & not necessary for a fund-admin
          const backgroundedApiCalls = [
            storeActions.settings.getBankAccounts({
              isBackgroundXHR: true,
              request: {},
            }),
          ];
          backgroundedApiCalls.push(
            storeActions.settings.cryptoAddresses.getWithdrawalAddresses({
              accountId: undefined,
            })
          );
          backgroundedApiCalls.push(
            storeActions.settings.getTag({ isBackgroundXHR: true })
          );
          backgroundedApiCalls.push(
            storeActions.user.getProfile({ isBackgroundXHR: true })
          );
          // TODO: check if we @deprecate this and make tickets
          backgroundedApiCalls.push(
            storeActions.settings.getRewards({ isBackgroundXHR: true })
          );
          if (client === 'web') {
            Promise.all(backgroundedApiCalls);
          }

          // intentionally not writing as an `else` block for readability reasons
          if (client === 'mobile') {
            /**
             * Note:
             * Only really need to await for `android`, but did not want to expand the `client` enum
             * awaiting to solve for UIthread being blocked by network calls and responses,
             */
            await Promise.all(backgroundedApiCalls);
          }
        }

        storeActions.markets.startAggregatorTracking();
        actions.setBooted(true);

        return Promise.resolve();
      } catch (error) {
        actions.setBooted(false);
        return Promise.reject(error);
      }
    }
  ),

  onBusy: actionOn(
    (_actions, storeActions) => [
      storeActions.receive.setBusy,
      // storeActions.addCash.setBusy, // TODO(Benoit): Listen to all sub-stores.setBusy actions
    ],
    (state, { payload }) => {
      if (state.client !== 'mobile') {
        state.busy = payload;
      }
    }
  ),
};

export const getDataStore = (
  apiClient: API.StablehouseClient,
  storage?: AppStorage
) => {
  return createContextStore<DataModel>(dataModel, {
    name: 'Data Store',
    injections: {
      apiClient,
      storage,
      marketsDataAggregator,
    },
  });
};

export type DataStoreType = ReturnType<typeof getDataStore>;
