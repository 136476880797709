import { FC, useCallback, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import StepWizard from 'react-step-wizard';
import { Form } from './form';
import { Summary } from './summary';
import { defaultStepWizardProps } from '../../../constants';
import { Preview } from './preview';
import { Code } from './code';
import { ConfirmCancelSend } from './confirm-cancel';
import { Cancelled } from './cancelled';
import { AppStore, DataStore } from '../../../store';
import { SendSteps } from './steps';
import { AccountLocked } from '../shared/account-locked';
import { AddressBook } from './address-book';
import { PreviewTwoFaWidget } from './preview-two-fa-widget';
import { EnrichedCryptoAddress, useAppDialogHelper, useSendForm } from 'common';
import { AddCryptoAddress } from './add-crypto-address';
import { apiClient } from '~/api/client';
import { Kyt } from './kyt';

export const WorkflowSend: FC = () => {
  /**
   * Vars
   */
  const ref = useRef<HTMLDivElement | null>(null);

  /**
   * Store
   */
  const setError = DataStore.useStoreActions(a => a.send.setError);

  const resetState = DataStore.useStoreActions(a => a.send.resetState);
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );

  const { setDestination, setWithdrawalAddress, withdrawalAddresses } =
    useSendForm({
      DataStore,
      apiClient,
    });

  const handleCancelAddressBook = useCallback(() => {
    setDestination('');
  }, [setDestination]);

  const handleChangeAddress = useCallback(
    (address: EnrichedCryptoAddress) => {
      setWithdrawalAddress(address);
    },
    [setWithdrawalAddress]
  );

  /**
   * Hooks
   */
  const appDialogHelper = useAppDialogHelper();
  useEffectOnce(() => {
    appDialogHelper.init(ref.current);

    return () => {
      console.log(`Send funds dialog => reset`);
      setDashboardSelectedAsset(null);
      resetState();
    };
  });

  /**
   * Methods
   */
  return (
    <div className="relative text-primary h-full" ref={ref}>
      <StepWizard
        {...defaultStepWizardProps}
        className="h-full"
        onStepChange={({ activeStep }) => {
          setError(null);

          const closeBtnHiddenSteps = [4, 5, 6];
          if (!closeBtnHiddenSteps.includes(activeStep)) {
            appDialogHelper.closeButton.show();
            return;
          }
          appDialogHelper.closeButton.hide();
        }}
      >
        <Form stepName={SendSteps.Form} />
        <AddressBook
          stepName={SendSteps.AddressBook}
          addresses={withdrawalAddresses}
          onCancel={handleCancelAddressBook}
          onChangeAddress={handleChangeAddress}
        />
        <Kyt stepName={SendSteps.Kyt} />
        <Preview stepName={SendSteps.Preview} />
        <PreviewTwoFaWidget stepName={SendSteps.PreviewTwoFaWidget} />
        <Code stepName={SendSteps.Code} />
        <ConfirmCancelSend stepName={SendSteps.ConfirmCancellation} />
        <Cancelled stepName={SendSteps.Cancelled} />
        <Summary stepName={SendSteps.Summary} />
        <AccountLocked stepName={SendSteps.AccountLocked} />
        <AddCryptoAddress stepName={SendSteps.AddCryptoAddress} />
      </StepWizard>
    </div>
  );
};
