var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Image } from '../../atoms';
// TODO(Benoit): Envify
const bucketUrl = 'https://prod-stablehouse-assets.s3.eu-west-1.amazonaws.com';
export function BucketImage(_a) {
    var { responsive = true, path } = _a, rest = __rest(_a, ["responsive", "path"]);
    return (_jsx(Image, Object.assign({}, rest, { src: `${bucketUrl}/${path}`, responsive: responsive })));
}
