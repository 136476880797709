import { FC } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { DataStore } from '../../../store';
import { TransactionSummarySteps } from './steps';
import { API } from '@xbto/api-client';
import { ConfirmCancel } from '~/components/workflows/shared/confirm-cancel';
import { isInternalTransfer, isTransfer } from 'common';

type Props = {
  activity: API.ActivityTransaction | null;
} & Partial<StepWizardChildProps>;

export const ConfirmCancelation: FC<Props> = ({ activity, goToNamedStep }) => {
  if (!goToNamedStep) {
    return null;
  }

  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const cancelTransfer = DataStore.useStoreActions(
    s => s.transactions.cancelTransfer
  );
  const cancelFiatWithdrawal = DataStore.useStoreActions(
    s => s.transactions.cancelFiatWithdrawal
  );
  const cancelWithdrawal = DataStore.useStoreActions(
    s => s.transactions.cancelWithdrawal
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  /**
   * Methods
   */
  const cancel = async (id: string) => {
    if (activity?.type === API.ActivityType.Withdrawal) {
      return await cancelWithdrawal({
        request: {
          withdrawalId: id,
        },
        accountId: accountDetail?.account?.accountId || '',
      });
    }

    if (isTransfer(activity) || isInternalTransfer(activity)) {
      return await cancelTransfer({
        request: { transferId: id },
        accountId: accountDetail?.account?.accountId || '',
      });
    }

    if (activity?.type === API.ActivityType.FiatWithdrawal) {
      return await cancelFiatWithdrawal({
        request: {
          id,
        },
        accountId: accountDetail?.account?.accountId || '',
      });
    }
  };
  const handleOnCancelActivity = async () => {
    const id = activity?.id;
    if (!id) {
      return;
    }
    const result = await cancel(id);
    if (!result?.isSuccessful) {
      return;
    }
    goToNamedStep(TransactionSummarySteps.Cancelled);
  };

  /**
   * DOM
   */
  return (
    <ConfirmCancel
      title="Are you sure you want to cancel this transaction?"
      subTitle="If you cancel, your transaction will no longer be processed."
      noActionText="Continue"
      submitText="Cancel request"
      error={error}
      onNoActionClick={() => goToNamedStep(TransactionSummarySteps.Records)}
      onSubmitClick={handleOnCancelActivity}
    />
  );
};
