import { API } from '@xbto/api-client';
import {
  Enriched,
  EnrichedCurrencyInformation,
  EnrichedInternalTransfer,
  getCurrencyInfoForCode,
} from 'common';
import {
  ActivityAmount,
  ActivityDate,
  ActivityDescription,
  ActivityHash,
  ActivityNetwork,
} from '../shared';
import {
  CellActivityStatus,
  CellTransferAccount,
} from '~/components/transactions/cells';

export const getTransferRecords = (
  data: EnrichedInternalTransfer,
  status: string,
  currencies: EnrichedCurrencyInformation[],
  sourceAccount: Enriched.ListAccountItem | undefined,
  destinationAccount: Enriched.ListAccountItem | undefined
) => {
  const ccyInfo = getCurrencyInfoForCode(data.currencyCode, currencies);
  const feeCcyInfo = getCurrencyInfoForCode(data.feeCurrency, currencies);
  return {
    '': <CellActivityStatus {...data} status={status} />,
    'You send': (
      <ActivityAmount
        sign="-"
        amount={data.formattedAmount}
        formatted
        meta={
          <>
            <p className="text-gray-darker font-normal text-sm -mt-1 mb-2">
              {data.formattedAmountUsd}
            </p>
            <CellTransferAccount data={sourceAccount} />
          </>
        }
      />
    ),
    Network: <ActivityNetwork ccyInfo={ccyInfo} />,
    Fee: (
      <ActivityAmount
        amount={
          data.feeMode === API.WithdrawalFeeChargeMode.ChargedImmediately
            ? data.formattedFee
            : data.formattedFeeUsd
        }
        formatted
        code={
          data.feeMode === API.WithdrawalFeeChargeMode.ChargedImmediately
            ? feeCcyInfo?.displayCode
            : `USD`
        }
      />
    ),
    Receive: (
      <ActivityAmount
        sign="+"
        amount={data.formattedSendingAmount}
        formatted
        meta={<CellTransferAccount data={destinationAccount} />}
      />
    ),
    'Reference code': <ActivityHash hash={data.humanReadableIdentifier} />,
    'Transaction hash': <ActivityHash hash={data.txId} />,
    Date: <ActivityDate date={data.creationDate} />,
    Description: <ActivityDescription value={data.description} />,
  };
};
