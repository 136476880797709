import { API } from '@xbto/api-client';
import { getSimulationRateLabel } from '../simulation';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { TradeFormValues } from '../../store';

export const enrichTradeSimulation = (
  simulation: API.TradeSimulation | null,
  formValues: TradeFormValues,
  fiatCurrencyCodes: string[],
  currencies: EnrichedCurrencyInformation[]
): Enriched.TradeSimulation | null => {
  if (
    simulation?.amountFrom &&
    simulation?.amountTo &&
    simulation?.amountFromHomeCurrencyEquivalent &&
    simulation?.amountToHomeCurrencyEquivalent &&
    simulation?.currencyFrom &&
    simulation?.currencyTo &&
    simulation?.currencyDisplayFrom &&
    simulation?.currencyDisplayTo
  ) {
    const isSell = formValues.side === API.Side.Sell;

    const amountFromWithCurrencyCode = formatters.getCurrency(
      simulation.amountFrom,
      undefined,
      simulation.currencyDisplayFrom,
      fiatCurrencyCodes
    );
    const amountToWithCurrencyCode = formatters.getCurrency(
      simulation.amountTo,
      undefined,
      simulation.currencyDisplayTo,
      fiatCurrencyCodes
    );
    const amountUsdFromWithCurrencyCode = formatters.getCurrency(
      simulation.amountFromHomeCurrencyEquivalent,
      undefined,
      'USD',
      fiatCurrencyCodes
    );
    const amountUsdToWithCurrencyCode = formatters.getCurrency(
      simulation.amountToHomeCurrencyEquivalent,
      undefined,
      'USD',
      fiatCurrencyCodes
    );

    return {
      ...simulation,
      isSell,
      side: formValues.side,
      formatted: {
        amountWithCurrencyCode: isSell
          ? amountFromWithCurrencyCode
          : amountToWithCurrencyCode,
        amountFromWithCurrencyCode,
        amountToWithCurrencyCode,
        amountUsdWithCurrencyCode: isSell
          ? amountUsdFromWithCurrencyCode
          : amountUsdToWithCurrencyCode,
        amountUsdFromWithCurrencyCode,
        amountUsdToWithCurrencyCode,
        rateFromWithCurrencyCode: getSimulationRateLabel(
          simulation,
          simulation.currencyTo,
          currencies
        ),
        rateToWithCurrencyCode: getSimulationRateLabel(
          simulation,
          simulation.currencyFrom,
          currencies
        ),
      },
    };
  }
  return null;
};
