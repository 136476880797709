import { API } from '@xbto/api-client';
import { DATE_FORMATS } from 'common';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { DataStore } from '../../store';
import { CreateApiKey } from '../apikeys/add';
import { DeleteApiKey } from '../apikeys/delete';
import { ShowApiKey } from '../apikeys/show';
import { AppIcon, AppDialog } from 'common';

const Header: FC = () => {
  return (
    <div className="flex flex-row items-center text-gray-400 text-sm justify-between pt-4 pb-6 border-t border-grey-bright px-2">
      <div className="flex-2">Key</div>
      <div style={{ width: 150 }}>Creation date</div>
      <div style={{ width: 150 }}>Last usage date</div>
      <div className="mr-2">Details</div>
    </div>
  );
};

const Date: FC<{ date: Date }> = ({ date }) => {
  return (
    <div className="flex flex-col items-start">
      <span className="text-lg font-bold">
        {format(date, DATE_FORMATS.DD_MMM_YYYY)}
      </span>
      <span className="text-sm text-gray-400">
        {format(date, DATE_FORMATS.HH_MM_TZ)}
      </span>
    </div>
  );
};

interface RowProps {
  apiKeys: API.ApiKey[] | null;
  onShow: (key: string) => void;
}

const ApiKeys: FC<RowProps> = ({ apiKeys, onShow }) => {
  return (
    <>
      {(apiKeys || []).map(apiKey => {
        return (
          <div
            key={apiKey.key}
            className="flex flex-row items-center hover-bg-grey-brighter text-gray-400 text-sm justify-between p-4 cursor-pointer outline:none focus:outline-none border rounded-4 mb-2"
            onClick={() => onShow(apiKey.key!)}
          >
            <div className="flex-2" style={{ width: 150 }}>
              <div className={'flex flex-col'}>
                <span className="text-primary font-bold">{apiKey.name}</span>
                <span className="text-xs text-gray-400">{apiKey.key}</span>
                <span className="text-xs text-gray-400">
                  {apiKey.ipAddresses}
                </span>
              </div>
            </div>
            <div
              className="text-center text-primary mr-3"
              style={{ width: 150 }}
            >
              <Date date={apiKey.creationDate} />
            </div>
            <div
              className="text-center text-primary mr-3"
              style={{ width: 150 }}
            >
              {apiKey.lastUsageDate ? (
                <Date date={apiKey.lastUsageDate} />
              ) : (
                '-'
              )}
            </div>
            <AppIcon
              icon="chevron-right"
              size={14}
              bg="bg-transparent"
              cls="ml-6"
              fill="fill-current-color-primary"
            />
          </div>
        );
      })}
    </>
  );
};

export const SettingsApiKeys: FC = () => {
  /**
   * Store
   */
  const apiKeys = DataStore.useStoreState(s => s.settings.apiKeys);
  const getApiKeys = DataStore.useStoreActions(a => a.settings.getApiKeys);
  const setError = DataStore.useStoreActions(a => a.setError);

  /**
   * State
   */
  const [showAddApiKeyDialog, setShowAddApiKeyDialog] = useState(false);
  const [toDeleteApiKeyId, setToDeleteApiKeyId] = useState<string | null>(null);
  const [toShowApiKey, setToShowApiKey] = useState<
    API.CreateApiKeyResponse | API.ApiKey | null
  >(null);

  /**
   * Hooks
   */
  useEffect(() => {
    getApiKeys();
  }, []);
  useEffect(() => {
    if (!toDeleteApiKeyId) {
      setError(null);
      getApiKeys();
    }
  }, [toDeleteApiKeyId]);

  /**
   * DOM
   */

  const isEmpty = (apiKeys || []).length === 0;

  return (
    <div className="">
      {/* content  */}
      <section className="flex flex-col">
        <nav className="flex flex-row justify-between items-center pb-3">
          <p className="font-bold">Api Keys</p>
          <button
            className="font-bold flex flex-row items-center"
            onClick={e => {
              e.preventDefault();
              setShowAddApiKeyDialog(true);
            }}
          >
            <span className="hidden sm:inline mr-1">Create a new API Key</span>
            <span className="sm:hidden mr-1">Add new</span>
            <AppIcon icon="plus-accent" size="sm" />
          </button>
        </nav>
        {/* table  */}
        <main>
          {/* header  */}
          <Header />
          {/* content  */}

          {isEmpty && (
            <div className="flex flex-col items-center">
              <AppIcon icon="institutions" cls="mt-10 mb-4 p-3 justify-center items-center text-secondary" />
              <h4 className="text-center">You have no API Key</h4>
              <button
                type="button"
                className="app-button-accent flex-1 flex items-center justify-center mt-6 mb-10"
                onClick={e => {
                  e.preventDefault();
                  setShowAddApiKeyDialog(true);
                }}
              >
                Add an API Key
              </button>
            </div>
          )}
          {!isEmpty && (
            <ApiKeys
              apiKeys={apiKeys}
              onShow={key =>
                setToShowApiKey(apiKeys?.find(i => i.key == key) || null)
              }
            />
          )}
        </main>
      </section>

      {/* add api key dialog  */}
      <AppDialog
        isOpen={showAddApiKeyDialog}
        onClose={() => {
          setShowAddApiKeyDialog(false);
        }}
      >
        <CreateApiKey
          onClose={(apiKey: API.CreateApiKeyResponse | null) => {
            setShowAddApiKeyDialog(false);
            setToShowApiKey(apiKey);
          }}
        />
      </AppDialog>

      {/* delete api key dialog  */}
      <AppDialog
        isOpen={toDeleteApiKeyId !== null}
        onClose={() => setToDeleteApiKeyId(null)}
      >
        <DeleteApiKey
          apiKeyId={toDeleteApiKeyId as string}
          onClose={() => setToDeleteApiKeyId(null)}
        />
      </AppDialog>

      {/* view api key dialog  */}
      <AppDialog
        isOpen={!!toShowApiKey}
        onClose={() => setToShowApiKey(null)}
        widthCls="max-w-2xl"
      >
        <ShowApiKey
          apiKey={toShowApiKey!}
          onClose={() => setToShowApiKey(null)}
          onDelete={apiKey => setToDeleteApiKeyId(apiKey)}
        />
      </AppDialog>
    </div>
  );
};
