import { EnrichedCurrencyInformation, Enriched, formatters } from 'common';
import { ActivityDate, ActivityHash, ActivityRate } from '../shared';
import {
  CellActivityStatus,
  CellTransferAccount,
} from '~/components/transactions/cells';
import { API } from '@xbto/api-client';

export const getTradeRecords = (
  data: Enriched.Trade,
  status: string,
  currencies: EnrichedCurrencyInformation[],
  account: Enriched.ListAccountItem | undefined,
  side?: API.Side
) => {
  const isBuy = side === API.Side.Buy;
  const currencyLabel = isBuy ? `Buy` : `Sell`;
  const amountLabel = isBuy ? `Pay with` : `To`;
  return {
    '': <CellActivityStatus {...data} status={status} />,
    Type: '',
    [currencyLabel]: `${isBuy ? '+' : '-'}${
      isBuy
        ? data.formatted.amountToWithCurrencyCode
        : data.formatted.amountFromWithCurrencyCode
    }`,
    [amountLabel]: `${isBuy ? '-' : '+'}${
      isBuy
        ? data.formatted.amountFromWithCurrencyCode
        : data.formatted.amountToWithCurrencyCode
    }`,
    'Exchange rate': <ActivityRate currencies={currencies} trade={data} />,
    Account: (
      <CellTransferAccount
        data={account}
        subTextCls="text-xs font-normal text-grey-darker"
      />
    ),
    'Reference code': <ActivityHash hash={data.humanReadableIdentifier} />,
    Date: <ActivityDate date={data.creationDate} />,
  };
};
