import { API } from '@xbto/api-client';
import {
  Enriched,
  EnrichedCurrencyInformation,
  EnrichedInternalTransfer,
  isInternalTransfer,
  isTransfer,
  Transaction,
} from 'common';
import { getReceiveRecords } from './receive';
import { getTradeRecords } from './trade';
import { getSendRecords } from './send';
import { getAddCashRecords } from './add-cash';
import { getWithdrawCashRecords } from './withdraw-cash';
import { getTransferRecords } from './transfer';
import {
  getEarnedFeeRecords,
  getRewardRecords,
  getYieldPayoutRecords,
} from './legacy';
import { getDefaultRecords } from './default';
import { getChargedFeeRecords } from './charged-fee';
import { getPaymentReceivedRecords } from './payment-received';
import { getFundSubscriptionRecords } from './fund-subscription';
import { getFundRedemptionRecords } from './fund-redemption';

export const getActivityRecords = (
  activity: Enriched.ActivityTransaction,
  transaction: Transaction | null,
  currencies: EnrichedCurrencyInformation[],
  sourceAccount: Enriched.ListAccountItem | undefined,
  destinationAccount: Enriched.ListAccountItem | undefined
): Record<string, JSX.Element | string> => {
  /**
   * Core flows
   */
  // trade
  if (transaction && activity.type === API.ActivityType.Trade) {
    const item = transaction as Enriched.Trade;
    return getTradeRecords(
      item,
      item.status,
      currencies,
      sourceAccount,
      // TODO: BE does not give us this, so passing something to keep TS happy
      API.Side.Buy
    );
  }
  // send -or- stabletag transfer
  if (
    transaction &&
    (activity.type === API.ActivityType.Withdrawal || isTransfer(activity))
  ) {
    const item = transaction as Enriched.Transfer | Enriched.Withdrawal;
    return getSendRecords(
      item,
      item.status,
      currencies,
      sourceAccount,
      isTransfer(activity)
    );
  }
  // receive
  if (transaction && activity.type === API.ActivityType.Deposit) {
    const item = transaction as API.Deposit;
    return getReceiveRecords(item, item.status, currencies, sourceAccount);
  }
  // add cash
  if (activity.type === API.ActivityType.FiatDeposit) {
    return getAddCashRecords(activity);
  }
  // withdraw cash
  if (transaction && activity.type === API.ActivityType.FiatWithdrawal) {
    const item = transaction as Enriched.FiatWithdrawal;
    return getWithdrawCashRecords(item, item.status, sourceAccount);
  }
  // transfer
  if (transaction && isInternalTransfer(activity)) {
    const item = transaction as EnrichedInternalTransfer;
    return getTransferRecords(
      item,
      item.status,
      currencies,
      sourceAccount,
      destinationAccount
    );
  }

  if (activity.type === API.ActivityType.PaymentReceived) {
    return getPaymentReceivedRecords(activity);
  }

  /**
   * Other activity - legacy
   */
  if (activity.type === API.ActivityType.YieldPayout) {
    return getYieldPayoutRecords(activity);
  }
  if (activity.type === API.ActivityType.Reward) {
    return getRewardRecords(activity);
  }
  if (activity.type === API.ActivityType.EarnedFee) {
    return getEarnedFeeRecords(activity);
  }

  if (activity.type === API.ActivityType.ChargedFee) {
    return getChargedFeeRecords(activity, sourceAccount);
  }

  if (activity.type === API.ActivityType.FundSubscription) {
    return getFundSubscriptionRecords(activity, sourceAccount);
  }
  if (activity.type === API.ActivityType.FundRedemption) {
    return getFundRedemptionRecords(activity, sourceAccount);
  }

  /**
   * Unsupported
   */
  return getDefaultRecords(activity, sourceAccount);
};
