import { API } from '@xbto/api-client';
import {
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
} from '../../../types';

export enum WithdrawCashFormikField {
  fromAsset = 'fromAsset',
  bankAccount = 'bankAccount',
  amount = 'amount',
  description = 'description',
}

export interface WithdrawCashFormikProps {
  [WithdrawCashFormikField.fromAsset]:
    | EnrichedAccountDetailAsset
    | EnrichedAssetHolding
    | null;
  [WithdrawCashFormikField.bankAccount]: API.BankAccount | null;
  [WithdrawCashFormikField.amount]: string | null;
  [WithdrawCashFormikField.description]: string | null;
}
