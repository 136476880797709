import { API } from '@xbto/api-client';
import {
  AllowedWorkflowType,
  CurrencyIcon,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  HoldingsTableColumn,
  HoldingsTableColumnId,
  PriceCell,
} from 'common';
import { Props as ColumnHeaderProps, SortProps } from '~/components/app-table';
import { WorkflowRowButtons } from '~/components/workflows/row-buttons';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';

export type HoldingsTableOnAction = (
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding | null,
  actionType: DASHBOARD_SELECTED_DIALOG_TYPE | AllowedWorkflowType | null
) => void;

type Result = ColumnHeaderProps<
  HoldingsTableColumnId,
  EnrichedAccountDetailAsset
>;

const colMap = {
  [HoldingsTableColumnId.Asset]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      cls: 'overflow-hidden',
      cell: (data: EnrichedAccountDetailAsset) => {
        if (data.account?.accountType === API.AccountType.Fund) {
          return (
            <div className="flex flex-col truncate">
              <span className="text-sm font-bold">{data.currency.name}</span>
              <span className="text-xs text-grey-darker">
                {data.formatted.valueAsOfDateTime}
              </span>
            </div>
          );
        }
        return (
          <span className="text-sm truncate">
            <CurrencyIcon
              size={32}
              currencyCode={data.currency?.code}
              currencyName={data.currency?.name}
              apy={data.currency.apy}
            />
          </span>
        );
      },
    };
  },
  [HoldingsTableColumnId.Quantity]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden',
      cell: (data: EnrichedAccountDetailAsset) => {
        if (data.account?.accountType === API.AccountType.Fund) {
          return (
            <div className="flex flex-col truncate text-right">
              <span className="text-sm font-bold">
                {data.formatted.balanceSecondaryAssetWithCurrencyCode}
              </span>
              {data.fundDetails?.secondaryAsset?.code !== 'USD' && (
                <span className="text-xs text-grey-darker">
                  {data.formatted.balanceUsdWithCurrencyCode}
                </span>
              )}
            </div>
          );
        }

        return (
          <div className="flex flex-col truncate text-right">
            <span className="text-sm font-bold">
              {data.formatted.balanceIncludingPending}
            </span>
            <span className="text-xs text-grey-darker">
              {data.formatted.balanceIncludingPendingUsdWithCurrencyCode}
            </span>
          </div>
        );
      },
    };
  },
  [HoldingsTableColumnId.Price]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden text-sm font-bold',
      cell: (data: EnrichedAccountDetailAsset) => {
        return data.currency.fxRate.rateWithCurrencyCodeFormatted;
      },
    };
  },
  [HoldingsTableColumnId.Value]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden text-sm font-bold',
      cell: (data: EnrichedAccountDetailAsset) => {
        return data.formatted.value;
      },
    };
  },
  [HoldingsTableColumnId.APY]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cell: (data: EnrichedAccountDetailAsset) => {
        return <PriceCell value={data.currency.apy} showColor={false} />;
      },
    };
  },
  [HoldingsTableColumnId.Change24h]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cell: (data: EnrichedAccountDetailAsset) => {
        if (!data || !data.currency.fxRate.rate24HoursAgoPercentageChange) {
          return null;
        }
        return (
          <PriceCell
            value={data.currency.fxRate.rate24HoursAgoPercentageChange}
          />
        );
      },
    };
  },
  [HoldingsTableColumnId.TotalInterestEarned]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden text-sm font-bold text-primary truncate',
      cell: (data: EnrichedAccountDetailAsset) => {
        return data.formatted.earnedInterestTotalUsd;
      },
    };
  },
  [HoldingsTableColumnId.AccruedInterestThisWeek]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden text-sm font-bold text-primary truncate',
      cell: (data: EnrichedAccountDetailAsset) => {
        return data.formatted.earnedInterestThisWeekUsd;
      },
    };
  },
  [HoldingsTableColumnId.Action]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn,
    onAction?: HoldingsTableOnAction
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      stopEventPropogation: true,
      cell: (data: EnrichedAccountDetailAsset) => {
        return (
          <WorkflowRowButtons
            asset={data}
            onActionClick={(action, asset) => {
              if (!onAction) {
                return;
              }
              onAction(asset, action);
            }}
          />
        );
      },
    };
  },
  [HoldingsTableColumnId.RateOfReturnMTD]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden',
      cell: (data: EnrichedAccountDetailAsset) => {
        return (
          <div className="flex flex-col text-right items-end">
            <span className="text-sm font-bold">
              <PriceCell value={data.formatted.rateOfReturnPercentMTD} />
            </span>
            <span className="text-xs text-grey-darker">
              {data.formatted.mtdReturn}
            </span>
          </div>
        );
      },
    };
  },
  [HoldingsTableColumnId.RateOfReturnYTD]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden',
      cell: (data: EnrichedAccountDetailAsset) => {
        return (
          <div className="flex flex-col text-right items-end">
            <span className="text-sm font-bold">
              <PriceCell value={data.formatted.rateOfReturnPercentYTD} />
            </span>
            <span className="text-xs text-grey-darker">
              {data.formatted.ytdReturn}
            </span>
          </div>
        );
      },
    };
  },
  [HoldingsTableColumnId.TotalReturn]: (
    sortProps: SortProps<HoldingsTableColumnId>,
    col: HoldingsTableColumn
  ) => {
    return {
      ...sortProps,
      heading: col.title,
      sx: col.sx,
      showSort: col.sortable,
      name: col.id,
      justify: 'justify-end',
      cls: 'overflow-hidden',
      cell: (data: EnrichedAccountDetailAsset) => {
        return (
          <div className="flex flex-col text-right items-end">
            <span className="text-sm font-bold">
              <PriceCell value={data.formatted.totalReturnPercent} />
            </span>
            <span className="text-xs text-grey-darker">
              {data.formatted.totalReturn}
            </span>
          </div>
        );
      },
    };
  },
};

export const getHoldingsColumns = (
  columns: HoldingsTableColumn[] | undefined,
  sortProps: SortProps<HoldingsTableColumnId>,
  onAction?: HoldingsTableOnAction
): Result[] => {
  const result: Result[] = [];

  if (!columns) {
    return result;
  }

  return columns.reduce((out, col) => {
    const columnFn = colMap[col.id];
    if (!columnFn) {
      return out;
    }
    const column = columnFn(sortProps, col, onAction);
    out.push(column);
    return out;
  }, [] as Result[]);
};
