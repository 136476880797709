import { FC } from 'react';
import { API } from '@xbto/api-client';
import { DataStore } from '../../../../store';
import { IconContent } from '../../../icon-content';
import { CopyableText } from '../../../copyable-text';
import {
  RowLabelContent,
  CurrencyIcon,
  CurrencyIconKey,
  Note,
  countryNamesByCode,
  hasSensibleValue,
} from 'common';
import { APP_ROUTES } from '../../../../routes';
import { Error } from '../../../common/error';

interface RowMetaDataProps {
  label: string;
  text: string | undefined | null;
  borderCls?: string;
}

const RowMetaData = ({ label, text, borderCls }: RowMetaDataProps) => {
  if (!text) {
    return null;
  }
  return (
    <RowLabelContent
      cls="py-2"
      borderCls={borderCls}
      showColon={false}
      label={label}
      content={
        <CopyableText
          cls="flex items-center"
          text={text}
          showCopyText={false}
          textCls="text-right font-bold break-normal whitespace-pre"
        />
      }
    />
  );
};

const AccountMetaData = ({
  currency,
  accountNumber,
  localBankNumber,
  internationalBankNumber,
  iban,
}: API.FiatDepositDetails) => {
  /**
   * DOM
   */
  if (currency?.code === CurrencyIconKey.GBP) {
    return (
      <>
        {hasSensibleValue(internationalBankNumber) && (
          <RowMetaData
            label={`BIC / Swift`}
            text={internationalBankNumber}
            borderCls="border-b"
          />
        )}
        <RowMetaData label={`IBAN`} text={iban} borderCls="border-b" />
        <RowMetaData
          label={`Sort code`}
          text={localBankNumber}
          borderCls="border-b"
        />
        <RowMetaData
          label={`Account number`}
          text={accountNumber}
          borderCls="border-b"
        />
      </>
    );
  }
  if (currency?.code === CurrencyIconKey.EUR) {
    return (
      <>
        {hasSensibleValue(internationalBankNumber) && (
          <RowMetaData
            label={`BIC / Swift`}
            text={internationalBankNumber}
            borderCls="border-b"
          />
        )}
        <RowMetaData label={`IBAN`} text={iban} borderCls="border-b" />
      </>
    );
  }
  if (currency?.code === CurrencyIconKey.USD) {
    return (
      <>
        {hasSensibleValue(internationalBankNumber) && (
          <RowMetaData
            label={`BIC / Swift`}
            text={internationalBankNumber}
            borderCls="border-b"
          />
        )}
        {hasSensibleValue(localBankNumber) && (
          <RowMetaData
            label={`Routing number (ABA)`}
            text={localBankNumber}
            borderCls="border-b"
          />
        )}
        {hasSensibleValue(iban) && (
          <RowMetaData label={`IBAN`} text={iban} borderCls="border-b" />
        )}
        {hasSensibleValue(accountNumber) && (
          <RowMetaData
            label={`Account number`}
            text={accountNumber}
            borderCls="border-b"
          />
        )}
      </>
    );
  }
  return (
    <>
      <RowMetaData
        label={`BIC / Swift`}
        text={localBankNumber}
        borderCls="border-b"
      />
      <RowMetaData
        label={`Account number`}
        text={accountNumber}
        borderCls="border-b"
      />
    </>
  );
};

const Disclaimer = ({ currency }: API.FiatDepositDetails) => {
  /**
   * DOM
   */
  return (
    <IconContent
      iconColor="black"
      content={
        <>
          <b className="text-primary">Special instructions</b>
          <br />
          <div className="text-gray-400 text-sm text-left -ml-5 mt-1">
            <ul className="list-disc ml-8">
              <li>
                The name on your originating bank account must match the name on
                your Stablehouse account. If your bank account name doesn&apos;t
                match the one in our records, please
                <a
                  className="text-primary"
                  href={APP_ROUTES.NON_AUTH_CONTACT_US}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="underline cursor-pointer ml-1">
                    contact support.
                  </span>
                </a>
              </li>
              <li>
                Processing times may vary. Please allow:
                <br />
                - 1-2 business days for domestic wires
                <br />- 3-5 business days for international wires.
              </li>
              <li>
                Keep in mind payments received in other currencies that are not{' '}
                {currency?.displayCode} will be automatically returned to the
                source.
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};

const BankInformation = (props: API.FiatDepositDetails) => {
  /**
   * DOM
   */
  if (!props) {
    return null;
  }
  const {
    beneficiaryName,
    beneficiaryAddress,
    beneficiaryCity,
    beneficiaryPostalCode,
    beneficiaryCountry,
    bankName,
    bankAddress,
    bankCity,
    bankPostalCode,
    bankCountry,
    currency,
  } = props;
  const recipientInfo = [
    beneficiaryAddress,
    `${beneficiaryCity}, ${beneficiaryPostalCode}`,
    beneficiaryCountry,
  ]
    .filter(item => !!item)
    .join('\n');
  const bankInfoCountry = countryNamesByCode[bankCountry || ''] ?? bankCountry;
  const bankInfo = [
    bankAddress,
    `${bankCity}, ${bankPostalCode}`,
    bankInfoCountry,
  ]
    .filter(item => !!item)
    .join('\n');
  const isUsd = currency?.code?.toUpperCase() === 'USD';
  return (
    <>
      <Note textSize="sm" cls="rounded-4 my-4">
        <div className="flex flex-row items-center px-2 py-3">
          <p className="font-bold mr-6">
            You must add this reference code in the reference section when
            making the wire transfer
          </p>
          <CopyableText
            showCopyText={false}
            text={props.requiredMemo}
            textCls="font-bold uppercase"
          />
        </div>
      </Note>

      <p className="font-bold mt-4">Bank account details</p>
      <AccountMetaData {...props} />

      <p className="font-bold mt-4">Recipient details</p>
      <RowMetaData
        label={`Recipient name`}
        text={beneficiaryName}
        borderCls=""
      />
      <RowMetaData
        label={`Recipient address`}
        text={recipientInfo}
        borderCls="border-t border-b"
      />

      <p className="font-bold mt-4">Bank details</p>
      <RowMetaData label={`Bank name`} text={bankName} borderCls="" />
      <RowMetaData label={`Bank address`} text={bankInfo} borderCls="" />

      {isUsd && (
        <>
          <RowMetaData
            label={`Intermediary Bank name`}
            text={`Citi Bank NY`}
            borderCls=""
          />
          <RowMetaData
            label={`Intermediary Bank BIC / Swift`}
            text={`CITIUS33`}
            borderCls="border-t border-b"
          />
        </>
      )}

      <br />
      <Disclaimer {...props} />
    </>
  );
};

export const DetailsBank: FC = () => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.addCash.error);
  const formValues = DataStore.useStoreState(s => s.addCash.formValues);
  const details = DataStore.useStoreState(s => s.addCash.details);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  /**
   * DOM
   */
  if (!formValues || !accountDetail) {
    return null;
  }
  const depositDetails = details as API.FiatDepositDetails;
  return (
    <div className="flex flex-col px-10 mb-8" key={JSON.stringify(error)}>
      {/* banner  */}
      <div className="flex flex-col items-center my-4">
        <CurrencyIcon
          currencyCode={formValues.toAsset?.code}
          size={45}
          showLabel={false}
          apy={formValues.toAsset?.apy}
        />
        <h4 className="text-center text-base mt-6 font-bold">
          Below are the details to make sure your transaction is completed
          successfully:
        </h4>
      </div>

      {/* api errors  */}
      {error && !details && <Error message={error} cls="mx-10 my-2" />}

      {/* content  */}
      <BankInformation {...depositDetails} />
    </div>
  );
};
