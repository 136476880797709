import { FC, useEffect } from 'react';
import cx from 'classnames';
import {
  AppIcon,
  validateBankAccountFormik,
  BankAccountFormikProps,
} from 'common';
import { API } from '@xbto/api-client';
import { Formik } from 'formik';
import { AppLoader } from '../app-loader';
import { Error } from '../common/error';
import { DataStore } from '../../store';
import { CountryCcyInfo } from './formik/country-ccy-info';
import { BeneficiaryInfo } from './formik/beneficiary-info';
import { AccountInfo } from './formik/account-info';

interface Props {
  header?: JSX.Element;
  saveButtonCls?: string;
  saveButtonText?: string;
  isClientAdmin?: boolean;
  onClose: (
    bank: (API.BankAccount & API.CreateQuorumOperationApiResponse) | null
  ) => void;
}

export const AddBankAccount: FC<Props> = ({
  header,
  saveButtonCls = `app-button-primary`,
  saveButtonText = `Add account`,
  isClientAdmin = false,
  onClose,
}) => {
  /**
   * Store
   */
  const bankAccountRequirements = DataStore.useStoreState(
    s => s.settings.bankAccountRequirements
  );
  const error = DataStore.useStoreState(s => s.error);

  const getBankAccountRequirements = DataStore.useStoreActions(
    a => a.settings.getBankAccountRequirements
  );
  const createBankAccount = DataStore.useStoreActions(
    a => a.settings.createBankAccount
  );
  const createBankAccountOperation = DataStore.useStoreActions(
    a => a.settings.createBankAccountOperation
  );

  /**
   * Methods
   */
  const onFormValidate = (values: BankAccountFormikProps) => {
    const errors = validateBankAccountFormik(values, bankAccountRequirements);
    return errors;
  };
  const onFormSubmit = async (values: BankAccountFormikProps) => {
    const body: API.CreateBankAccountRequest = {
      currencyCode: values.currencyCode,
      bankAccountDetails: values,
      adhoc: false,
    };
    const bank = isClientAdmin
      ? await createBankAccountOperation({
          request: body,
        })
      : await createBankAccount(body);
    if (!bank) {
      return;
    }
    onClose(bank);
  };

  /**
   * DOM
   */
  const initialValues: BankAccountFormikProps = {
    currencyCode: '',
    accountNumber: '',
    bankIdentifier: '',
    bankCountry: '',
    recipientAddress1: '',
    recipientAddress2: '',
    recipientCity: '',
    recipientState: '',
    recipientPostalCode: '',
    recipientCountry: '',
    addIntermediaryBank: false,
    intermediaryBankIdentifier: '',
    intermediaryAccountNumber: '',
    bankName: '',
    accountHolderName: '',
  };
  return (
    <div className="flex flex-col">
      {/* header  */}
      {header ? (
        <>{header}</>
      ) : (
        <h3
          role="heading"
          className="text-xl px-10 mt-8 mb-2 sm:mb-4 font-bold"
        >
          Add Bank Account
        </h3>
      )}

      {/* api errors  */}
      <Error message={error} cls={`mx-10`} />

      {/* form  */}
      <Formik<BankAccountFormikProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({ errors, isValidating, dirty, submitForm, values }) => {
          /**
           * Form DOM
           */
          const hasErrors = Object.keys(errors).length > 0;
          useEffect(() => {
            if (!values.currencyCode) {
              return;
            }
            getBankAccountRequirements({
              bankCountry: values.bankCountry,
              bankCurrency: values.currencyCode,
            });
          }, [
            values.bankCountry,
            values.currencyCode,
            getBankAccountRequirements,
          ]);
          return (
            <>
              {/* busy indicator when validation is happening  */}
              {isValidating && (
                <AppLoader bgColor={'bg-transparent'} spinnerTop="0%" />
              )}

              {/* fields  */}
              <div className="py-4 px-10 max-h-80 sm:max-h-96 md:max-h-576 overflow-y-scroll">
                <CountryCcyInfo />
                <AccountInfo />
                <BeneficiaryInfo />
              </div>

              {/* actions  */}
              <div className="flex flex-col sm:flex-row gap-5 border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
                {/* cancel button  */}
                <button
                  className="app-button-outline w-full sm:mr-3 flex-1"
                  onClick={e => {
                    e.preventDefault();
                    onClose(null);
                  }}
                >
                  Cancel
                </button>
                {/* withdraw button  */}
                <button
                  disabled={hasErrors || !dirty}
                  type="button"
                  className={cx(
                    'flex-1 flex items-center justify-center',
                    saveButtonCls
                  )}
                  onClick={submitForm}
                >
                  {saveButtonText}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
