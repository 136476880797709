import { PixelRatio } from 'react-native';
const pixelDensity = PixelRatio.get();
const densitySuffix = pixelDensity >= 3 ? '@3x' : pixelDensity >= 2 ? '@2x' : '';
// (\.(png|jpe?g))?$  - Optionally matches a dot followed by 'png', 'jpg', or 'jpeg' at the end of the string.
const reImageExtension = /(\.(png|jpe?g))?$/i;
export function addDensityToSrc(src) {
    return src.replace(reImageExtension, (_match, extension) => {
        if (extension) {
            return `${densitySuffix}${extension}`;
        }
        // If there's no extension, append '@2x' at the end.
        return densitySuffix;
    });
}
