import { Props as ColumnHeaderProps, SortProps } from '../app-table/cell';
import { API } from '@xbto/api-client';

import {
  CellActivityType,
  CellAmountCurrency,
  CellActivityDate,
  CellActivityStatus,
} from './cells';
import { convertColumnToSortColumn } from './utils';
import { TransactionColumn } from '~/config';
import { Enriched } from 'common';
import { CellFillPrice } from './cells/cell-fill-price';

const headings = {
  [TransactionColumn.TransactionType]: 'Transaction type',
  [TransactionColumn.AmountCurrency]: 'Amount',
  [TransactionColumn.Date]: 'Date',
  [TransactionColumn.Status]: 'Status',
  [TransactionColumn.FillPrice]: 'Fill price',
};

const getTransactionTypeColumn = (
  props: SortProps<TransactionColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    sx: {
      width: '50%',
      flexShrink: 0,
      flexGrow: 0
    },
    justify: 'justify-start',
    showSort: !!convertColumnToSortColumn(TransactionColumn.TransactionType),
    name: TransactionColumn.TransactionType,
    cell: (data: Enriched.ActivityTransaction | null) => {
      if (!data) {
        return null;
      }
      return <CellActivityType data={data} />;
    },
  };
};

const getAmountCurrencyColumn = (
  props: SortProps<TransactionColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    sx: {
      width: '50%',
    },
    justify: 'justify-start',
    showSort: !!convertColumnToSortColumn(TransactionColumn.AmountCurrency),
    name: TransactionColumn.AmountCurrency,
    cell: (data: Enriched.ActivityTransaction | null) => {
      if (!data) {
        return null;
      }
      return <CellAmountCurrency data={data} />;
    },
  };
};

const getFillPriceColumn = (
  props: SortProps<TransactionColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    sx: {
      width: '50%',
    },
    showSort: !!convertColumnToSortColumn(TransactionColumn.AmountCurrency),
    name: TransactionColumn.AmountCurrency,
    justify: 'justify-end',
    cell: (data: Enriched.ActivityTransaction | null) => {
      if (!data) {
        return null;
      }
      return <CellFillPrice data={data} />;
    },
  };
};

const getDateColumn = (
  props: SortProps<TransactionColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    sx: {
      width: '25%',
    },
    showSort: !!convertColumnToSortColumn(TransactionColumn.Date),
    name: TransactionColumn.Date,
    cell: (data: Enriched.ActivityTransaction | null) => {
      if (!data) {
        return null;
      }
      return <CellActivityDate date={data.creationDate} />;
    },
  };
};

const getActivityStatusColumn = (
  props: SortProps<TransactionColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    sx: {
      width: '25%',
    },
    showSort: !!convertColumnToSortColumn(TransactionColumn.Status),
    justify: 'justify-end',
    name: TransactionColumn.Status,
    cell: (data: Enriched.ActivityTransaction | null) => {
      if (!data) {
        return null;
      }
      return <CellActivityStatus status={data.statusFilter || ''} cls="" />;
    },
  };
};

export const getTransactionsColumns = (
  columnProps: SortProps<TransactionColumn>,
  isFund?: boolean
): ColumnHeaderProps<
  TransactionColumn,
  Enriched.ActivityTransaction | null
>[] => {
  const amountColumn = getAmountCurrencyColumn(
    columnProps,
    headings[TransactionColumn.AmountCurrency]
  );

  const columns = [
    getDateColumn(columnProps, headings[TransactionColumn.Date]),
    getTransactionTypeColumn(
      columnProps,
      headings[TransactionColumn.TransactionType]
    ),
    // getFillPriceColumn(columnProps, headings[TransactionColumn.FillPrice]),
    getActivityStatusColumn(columnProps, headings[TransactionColumn.Status]),
  ];
  if (!isFund) {
    columns.splice(1, 0, amountColumn);
  }
  return columns;
};
