import { useEffect, useState } from 'react';
import { DataStoreType } from '../../../store';
import { EnrichedAccountDetailAsset } from '../../../types';

interface Options {
  DataStore: DataStoreType;
  ccyCode: string | undefined;
  onSelected: (asset: EnrichedAccountDetailAsset) => void;
}

export const useWorkflowBuySellList = ({
  DataStore,
  ccyCode,
  onSelected,
}: Options) => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.buySell.busy);
  const error = DataStore.useStoreState(s => s.buySell.error);
  const hasNoAssets = DataStore.useStoreState(s => s.buySell.hasNoAssets);
  const groupedAssets = DataStore.useStoreState(s => s.buySell.groupedAssets);
  const isTradableAsset = DataStore.useStoreState(
    a => a.buySell.isTradableAsset
  );
  const formValues = DataStore.useStoreState(s => s.buySell.formValues);
  const assetSearch = DataStore.useStoreState(s => s.buySell.assetSearch);
  const assetFilter = DataStore.useStoreState(s => s.buySell.assetFilter);
  const assetListTitle = DataStore.useStoreState(
    s => s.buySell.labels.assetListTitle
  );
  const assetListHeader = DataStore.useStoreState(
    s => s.buySell.labels.assetListHeader
  );
  const setAssetSearch = DataStore.useStoreActions(
    s => s.buySell.setAssetSearch
  );
  const setAssetFilter = DataStore.useStoreActions(
    s => s.buySell.setAssetFilter
  );
  const setFormValues = DataStore.useStoreActions(s => s.buySell.setFormValues);

  /**
   * State
   */
  const [allowed, setAllowed] = useState(false);

  /**
   * Hooks
   */
  useEffect(() => {
    if (busy) {
      return;
    }
    if (!formValues.fromAsset && !formValues.toAsset && ccyCode) {
      const [isTradable, tradableAsset] = isTradableAsset(ccyCode);
      setAllowed(isTradable);

      if (isTradable && tradableAsset) {
        onSelected(tradableAsset);
      }
      return;
    }

    setAllowed(true);
  }, [
    busy,
    onSelected,
    ccyCode,
    formValues.fromAsset,
    formValues.toAsset,
    isTradableAsset,
  ]);

  return {
    error,
    formValues,
    assetFilter,
    assetSearch,
    allowed,
    assetListTitle,
    hasNoAssets,
    groupedAssets,
    busy,
    assetListHeader,
    setAssetSearch,
    setAssetFilter,
    setFormValues,
  };
};
