import { BankAccountFormikProps, BankAccountFormikField } from './types';
import iban, { isValid, isValidBBAN, toBBAN } from 'iban';
import { ERROR_MESSAGES, REGEX } from '../../../constants';
import { API } from '@xbto/api-client';
import { isBankAccountRequirementRequired } from '../../bank-requirements';
import bic from 'bic';
import { ABARoutingNumberIsValid } from 'bank-routing-number-validator';
import { countries, isCountryAuthorized } from '../../lists/countries';

const isAlphanum = (
  prop: BankAccountFormikField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  const isValid = Boolean(value && REGEX.ALPHANUMERIC.test(value));

  if (!isValid) {
    errors[prop] = ERROR_MESSAGES.INVALID_VALUE;
  }

  return isValid;
};

const isRequired = (
  prop: BankAccountFormikField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  const isValid = Boolean(value);

  if (!isValid) {
    errors[prop] = ERROR_MESSAGES.REQUIRED_VALUE;
  }

  return isValid;
};

const isValidState = (
  prop: BankAccountFormikField,
  value: string | null,
  errors: Record<string, unknown>
) => (value ? isAlphanum(prop, value, errors) : true);

const isValidIBAN = (
  prop: BankAccountFormikField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  const ok = isAlphanum(prop, value, errors);

  if (ok) {
    // IBAN
    const isValid = !!value && iban.isValid(value);

    if (!isValid) {
      errors[prop] = ERROR_MESSAGES.INVALID_IBAN;
    }

    return isValid;
  }

  return false;
};

function isValidABARoutingNumber(value: string | null): boolean {
  if (!value) {
    return false;
  }

  return ABARoutingNumberIsValid(value);
}

function isValidABA(
  prop: BankAccountFormikField,
  value: string | null,
  errors: Record<string, unknown>
) {
  const isValid = isValidABARoutingNumber(value);

  if (!isValid) {
    if (prop === BankAccountFormikField.bankCountry) {
      errors[prop] = ERROR_MESSAGES.INVALID_COUNTRY_ABA;
    }
    if (prop === BankAccountFormikField.bankIdentifier) {
      errors[prop] = ERROR_MESSAGES.INVALID_ABA;
    }
  }

  return isValid;
}

function isValidBIC(
  prop: BankAccountFormikField,
  value: string,
  errors: Record<string, unknown>
) {
  const cleanedValue = value.replace(/[-\s]/g, '');

  const isValid = bic.isValid(cleanedValue);

  if (!isValid) {
    errors[prop] = ERROR_MESSAGES.INVALID_BIC;
  }

  return isValid;
}

const isValidSortCode = (
  prop: BankAccountFormikField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  const isValid = !!value && REGEX.SORT_CODE.test(value);

  if (!isValid) {
    errors[prop] = ERROR_MESSAGES.INVALID_SORT_CODE;
  }

  return isValid;
};

const isValidIbanCountryPair = (
  prop: BankAccountFormikField,
  value: { iban: string; countryCode: string },
  errors: Record<string, unknown>
) => {
  const code = countries.find(
    ({ code }) => code === value.countryCode
  )?.codeAlt;
  const isValidPair = isValid(value.iban)
    ? isValidBBAN(code || '', toBBAN(value.iban))
    : false;

  if (!isValidPair) {
    errors[prop] = ERROR_MESSAGES.INVALID_IBAN;
  }

  return isValidPair;
};

const isValidBicCountryPair = (
  prop: BankAccountFormikField,
  value: { bic: string; countryCode: string },
  errors: Record<string, unknown>
) => {
  if (!value.countryCode?.length) {
    return;
  }
  const countryCode = countries.find(
    ({ code }) => code === value.countryCode
  )?.codeAlt;

  if (value?.bic?.length < 6 || !countryCode) {
    return;
  }

  const countryCodeFromBic = value.bic.substring(4, 6);
  if (countryCodeFromBic.length !== 2) {
    return;
  }
  const isValidPair =
    countryCode.toUpperCase() === countryCodeFromBic.toUpperCase();

  if (!isValidPair) {
    if (prop === BankAccountFormikField.bankCountry) {
      errors[prop] = ERROR_MESSAGES.INVALID_COUNTRY_BIC;
    }
    if (prop === BankAccountFormikField.bankIdentifier) {
      errors[prop] = ERROR_MESSAGES.INVALID_BIC;
    }
  }

  return isValidPair;
};

export const validateBankAccountFormik = (
  {
    accountNumber,
    bankIdentifier,
    bankCountry,
    intermediaryBankIdentifier,
    intermediaryAccountNumber,
    recipientAddress1,
    currencyCode,
    recipientCity,
    recipientState,
    recipientPostalCode,
    recipientCountry,
    bankName,
    accountHolderName,
  }: BankAccountFormikProps,
  bankAccountRequirements: API.BankAccountRequirementsOutput | null
) => {
  const errors: Record<string, unknown> = {};

  // Bank country
  isRequired(BankAccountFormikField.bankCountry, bankCountry, errors);

  if (!isCountryAuthorized(bankCountry)) {
    errors[BankAccountFormikField.bankCountry] =
      ERROR_MESSAGES.UNAUTHORIZED_COUNTRY;
  }

  isRequired(BankAccountFormikField.currencyCode, currencyCode, errors);

  // Account number
  const isIBANRequired = isBankAccountRequirementRequired(
    bankAccountRequirements?.iban
  );

  if (accountNumber && bankCountry) {
    isRequired(BankAccountFormikField.accountNumber, accountNumber, errors);

    if (isIBANRequired) {
      isValidIbanCountryPair(
        BankAccountFormikField.accountNumber,
        {
          iban: accountNumber,
          countryCode: bankCountry,
        },
        errors
      );
    }
  }

  if (
    isBankAccountRequirementRequired(bankAccountRequirements?.accountNumber) ||
    isIBANRequired
  ) {
    isRequired(BankAccountFormikField.accountNumber, accountNumber, errors);

    if (isIBANRequired) {
      isValidIBAN(BankAccountFormikField.accountNumber, accountNumber, errors);
    }
  }

  // Bank ID
  const isSortCodeRequired = isBankAccountRequirementRequired(
    bankAccountRequirements?.sortCode
  );
  const isABARequired = isBankAccountRequirementRequired(
    bankAccountRequirements?.aba
  );
  const isBICRequired = isBankAccountRequirementRequired(
    bankAccountRequirements?.bicSwift
  );

  if (isABARequired || isBICRequired || isSortCodeRequired) {
    const ok = isRequired(
      BankAccountFormikField.bankIdentifier,
      bankIdentifier,
      errors
    );

    if (ok) {
      if (isABARequired && bankCountry && bankIdentifier) {
        isValidABA(
          bankCountry
            ? BankAccountFormikField.bankCountry
            : BankAccountFormikField.bankIdentifier,
          bankIdentifier,
          errors
        );
      }

      if (isBICRequired) {
        isValidBIC(
          BankAccountFormikField.bankIdentifier,
          bankIdentifier,
          errors
        );

        if (!!bankCountry && !!bankIdentifier) {
          isValidBicCountryPair(
            bankCountry
              ? BankAccountFormikField.bankCountry
              : BankAccountFormikField.bankIdentifier,
            {
              bic: bankIdentifier,
              countryCode: bankCountry,
            },
            errors
          );
        }
      }

      if (isSortCodeRequired) {
        isValidSortCode(
          BankAccountFormikField.bankIdentifier,
          bankIdentifier,
          errors
        );
      }
    }
  }

  isRequired(
    BankAccountFormikField.accountHolderName,
    accountHolderName,
    errors
  );
  isRequired(
    BankAccountFormikField.recipientAddress1,
    recipientAddress1,
    errors
  );
  isRequired(BankAccountFormikField.recipientCity, recipientCity, errors);
  isRequired(BankAccountFormikField.recipientCountry, recipientCountry, errors);
  isRequired(
    BankAccountFormikField.recipientPostalCode,
    recipientPostalCode,
    errors
  );
  isRequired(BankAccountFormikField.bankName, bankName, errors);
  isValidState(BankAccountFormikField.recipientState, recipientState, errors);

  if (
    isBankAccountRequirementRequired(
      bankAccountRequirements?.intermediaryBicSwift
    )
  ) {
    isRequired(
      BankAccountFormikField.intermediaryBankIdentifier,
      intermediaryBankIdentifier,
      errors
    );
  }

  if (
    isBankAccountRequirementRequired(bankAccountRequirements?.intermediaryAba)
  ) {
    isRequired(
      BankAccountFormikField.intermediaryAccountNumber,
      intermediaryAccountNumber,
      errors
    );
  }

  return errors;
};
