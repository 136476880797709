import { FC, useState, useCallback, memo } from 'react';
import cx from 'classnames';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import { API } from '@xbto/api-client';
import { Menu } from '@headlessui/react';
interface Props {
  paginatedActivities:
    | API.ActivityTransactionArrayPaginatedApiResponse
    | API.QuorumOperationArrayPaginatedApiResponse
    | null;
  onChanged?: (
    options: Partial<
      API.GetActivityTransactionsRequest | API.PendingOperationsRequest
    >
  ) => void;
  sortMenu?: Record<string, string>;
  onSortByChange?: (
    sortItem: Record<'sortById' | 'sortByName', string>
  ) => void;
}

export const AppTablePaginator: FC<Props> = memo(
  ({ paginatedActivities, onChanged, sortMenu = null, onSortByChange }) => {
    /**
     * State
     */
    const [selectedFilter, setSelectedFilter] = useState<string>('Select');

    const handlePageOnclick = useCallback(
      pageData => {
        if (!onChanged) {
          return null;
        }
        onChanged({ page: pageData });
      },
      [onChanged]
    );
    const handleSortByOnclick = useCallback(
      ({ sortById, sortByName }) => {
        setSelectedFilter(sortByName);
        if (!onSortByChange) return;
        onSortByChange({
          sortById,
          sortByName,
        });
      },
      [onSortByChange, setSelectedFilter]
    );

    /**
     * DOM
     */
    if (!paginatedActivities?.totalPages) {
      return null;
    }
    const { page, totalCount, totalPages, result } = paginatedActivities;
    const disabled = totalCount === 0;
    const cannotFirstPage = totalPages === 1 || page === 1;
    const cannotPreviousPage = page === 1;
    const cannotNextPage = page === totalPages || page > totalPages;
    const cannotLastPage =
      page === totalPages || totalPages === 1 || page > totalPages;

    return (
      <div className="relative flex flex-col lg:flex-row items-center justify-between my-6">
        {/* showing count  */}
        <div>
          <span className="text-primary">Showing&nbsp;</span>
          <span className="font-bold text-primary" data-testid="page-index">
            {result?.length}
          </span>
          <span className="text-primary">&nbsp;of&nbsp;</span>
          <span className="font-bold text-primary">{totalCount}</span>
          <span className="text-primary">&nbsp;results</span>
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          {/*  page index & buttons  */}
          <div className="flex mt-8 lg:mt-0">
            <button
              className={cx('app-button-default button-sm', {
                'hover-bg-accent focus:ring-0': !cannotFirstPage,
              })}
              disabled={disabled || cannotFirstPage}
              onClick={() => handlePageOnclick(1)}
            >
              <ChevronDoubleLeftIcon className="h-4 w-4" />
            </button>
            <button
              className={cx('app-button-default button-sm', {
                'hover-bg-accent focus:ring-0': !cannotPreviousPage,
              })}
              disabled={disabled || cannotPreviousPage}
              onClick={() => {
                const prevPage =
                  paginatedActivities.page > paginatedActivities.totalPages
                    ? paginatedActivities.totalPages
                    : page - 1;
                handlePageOnclick(prevPage);
              }}
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </button>
            <div className="mx-1 mt-1">
              <span className="text-primary">Page&nbsp;</span>
              <span className="font-bold text-primary">{page}</span>
              <span className="text-primary">&nbsp;of&nbsp;</span>
              <span className="font-bold text-primary">{totalPages}</span>
            </div>
            <button
              className={cx('app-button-default button-sm', {
                'hover-bg-accent focus:ring-0': !cannotNextPage,
              })}
              disabled={disabled || cannotNextPage}
              onClick={() => handlePageOnclick(page + 1)}
            >
              <ChevronRightIcon className="h-4 w-4" />
            </button>
            <button
              className={cx('app-button-default button-sm', {
                'hover-bg-accent focus:ring-0': !cannotLastPage,
              })}
              disabled={disabled || cannotLastPage}
              onClick={() => handlePageOnclick(totalPages)}
            >
              <ChevronDoubleRightIcon className="h-4 w-4" />
            </button>
          </div>
          {sortMenu && (
            <div className="flex mt-6 lg:ml-6 lg:mt-0">
              {/* @ts-ignore */}
              <Menu className="px-40">
                {({ open }: { open: boolean }) => {
                  const menuCls =
                    'text-primary bg-white w-4 h-4 mt-1 font-semibold';
                  return (
                    <>
                      <Menu.Button
                        title="Sort by"
                        className={'p-1 flex flex-row justify-between'}
                      >
                        <span className="text-gray-400 mr-3">Sort by:</span>{' '}
                        <span className="text-primary mr-2 font-semibold">
                          {selectedFilter}
                        </span>
                        {open ? (
                          <ChevronUpIcon className={menuCls} />
                        ) : (
                          <ChevronDownIcon className={menuCls} />
                        )}
                      </Menu.Button>
                      <Menu.Items className="absolute p-5 top-40 lg:top-8 lg:right-0 w-56 bg-white rounded-md shadow-2xl">
                        {Object.entries(sortMenu).map(
                          (item: [string, string]) => {
                            return (
                              <p
                                className="font-semibold leading-7 text-gray-400 hover:underline hover:text-primary"
                                key={item[0]}
                                onClick={() =>
                                  handleSortByOnclick({
                                    sortById: item[0],
                                    sortByName: item[1],
                                  })
                                }
                              >
                                {item[1]}
                              </p>
                            );
                          }
                        )}
                      </Menu.Items>
                    </>
                  );
                }}
              </Menu>
            </div>
          )}
        </div>
      </div>
    );
  }
);
