import { API } from '@xbto/api-client';
import cx from 'classnames';
import {
  DATE_FORMATS,
  Note,
  cammelCaseToWords,
  QuorumOperationModalType,
  AppDialog,
} from 'common';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { DataStore } from '../../store';
import { getAccountAndUserToggleStatus } from '../../utils/get-account-and-user-toggle-status';
import { AppContainer } from '../app-container';
import { AppTableRows } from '../app-table/rows';
import { ApprovalConfirmationModal } from '../approval-confirmation-modal';
import { ShowBankAccountDetails } from '../bank-accounts/show-details';
import { ShowWithdrawalAddressDetails } from '../crypto-addresses/show-details';
import {
  getAprovalsDescriptionColumns,
  ApprovalsDescirptionColumn,
} from '../dashboard/apex-quorum-tables/columns';
import { QuorumStatus } from '../quorum-status';

const getLine = (
  label: string,
  value?: string | JSX.Element | number | null
) => (
  <div
    className={cx('sm:flex sm:justify-between text-lg py-4', {
      'border-b': label !== 'Approvers required',
      'sm:flex-col': label === 'Description',
      'sm:items-center': label !== 'Description',
    })}
  >
    <p className="flex-1 whitespace-nowrap mr-2 text-gray-400">{label}</p>
    <span className="text-primary font-bold break-all">{value}</span>
  </div>
);

interface DataPointProps {
  title: string;
  value: string | JSX.Element;
  w?: number;
}

const DataPoint: FC<DataPointProps> = ({ title, value, w = 1 }) => {
  if (!value) return null;
  return (
    <div
      className={cx('mb-6 pr-6', {
        'w-full': w === 2,
        'w-1/2': w === 1,
      })}
    >
      <p className="text-gray-400">{title}</p>
      {typeof value === 'string' ? <p className="font-bold">{value}</p> : value}
    </div>
  );
};

type Props = {
  onClose?: () => void;
  operationDetails?: API.QuorumOperation | null;
};

export const QuorumApprovalComponent: FC<Props> = ({
  operationDetails,
  onClose,
}) => {
  if (!operationDetails) return null;
  /**
   * Store
   */
  const approveOperation = DataStore.useStoreActions(
    s => s.quorum.approveOperation
  );
  const cancelOperation = DataStore.useStoreActions(
    s => s.quorum.cancelOperation
  );
  const setBankAccount = DataStore.useStoreActions(
    s => s.settings.setBankAccount
  );
  const _setWithdrawalAddress = DataStore.useStoreActions(
    s => s.settings.cryptoAddresses._setWithdrawalAddress
  );
  const getOperationDetails = DataStore.useStoreActions(
    s => s.quorum.getOperationDetails
  );
  const user = DataStore.useStoreState(s => s.user);

  /**
   *  State
   */
  const [modalType, setModalType] = useState<QuorumOperationModalType | null>(
    null
  );

  /**
   * Hooks
   */
  useEffect(
    () => () => {
      setBankAccount(null);
      _setWithdrawalAddress(null);
    },
    []
  );

  /**
   * DOM
   */
  if (!operationDetails) {
    return null;
  }

  const showDefaultButton =
    operationDetails.status !== API.QuorumOperationStatus.WaitingForApproval ||
    operationDetails?.submittedByUserId === user?.identity?.id;
  const getUpdatedApproval = () =>
    getOperationDetails({
      request: {
        id: operationDetails.id,
      },
    });
  const columnProps = {
    sort: null,
    status: operationDetails.status,
    onSort: () => null,
  };

  const columns = getAprovalsDescriptionColumns(columnProps);
  return (
    <div className="">
      <AppDialog
        isOpen={!!modalType}
        onClose={() => {
          setModalType(null);
        }}
      >
        <ApprovalConfirmationModal
          kind={operationDetails.kind}
          approvalData={operationDetails}
          modalType={modalType}
          showGenericButton={showDefaultButton}
          onClose={() => setModalType(null)}
        />
      </AppDialog>
      <AppContainer cls="mb-20">
        <header className="text-primary pt-14 pb-6">
          <p className="typo-h3 font-semibold text-center">
            Approval request details
          </p>
        </header>
        <div className="flex gap-x-4 items-start justify-center">
          <div>
            {getLine(
              'Type',
              getAccountAndUserToggleStatus(
                operationDetails.kind,
                operationDetails?.parameters?.state
              )
            )}
            {getLine(
              'Request date',
              format(
                new Date(operationDetails.creationDate),
                DATE_FORMATS.DD_MMM_YYYY
              )
            )}
            {getLine('Requester', operationDetails?.submittedByUserName)}
            {getLine('Fund', operationDetails.parameters.accountName)}
            {getLine(
              'Status',
              <QuorumStatus status={operationDetails.status} />
            )}
            {operationDetails.parameters &&
              getLine(
                'Description',
                <Note
                  bgColor="bg-gray-100"
                  cls="rounded-4 px-6 py-6 border-none w-full mt-4"
                  textSize="base"
                >
                  <div className="flex text-sm font-semibold flex-row flex-wrap justify-start text-gray-400">
                    {Object.entries(operationDetails?.parameters).map(
                      (item: [string, any]) => (
                        <DataPoint
                          key={item[0]}
                          title={cammelCaseToWords(item[0])}
                          value={item[1]}
                        />
                      )
                    )}
                  </div>
                </Note>
              )}
            {getLine('Approvers required', operationDetails.requiredApprovals)}
            {!!operationDetails.approvals?.length && (
              <>
                <Note
                  bgColor="bg-gray-100"
                  cls="rounded-4 px-3 py-3 border-none"
                  textSize="base"
                >
                  <div className="flex justify-between font-semibold flex-row text-gray-400">
                    {/* id number column */}
                    <div className="text-sm">Name</div>
                    {/*action type column */}
                    <div className="text-sm">Date actioned</div>
                    {/* fund column */}
                    <div className="text-sm">Status</div>
                  </div>
                </Note>
                <AppTableRows<
                  ApprovalsDescirptionColumn,
                  | (API.QuorumOperationApproval & Partial<API.QuorumOperation>)
                  | null
                >
                  columns={columns}
                  rowPadding="px-4 md:px-8 py-3"
                  rows={operationDetails.approvals || []}
                  containerCls="border-none"
                />
              </>
            )}
            <div className="flex flex-col pt-20 gap-y-4 gap-x-2 sm:flex-row">
              {showDefaultButton ? (
                <button
                  role="button"
                  type="button"
                  className="w-full flex-1 app-button-outline"
                  onClick={() => {
                    if (!onClose) return;
                    onClose();
                  }}
                >
                  Back to approvals
                </button>
              ) : (
                <>
                  <button
                    className="app-button-failure w-full flex-1"
                    onClick={async () => {
                      const result = await cancelOperation({
                        id: operationDetails.id,
                        message: operationDetails.kind,
                      });
                      if (!result) return;
                      await getUpdatedApproval();
                      setModalType(QuorumOperationModalType.rejected);
                    }}
                  >
                    Reject
                  </button>
                  <button
                    className="app-button-success w-full flex-1"
                    onClick={async () => {
                      const result = await approveOperation({
                        id: operationDetails.id,
                      });
                      if (!result) return;
                      await getUpdatedApproval();
                      setModalType(QuorumOperationModalType.approved);
                    }}
                  >
                    Approve
                  </button>
                </>
              )}
            </div>
          </div>

          {operationDetails.kind ===
            API.QuorumOperationKind.DeleteBankAccount && (
            <ShowBankAccountDetails
              id={operationDetails.elementId}
              accountId={operationDetails.parameters.accountId}
            />
          )}
          {operationDetails.kind ===
            API.QuorumOperationKind.DeleteWithdrawalAddress && (
            <ShowWithdrawalAddressDetails
              id={operationDetails.elementId}
              accountId={operationDetails.parameters.accountId}
            />
          )}
        </div>
      </AppContainer>
    </div>
  );
};
