import { API } from '@xbto/api-client';
import { useCopy } from './use-copy';
import { getWorkflowName } from '../utils';
import { AllowedWorkflowType } from '../types';

type NoticeCopy = {
  icon: string;
  title: string;
  content?: string;
};

export function useNoticeCopy(
  workflow: AllowedWorkflowType,
  accountType: API.AccountType | undefined
) {
  const {
    portfolio: { workflows },
  } = useCopy();

  const workflowName = getWorkflowName(workflow).replace(' ', '');

  if (
    !workflowName ||
    !workflows[workflowName] ||
    !workflows[workflowName][accountType]
  ) {
    return null;
  }

  // TODO: tech-debt this type casting does not help eg: `Trade` is now `Buy | Sell` this does not break and hence returning null
  return workflows[workflowName][accountType] as NoticeCopy;
}
