import { API } from '@xbto/api-client';
import cx from 'classnames';
import { Note } from 'common';
import { AppIcon } from 'common';

import { FC } from 'react';
import { CopyableText } from '../copyable-text';

interface DataPointProps {
  title: string;
  value: string | JSX.Element;
  w?: number;
  append?: JSX.Element;
}

const DataPoint: FC<DataPointProps> = ({ title, value, append, w = 3 }) => {
  return (
    <div
      className={cx('mb-6', {
        'w-full': w === 3,
        'w-1/3': w === 1,
      })}
    >
      <p className="text-gray-400">{title}</p>
      {typeof value === 'string' ? <p className="font-bold">{value}</p> : value}
      {append}
    </div>
  );
};

interface Props {
  apiKey: API.ApiKey | API.CreateApiKeyResponse | undefined;
  onClose: () => void;
  onDelete: (apiKeyId: string) => void;
}

export const ShowApiKey: FC<Props> = ({ apiKey, onClose, onDelete }) => {
  /**
   * Store
   */

  /**
   * Methods
   */
  const handleDelete = async () => {
    if (apiKey) {
      onDelete(apiKey.key!);
    }
    onClose();
  };

  /**
   * DOM
   */

  if (!apiKey) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col items-center px-10">
        <AppIcon icon="lock-2fa" cls="mt-10 mb-2" size="lg" />
        <h4 className="text-3xl font-bold text-center mb-6">API Key</h4>
        <div className="flex flex-row flex-wrap w-full">
          <DataPoint title="Name" value={apiKey.name || ''} />
          <DataPoint title="Key" value={<CopyableText text={apiKey.key} />} />

          {(apiKey as API.CreateApiKeyResponse).secret && (
            <>
              <DataPoint
                title="Secret"
                value={
                  <CopyableText
                    text={(apiKey as API.CreateApiKeyResponse).secret}
                  />
                }
                append={
                  <Note bgColor={'bg-warning-70'} cls="w-full mb-2">
                    <div className="text-primary font-bold text-base">
                      This is the last time this secret is visible.
                    </div>
                  </Note>
                }
              />
            </>
          )}
        </div>
        <div className="w-full border-b-1 mb-2">Permissions</div>
        <div className="flex flex-row flex-wrap w-full">
          <DataPoint
            title="Trading"
            w={1}
            value={apiKey.canTrade ? 'Allowed' : 'Blocked'}
          />
          <DataPoint
            title="Withdrawals"
            w={1}
            value={apiKey.canWithdraw ? 'Allowed' : 'Blocked'}
          />
          <DataPoint
            w={1}
            title="API Key Creation"
            value={apiKey.canCreateApiKeys ? 'Allowed' : 'Blocked'}
          />
        </div>
        <div className="w-full border-b-1 mb-2">Security</div>
        <div className="flex flex-row flex-wrap  w-full">
          <DataPoint
            title="Whitelisted IP Addresses"
            value={apiKey.ipAddresses || 'None'}
          />
        </div>

        {/* delete button  */}
        <button
          type="button"
          className="app-button-outline failure hover-bg-grey-brighter mb-6"
          onClick={handleDelete}
          style={{ width: '100%' }}
        >
          Delete this API Key
        </button>
      </div>

      {/* actions   */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
        {/* cancel button  */}
        <button className="app-button-outline w-full flex-1" onClick={onClose}>
          Close window
        </button>
      </div>
    </div>
  );
};
