import { API } from '@xbto/api-client';
import { Ensure } from '../utils';

export const travelRuleWalletOwnerTypes = Object.entries(
  API.TravelRuleWalletOwnerType
);

export type KytValues = {
  walletOwnerCategory: API.TravelRuleWalletOwnerType;
  walletOwnerIndividualInfo?: {
    firstName: string;
    lastName: string;
  };
  walletOwnerCompanyName?: string;
  walletPlatformCategory?: API.TravelRuleWalletType;
  walletPlatformInfo?: {
    name: string;
    did: string;
  };
} | null;

export type EnsuredTravelRuleExchangeSearchResponse = Ensure<
  API.TravelRuleExchangeSearchResponse,
  'id' | 'label'
>;
