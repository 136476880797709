import { API } from '@xbto/api-client';
import parseNumber from 'multi-number-parse';
import { SendDestinationType, SendForm } from './types';
import { formatAmount } from '../../format-amount';
import { Enriched, EnrichedCurrencyInformation } from '../../../types';

export const getSendRemainingBalance = (
  ccyInfo: EnrichedCurrencyInformation | null,
  fundBalance: number | 0,
  formValues: SendForm,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (
    simulation?.isExternal &&
    (formValues?.destinationType === SendDestinationType.crypto ||
      formValues?.destinationType === SendDestinationType.book)
  ) {
    return formatAmount(
      Number(fundBalance) -
        Number((simulation as Enriched.WithdrawalSimulation)?.sendingAmount),
      ccyInfo?.decimals
    );
  }
  return formatAmount(
    parseNumber(fundBalance || 0) - parseNumber(formValues?.amount || 0),
    ccyInfo?.decimals
  );
};

export const getSendAmountSent = (
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (!simulation) return '';

  return simulation.formatted.sendingAmountWithCurrencyCode;
};

export const getSendAmountWithdrawn = (
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  return simulation?.formatted.amountWithCurrencyCodeAndSign ?? '';
};

export const getSendFees = (
  ccyCode: string | null | undefined,
  formValues: SendForm,
  simulation: Enriched.TransferSimulation | Enriched.WithdrawalSimulation | null
) => {
  if (formValues?.destinationType === SendDestinationType.transfer) {
    return `FREE`;
  }
  if (!simulation || !ccyCode) {
    return ``;
  }
  const sim = simulation as Enriched.WithdrawalSimulation;
  if (sim.feeMode === API.WithdrawalFeeChargeMode.ChargedImmediately) {
    return sim.formatted.transactionFeeWithCurrencyCode;
  }

  return sim.formatted.invoicedFeeUsdWithCurrencyCode;
};
