import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { AppDialogInner } from './app-dialog-inner';
import { BaseComponentProps } from '../../types';

export interface Props extends BaseComponentProps {
  isOpen: boolean;
  onClose?: () => void;
  showClose?: boolean;
  widthCls?: string;
  buttonCls?: string;
}

export const AppDialog: FC<Props> = ({
  children,
  isOpen,
  onClose,
  showClose = true,
  widthCls = 'w-full max-w-xl',
  buttonCls = 'top-8',
}) => {
  /**
   * Methods
   */
  const handleOnClose = useCallback(() => {
    if (!onClose) {
      return;
    }
    onClose();
  }, [onClose]);

  /**
   * DOM
   */
  return (
    <Dialog
      data-testid="app-dialog"
      as="div"
      id="modal"
      className="fixed inset-0 z-50 flex flex-col justify-center items-center sm:p-8"
      open={isOpen}
      onClose={handleOnClose}
    >
      {/* overlay  */}
      <div className="fixed inset-0 bg-black opacity-70"></div>
      <AppDialogInner
        cls={cx('relative bg-white text-black sm:rounded-lg', widthCls)}
        showClose={showClose}
      >
        {showClose && (
          <button
            type="button"
            data-testid="app-dialog-close-button"
            className={cx(
              'focus:outline-none absolute right-10 z-30',
              buttonCls
            )}
            onClick={handleOnClose}
            tabIndex={-1}
            // Note: changing tabIndex, given it gets default focus on dialog open & pressing enter after a dialog opens closes the dialog - which is not desirable for our application
          >
            {/* // TODO(Hadrien): replace with close-button icon ? */}
            <XIcon className="w-6 h-6" />
          </button>
        )}

        {children}
      </AppDialogInner>
    </Dialog>
  );
};

export * from './use-app-dialog-helper';
