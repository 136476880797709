import { useCallback, useMemo, useState } from 'react';
import _defaults from 'lodash.defaults';
import {
  FILTER_ASSETS_BY,
  SORT_ASSETS_BY,
  SORT_ASSETS_FIELDS,
  SORT_DIR,
} from '../config';
import { DataStoreType } from '../store/data-store';
import { filterCurrencies, orderBy } from '../utils';
import { EnrichedCurrencyInformation } from '../types';
import { API } from '@xbto/api-client';

type Options = {
  initialFilterBy: FILTER_ASSETS_BY;
  initialOrderBy: SORT_ASSETS_BY;
  initialOrderDir: SORT_DIR;
  filterForMarkets: boolean;
  filterByAccountType: boolean;
};

const defaultOptions: Options = {
  initialFilterBy: FILTER_ASSETS_BY.ALL,
  initialOrderBy: SORT_ASSETS_BY.CODE,
  initialOrderDir: SORT_DIR.ASC,
  filterForMarkets: false,
  filterByAccountType: false,
};

export function useFilterCurrencies(
  DataStore: DataStoreType,
  _watchlist: string[],
  options?: Partial<Options>
) {
  const _options = _defaults({}, options, defaultOptions);

  // Store
  const allCurrencies = DataStore.useStoreState(s => s.metaData.currencies);
  const tenant = DataStore.useStoreState(s => s.tenant ?? undefined);

  const getCurrencies = DataStore.useStoreActions(
    a => a.metaData.getCurrencies
  );
  const account = DataStore.useStoreState(
    s => s.portfolio.accountDetail?.account
  );

  // State
  const [search, setSearch] = useState('');
  const [filterBy, setFilterBy] = useState(_options.initialFilterBy);
  const [sortBy, setSortBy] = useState(_options.initialOrderBy);
  const [sortDir, setSortDir] = useState(_options.initialOrderDir);

  // Vars
  const visibleCurrencies = useMemo(() => {
    return allCurrencies.filter(
      _currency =>
        !_currency.hidden &&
        (_options.filterForMarkets ? _currency.showInMarkets : true)
    );
  }, [allCurrencies, _options.filterForMarkets]);

  const accountsToExcludeFundCurrencies = [
    API.AccountType.Vault,
    API.AccountType.Interest,
    API.AccountType.ProTrading,
  ];

  const filteredCurrencies = useMemo(() => {
    return orderBy(
      filterCurrencies(visibleCurrencies, filterBy, search),
      sortDir,
      SORT_ASSETS_FIELDS[sortBy]
    ).filter(currency => {
      if (
        _options.filterByAccountType &&
        account?.accountType === API.AccountType.Fund
      ) {
        return currency.type === API.CurrencyType.Fund;
      }
      if (
        _options.filterByAccountType &&
        account?.accountType &&
        accountsToExcludeFundCurrencies.includes(account?.accountType)
      ) {
        return currency.type !== API.CurrencyType.Fund;
      }
      return true;
    }) as EnrichedCurrencyInformation[];
  }, [
    visibleCurrencies,
    filterBy,
    sortBy,
    sortDir,
    search,
    _options.filterByAccountType,
    account?.accountType,
    accountsToExcludeFundCurrencies,
  ]);

  const handleFilterBy = useCallback((value: FILTER_ASSETS_BY) => {
    // Reset
    setSearch('');
    setSortBy(SORT_ASSETS_BY.CODE);
    setSortDir(SORT_DIR.ASC);

    setFilterBy(value);
  }, []);

  const refresh = useCallback(() => {
    return getCurrencies({ tenant, isBackgroundXHR: true });
  }, []);

  // API
  return {
    currencies: filteredCurrencies,
    filterBy,
    refresh,
    sortBy,
    sortDir,
    search,
    setFilterBy: handleFilterBy,
    setSortBy,
    setSortDir,
    setSearch,
  };
}
