var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { KytOwnerCategory, KytOwnerCompany, KytOwnerIndividual, KytPlatformCategory, KytPlatform, } from '../../templates/kyt';
import { Wizard, useWizard } from 'react-use-wizard';
import { useEffectOnce } from 'usehooks-ts';
import { API } from '@xbto/api-client';
var STEPS;
(function (STEPS) {
    STEPS[STEPS["OWNER_CATEGORY"] = 0] = "OWNER_CATEGORY";
    STEPS[STEPS["OWNER_COMPANY"] = 1] = "OWNER_COMPANY";
    STEPS[STEPS["OWNER_INDIVIDUAL"] = 2] = "OWNER_INDIVIDUAL";
    STEPS[STEPS["PLATFORM_CATEGORY"] = 3] = "PLATFORM_CATEGORY";
    STEPS[STEPS["PLATFORM_NAME"] = 4] = "PLATFORM_NAME";
})(STEPS || (STEPS = {}));
const StepKytOwnerCategory = ({ onData, onComplete, walletOwnerName, walletPlatform, }) => {
    /**
     * Hooks
     */
    const { goToStep } = useWizard();
    useEffectOnce(() => {
        if (!walletOwnerName && !walletPlatform) {
            onComplete(null);
            return;
        }
        if (!walletOwnerName && walletPlatform) {
            goToStep(STEPS.PLATFORM_CATEGORY);
        }
    });
    /**
     * Methods
     */
    const handleOnNext = useCallback((value) => {
        onData({
            walletOwnerCategory: value,
        }, values => {
            if (value === API.TravelRuleWalletOwnerType.Self) {
                onComplete(values);
                return;
            }
            if (value === API.TravelRuleWalletOwnerType.Company) {
                goToStep(STEPS.OWNER_COMPANY);
                return;
            }
            if (value === API.TravelRuleWalletOwnerType.Individual) {
                goToStep(STEPS.OWNER_INDIVIDUAL);
            }
        });
    }, [onData, onComplete, goToStep]);
    return _jsx(KytOwnerCategory, { onNext: handleOnNext });
};
const StepKytOwnerCompany = ({ onData, onComplete, walletPlatform }) => {
    /**
     * Hooks
     */
    const { goToStep } = useWizard();
    /**
     * Methods
     */
    const handleOnNext = useCallback((values) => {
        onData({
            walletOwnerCompanyName: values.companyName,
        }, values => {
            if (!walletPlatform) {
                onComplete(values);
                return;
            }
            goToStep(STEPS.PLATFORM_CATEGORY);
        });
    }, [onData, onComplete, walletPlatform, goToStep]);
    /**
     * DOM
     */
    return _jsx(KytOwnerCompany, { onNext: handleOnNext });
};
const StepKytOwnerIndividual = ({ onData, onComplete, walletPlatform }) => {
    /**
     * Hooks
     */
    const { goToStep } = useWizard();
    /**
     * Methods
     */
    const handleOnNext = useCallback((values) => {
        onData({
            walletOwnerIndividualInfo: values,
        }, values => {
            if (!walletPlatform) {
                onComplete(values);
                return;
            }
            goToStep(STEPS.PLATFORM_CATEGORY);
        });
    }, [onData, onComplete, walletPlatform, goToStep]);
    /**
     * DOM
     */
    return _jsx(KytOwnerIndividual, { onNext: handleOnNext });
};
const StepKytPlatformCategory = ({ onData, onComplete, }) => {
    /**
     * Hooks
     */
    const { goToStep } = useWizard();
    /**
     * Methods
     */
    const handleOnNext = useCallback((value) => {
        onData({
            walletPlatformCategory: value,
        }, values => {
            if (value === API.TravelRuleWalletType.Centralized) {
                goToStep(STEPS.PLATFORM_NAME);
                return;
            }
            onComplete(values);
        });
    }, [onData, onComplete, goToStep]);
    /**
     * DOM
     */
    return _jsx(KytPlatformCategory, { onNext: handleOnNext });
};
const StepKytPlatformName = ({ onData, onComplete, searchExchanges }) => {
    /**
     * State
     */
    const [value, setValue] = useState({ name: '', did: '' });
    const [platforms, setPlatforms] = useState([]);
    /**
     * Hooks
     */
    // Note: Do not remove this - we need this so it can be inside `Wizard`
    useWizard();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield searchExchanges(value.name);
            setPlatforms(result || []);
        }))();
    }, [value, searchExchanges]);
    /**
     * Methods
     */
    const handleSelectedOnNext = useCallback((value) => {
        setValue({ name: value.label, did: value.id });
    }, []);
    const handleOnNext = useCallback(() => {
        onData({ walletPlatformInfo: value }, values => {
            onComplete(values);
        });
    }, [onData, onComplete, value]);
    const handleSearch = useCallback((name) => __awaiter(void 0, void 0, void 0, function* () {
        setValue({ name, did: '' });
    }), []);
    /**
     * DOM
     */
    return (_jsx(KytPlatform, { value: value.name, onChange: handleSearch, onSelect: handleSelectedOnNext, platforms: platforms, onNext: handleOnNext }));
};
export const KytWizard = ({ walletOwnerName, walletPlatform, searchExchanges, onComplete, }) => {
    /**
     * State
     */
    const [data, setData] = useState();
    /**
     * Methods
     */
    const handleOnData = useCallback((values, callback) => {
        const newValues = Object.assign(Object.assign({}, data), values);
        setData(newValues);
        callback(newValues);
    }, [setData, data]);
    const handleOnComplete = useCallback(values => {
        onComplete(values);
    }, [onComplete]);
    /**
     * DOM
     */
    return (_jsxs(Wizard, { children: [_jsx(StepKytOwnerCategory, { walletOwnerName: walletOwnerName, walletPlatform: walletPlatform, onData: handleOnData, onComplete: handleOnComplete }), _jsx(StepKytOwnerCompany, { walletPlatform: walletPlatform, onData: handleOnData, onComplete: handleOnComplete }), _jsx(StepKytOwnerIndividual, { walletPlatform: walletPlatform, onData: handleOnData, onComplete: handleOnComplete }), _jsx(StepKytPlatformCategory, { onData: handleOnData, onComplete: handleOnComplete }), _jsx(StepKytPlatformName, { onData: handleOnData, onComplete: handleOnComplete, searchExchanges: searchExchanges })] }));
};
