import { FC } from 'react';
import cx from 'classnames';
import { API } from '@xbto/api-client';
import { AccountTypeLabel, RowLabelContent } from 'common';
import { BankAccountInfo } from '~/components/bank-accounts/bank-account-info';
import { RowSection } from '../../shared/row-section';

interface WithdrawalInfoProps {
  amountSent: string;
  accountName: string;
  accountType: string;
  accountNumber: string;
  fees: string;
  amountReceive: string;
  description?: string | null;
  bankAccount: API.BankAccount | null | undefined;
  cls?: string;
}

export const WithdrawalInfo: FC<WithdrawalInfoProps> = ({
  amountSent,
  accountName,
  accountType,
  accountNumber,
  fees,
  amountReceive,
  bankAccount,
  description,
  cls,
}) => {
  /**
   * DOM
   */
  const accLabel = AccountTypeLabel?.[accountType] || '';
  return (
    <div className={cx('mx-10 my-6', cls)}>
      <RowLabelContent
        cls="border-none"
        label="Send"
        showColon={false}
        content={
          <div className="flex flex-col text-primary items-end">
            <span className="text-base">{amountSent}</span>
            <RowSection
              title={accountName || ''}
              description={`${accLabel} ${accountNumber || ''}`}
            />
          </div>
        }
      />

      {/* fees  */}
      <RowLabelContent
        showColon={false}
        label={`Fee`}
        content={<p className="font-bold text-base">{fees}</p>}
      />

      {/* destination  */}
      <RowLabelContent
        label={`Receive`}
        showColon={false}
        content={
          <div className="flex flex-col text-primary items-end">
            <span className="text-base">{amountReceive}</span>
            <BankAccountInfo
              cls="flex flex-col items-end"
              clsBankInfo="text-sm"
              account={bankAccount}
              showBankName
            />
          </div>
        }
      />

      {/* description  */}
      {description && (
        <RowLabelContent
          label={`Description`}
          showColon={false}
          content={<p>{description}</p>}
        />
      )}
    </div>
  );
};
