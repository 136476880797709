import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { TextInput, View, Pressable } from 'react-native';
import { Row } from '../../atoms/row';
import { Icon } from '../../molecules/icon';
import { PlatformsFontRegular } from '../../atoms/typography';
import { colors, createStyles } from '../../theme';
import { useInputFocusManager } from '../../hooks';
import { isWeb } from '../../utils';
export const SearchInput = (props) => {
    const { collapsed, maxLength, onChange, onToggleCollapse, style, value } = props;
    /**
     * Vars
     */
    const isCollapsible = !!onToggleCollapse;
    /**
     * Hooks
     */
    const { isFocused, onFocus, onBlur } = useInputFocusManager();
    /**
     * Methods
     */
    const clearInput = useCallback(() => {
        if (isCollapsible && !collapsed && value === '') {
            onToggleCollapse === null || onToggleCollapse === void 0 ? void 0 : onToggleCollapse();
        }
        onChange === null || onChange === void 0 ? void 0 : onChange('');
    }, [collapsed, isCollapsible, value, onChange, onToggleCollapse]);
    /**
     * DOM
     */
    const computedInputStyles = [$.textInput];
    if (isFocused && isWeb) {
        computedInputStyles.push({
            // @ts-expect-error this property is web specific
            outline: 'none',
        });
    }
    return isCollapsible && collapsed ? (_jsx(View, Object.assign({ style: $.collapsedContainer }, { children: _jsx(Pressable, Object.assign({ onPress: onToggleCollapse }, { children: _jsx(Icon, { name: "search-lens", size: "sm", em: true }) })) }))) : (_jsxs(Row.L, Object.assign({ px: "s", py: "xs", style: [
            $.inputContainer,
            style,
            // TODO(Hadrien): Manage disabled && inputStyles.disabled,
            // TODO(Hadrien): Manage error && inputStyles.error,
        ] }, { children: [_jsx(Icon, { name: "search-lens", size: "sm", em: true }), _jsx(TextInput, { style: computedInputStyles, autoFocus: isCollapsible, value: value, onChangeText: onChange, placeholder: "Search", keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, maxLength: maxLength, keyboardAppearance: "dark", numberOfLines: 1, placeholderTextColor: colors.onTextInputDim, selectionColor: colors.onTextInputEm, underlineColorAndroid: colors.transparent, onBlur: onBlur, onFocus: onFocus }), (value.length > 0 || (isCollapsible && !collapsed)) && (_jsx(Pressable, Object.assign({ onPress: clearInput, style: $.clearIcon }, { children: _jsx(Icon, { name: "close-x", size: "sm", em: true }) })))] })));
};
const $ = createStyles({
    collapsedContainer: {
        borderRadius: 'rad1',
        backgroundColor: 'backgroundAlt',
        ml: 's',
        p: 'xs',
    },
    inputContainer: {
        justifyContent: 'center',
        borderRadius: 'rad1',
        backgroundColor: 'backgroundAlt',
        borderColor: 'transparent',
        maxHeight: 32,
    },
    textInput: Object.assign(Object.assign({ mx: 'xs', fontSize: 12, lineHeight: 16, height: 16 }, PlatformsFontRegular), { color: 'onTextInput', padding: 0, flexGrow: 1, backgroundColor: 'backgroundAlt', borderColor: 'transparent', borderRadius: 'rad1' }),
    clearIcon: {
        position: 'absolute',
        right: 12,
    },
});
