import { API } from '@xbto/api-client';
import cx from 'classnames';
import { AppIcon } from 'common';
import { Formik } from 'formik';
import { FC } from 'react';
import { BaseForm } from '../../definitions/base-form';
import { DataStore } from '../../store';
import { AppLoader } from '../app-loader';
import { Error } from '../common/error';
import { TextField } from '../forms/text-field';
import { PermissionSelector } from './formik/fields';
import { validate } from './validate';

export enum FormField {
  name = 'name',
  passphrase = 'passphrase',
  ipAddresses = 'ipAddresses',
  canTrade = 'canTrade',
  canWithdraw = 'canWithdraw',
  canCreateApiKeys = 'canCreateApiKeys',
  canCreateInternalTransfer = 'canCreateInternalTransfer',
}

export interface FormProps extends BaseForm {
  [FormField.name]: string;
  [FormField.passphrase]: string;
  [FormField.ipAddresses]: string;
  [FormField.canTrade]: boolean;
  [FormField.canWithdraw]: boolean;
  [FormField.canCreateApiKeys]: boolean;
  [FormField.canCreateInternalTransfer]: boolean;
}

interface Props {
  header?: JSX.Element;
  saveButtonCls?: string;
  saveButtonText?: string;
  onClose: (apiKey: API.CreateApiKeyResponse | null) => void;
}

export const CreateApiKey: FC<Props> = ({
  header,
  saveButtonCls = `app-button-accent`,
  saveButtonText = `Create API Key`,
  onClose,
}) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const createApiKey = DataStore.useStoreActions(a => a.settings.createApiKey);
  const getApiKeys = DataStore.useStoreActions(a => a.settings.getApiKeys);
  /**
   * Methods
   */
  const onFormValidate = (values: FormProps) => {
    const errors = validate(values);
    return errors;
  };
  const onFormSubmit = async (values: FormProps) => {
    const body: API.CreateApiKeyRequest = {
      name: values.name,
      passphrase: values.passphrase,
      ipAddresses: values.ipAddresses,
      canTrade: values.canTrade,
      canWithdraw: values.canWithdraw,
      canCreateApiKeys: values.canCreateApiKeys,
      canCreateInternalTransfer: values.canCreateInternalTransfer,
    };
    const newlyCreatedApiKey = await createApiKey(body);
    if (!newlyCreatedApiKey) {
      return;
    }
    onClose(newlyCreatedApiKey);
    getApiKeys();
  };

  /**
   * DOM
   */
  const initialValues: FormProps = {
    name: '',
    passphrase: '',
    ipAddresses: '',
    canTrade: false,
    canWithdraw: false,
    canCreateApiKeys: false,
    canCreateInternalTransfer: false,
  };
  return (
    <div>
      {/* header  */}
      {header ? (
        <>{header}</>
      ) : (
        <div className="flex flex-col items-center px-10">
          <AppIcon icon="lock-2fa" cls="mt-10 mb-2" size="lg" />
          <h4>Create an API Key</h4>
        </div>
      )}

      {/* api errors  */}
      <Error message={error} cls={`mx-10`} />

      {/* form  */}
      <Formik<FormProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({ errors, isValidating, submitForm }) => {
          /**
           * Form DOM
           */
          const hasErrors = Object.keys(errors).length > 0;
          return (
            <>
              {/* busy indicator when validation is happening  */}
              {isValidating && (
                <AppLoader bgColor={'bg-transparent'} spinnerTop="0%" />
              )}

              {/* fields  */}
              <div className="my-6 px-10">
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <TextField name={FormField.name} label={'Name'} />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <TextField
                      name={FormField.passphrase}
                      label={'Optional passphrase'}
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <TextField
                      name={FormField.ipAddresses}
                      label={
                        'Optional comma delimited list of whitelisted IP addresses'
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <PermissionSelector
                      fieldName={FormField.canTrade}
                      label="Trading"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <PermissionSelector
                      fieldName={FormField.canWithdraw}
                      label="Withdrawals"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <PermissionSelector
                      fieldName={FormField.canCreateApiKeys}
                      label="API Key creation"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row flex-1">
                  <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
                    <PermissionSelector
                      fieldName={FormField.canCreateInternalTransfer}
                      label="Can transfer (internal)"
                    />
                  </div>
                </div>
              </div>

              {/* actions  */}
              <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
                {/* cancel button  */}
                <button
                  className="app-button-outline w-full mr-3 flex-1"
                  onClick={e => {
                    e.preventDefault();
                    onClose(null);
                  }}
                >
                  Cancel
                </button>
                {/* withdraw button  */}
                <button
                  disabled={hasErrors}
                  type="button"
                  className={cx(
                    'flex-1 flex items-center justify-center',
                    saveButtonCls
                  )}
                  onClick={submitForm}
                >
                  {saveButtonText}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
