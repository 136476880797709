import { API } from '@xbto/api-client';
import {
  EnrichedAllHolding,
  EnrichedAllHoldings,
  EnrichedCurrencyInformation,
  GlobalAppSettings,
} from '../../types/enriched';
import { formatters } from '../formatters';
import parseNumber from 'multi-number-parse';
import { formatAmount } from '../format-amount';
import { DEFAULTS } from '../../constants';
import {
  HoldingsTableColumn,
  HoldingsTableColumnId,
  HoldingsTableColumnTitle,
} from '../../store/portfolio/types';
import { DataModel } from '../../store';

const getColumns = (
  isAdmin: boolean,
  client: DataModel['client']
): HoldingsTableColumn[] => {
  const result: HoldingsTableColumn[] = [
    {
      id: HoldingsTableColumnId.Asset,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Asset],
      sortable: true,
      sx: { width: '160%' },
    },
    {
      id: HoldingsTableColumnId.Quantity,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Quantity],
      sortable: true,
      sx: { width: '80%' },
    },
    {
      id: HoldingsTableColumnId.Price,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Price],
      sortable: true,
      sx: { width: '60%' },
    },
    {
      id: HoldingsTableColumnId.Value,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Value],
      sortable: true,
      sx: { width: '60%' },
    },
    {
      id: HoldingsTableColumnId.Change24h,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Change24h],
      sortable: true,
      sx: { width: '40%' },
    },
  ];

  // is not admin user & only show action ... in web app
  if (!isAdmin && client === 'web') {
    result.push({
      id: HoldingsTableColumnId.Action,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Action],
      sortable: false,
      sx: { width: '25%' },
    });
  }

  return result;
};

export const enrichAllHoldings = (
  holdings: API.GetAllAssetsHoldingsResponse | null,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[],
  globalAppSettings: GlobalAppSettings | null,
  client: DataModel['client']
): EnrichedAllHoldings | null => {
  if (!holdings?.currencies) {
    return null;
  }

  const assets: EnrichedAllHolding[] = [];

  holdings.currencies.forEach(_holding => {
    const currency = currencies.find(
      _currency => _currency.code === _holding.currency?.code
    );
    if (currency) {
      const balance = parseNumber(_holding.totalQty ?? '0');
      const balanceUsd = parseNumber(_holding.totalQtyUsd ?? '0');
      const pendingBalance = 0; // Note: API does not provide it at this layer
      const pendingBalanceUsd = parseNumber(_holding.totalPendingUsd ?? '0');
      const balanceIncludingPending = balance + pendingBalance;
      const balanceIncludingPendingUsd = balanceUsd + pendingBalanceUsd;
      const value = currency.fxRate.rate * balanceIncludingPending;
      assets.push({
        currency,
        balance,
        balanceUsd: parseNumber(_holding.totalQtyUsd ?? '0'),
        hasBalance: !!balance,
        isAccountOfTypeProTrading: false,
        isAccountOfTypeVault: false,
        isAccountOfTypeFund: false,
        formatted: {
          earnedInterestThisWeekUsd: '0',
          earnedInterestTotalUsd: '0',
          balance: formatAmount(_holding.totalQty, currency?.decimals),
          balanceWithCurrencyCode: formatters.getCurrency(
            _holding.totalQty,
            currency?.decimals,
            currency.displayCode,
            fiatCurrencyCodes
          ),
          balanceUsd: formatAmount(_holding.totalQtyUsd, 2),
          balanceUsdWithCurrencyCode: formatters.getCurrency(
            _holding.totalQtyUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          pendingBalance: formatAmount(pendingBalance, currency?.decimals),
          pendingBalanceUsd: formatAmount(pendingBalanceUsd, 2),
          balanceIncludingPending: formatters.getAmount(
            balanceIncludingPending,
            currency.decimals
          ),
          balanceIncludingPendingWithCurrencyCode: formatters.getCurrency(
            balanceIncludingPending,
            currency.decimals,
            currency.displayCode,
            fiatCurrencyCodes
          ),
          balanceIncludingPendingUsdWithCurrencyCode: formatters.getCurrency(
            balanceIncludingPendingUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          tradableQuantity: '0',
          tradableQuantityUsd: formatAmount(_holding.totalTradableUsd, 2),
          withdrawableQuantity: '0',
          withdrawableQuantityUsd: formatAmount(
            _holding.totalWithdrawableUsd,
            DEFAULTS.DECIMAL_SCALE
          ),
          withdrawableQuantityUsdWithCurrencyCode: formatters.getCurrency(
            _holding.totalWithdrawableUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          value: formatters.getCurrency(
            value,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          rateOfReturnPercentMTD: 0,
          rateOfReturnPercentYTD: 0,
          totalReturnPercent: 0,
          totalReturn: '0',
          mtdReturn: '0',
          ytdReturn: '0',
        },
      });
    }
  });

  return {
    assets,
    holdingsTable: {
      columns: getColumns(
        globalAppSettings?.userType === API.UserType.ClientAdminReadWrite,
        client
      ),
    },
  };
};
