import { FormikHelpers } from 'formik';
import { ApiResponse } from '../definitions/api-response';
import { BaseForm } from '../definitions/base-form';
import { API } from '@xbto/api-client';
import { nameof } from './typescript-helpers';
import { APP_ROUTES } from '../routes';
import { ApiError } from 'common';

export const handleApiException = async <TForm extends BaseForm>(
  exception: any,
  helper: FormikHelpers<TForm>
) => {
  if (exception instanceof ApiError) {
    helper.setFieldValue(nameof<BaseForm>('errorCode'), exception.code);
    helper.setFieldValue(nameof<BaseForm>('errorMessage'), exception.message);
  } else if (exception instanceof API.ApiException) {
    const result = exception.result as ApiResponse<any>;
    helper.setFieldValue(nameof<BaseForm>('errorCode'), result.errorCode);
    helper.setFieldValue(nameof<BaseForm>('errorMessage'), result.errorMessage);
  } else if ('errorCode' in exception && 'errorMessage' in exception) {
    const result = exception as ApiResponse<any>;
    helper.setFieldValue(nameof<BaseForm>('errorCode'), result.errorCode);
    helper.setFieldValue(nameof<BaseForm>('errorMessage'), result.errorMessage);
  } else {
    helper.setFieldValue(
      nameof<BaseForm>('errorMessage'),
      'An unexpected error happened'
    );
    helper.setFieldValue(nameof<BaseForm>('errorCode'), 0);
  }
};

export const getErrorCode = (exception: any) => {
  if (exception instanceof ApiError) {
    return exception.code;
  }

  if (exception instanceof API.ApiException) {
    const result = exception.result as ApiResponse<any>;
    return result.errorCode;
  }

  if ('errorCode' in exception && 'errorMessage' in exception) {
    const result = exception as ApiResponse<any>;
    return result.errorCode;
  }

  return undefined;
};

export const clearFormErrors = async <T>(helper: FormikHelpers<T>) => {
  helper.setFieldValue(nameof<BaseForm>('errorMessage'), null);
  helper.setFieldValue(nameof<BaseForm>('errorCode'), null);
};

export const handleKycException = async <TForm extends BaseForm>(
  exception: any,
  helper: FormikHelpers<TForm> | null,
  history: any
) => {
  const errorCode = getErrorCode(exception);

  const redirectionErrorCodes = [
    errorCodes.invalidKycState,
    errorCodes.accountIsBlocked,
    errorCodes.invalidStateToPerformChecks,
    errorCodes.kycDenied,
    errorCodes.tooManyFailedKycs,
  ];

  if (errorCode && redirectionErrorCodes.includes(errorCode)) {
    history.push(APP_ROUTES.AUTH_KYC_INDEX);
  } else if (helper) {
    handleApiException(exception, helper);
  }
};

export const errorCodes = {
  invalidKycState: 12000,
  onfidoCannotBeUsedWithCorporateAccounts: 12002,
  accountHasNoUsers: 12003,
  invalidStateToPerformChecks: 12004,
  tooManyFailedKycs: 12005,
  kycSubmissionNotFound: 12006,
  invalidFileSubmitted: 12007,
  accountIsBlocked: 12008,
  kycDenied: 12010,
  countryNotAuthorized: 12010,
};
