/* eslint @typescript-eslint/no-explicit-any: 0 */

import { ApiClient, ApiException, ApiResponse } from './api';
import { parseContentDispositionFilename } from './utils/parse-content-disposition-filename';

const processResponse = async (
  response: Response,
  handlerOnError: (r: Response) => void,
  hanlderJsonParseReviver: ((key: string, value: any) => any) | undefined,
  handler200: (r: Response) => void
) => {
  const status = response.status;
  const _headers: any = {};
  if (response.headers && response.headers.forEach) {
    response.headers.forEach((v: any, k: any) => (_headers[k] = v));
  }

  if (status < 200 || status >= 400) {
    return handlerOnError(response) as any;
  }
  if (
    status === 200 &&
    response.type === 'cors' &&
    response.redirected &&
    response.url
  ) {
    return (window.location.href = response.url as any);
  }

  if (status === 200) {
    return await handler200(response);
  } else if (status === 429) {
    return response.text().then(_responseText => {
      throw new ApiException(
        'Too Many Requests',
        status,
        _responseText,
        _headers,
        null
      );
    });
  } else if (status === 400) {
    return response.text().then(_responseText => {
      let result400: any = null;
      result400 =
        _responseText === ''
          ? null
          : <ApiResponse>JSON.parse(_responseText, hanlderJsonParseReviver);
      throw new ApiException(
        'Bad Request',
        status,
        _responseText,
        _headers,
        result400
      );
    });
  } else if (status !== 200 && status !== 204) {
    return response.text().then(_responseText => {
      throw new ApiException(
        'An unexpected server error occurred.',
        status,
        _responseText,
        _headers,
        null
      );
    });
  }
  return Promise.resolve<void>(<any>null);
};

const convertResponseToBlob = async (r: Response) => {
  const clone = r.clone();
  const blob = await clone.blob();

  const cdValue = clone.headers.get('content-disposition') || 'unknown';
  const filename = parseContentDispositionFilename(cdValue);
  return {
    filename,
    blob,
  };
};

/**
 * Note: these are overrides that we can make to the API interfaces from BE, when we are waiting for BE merge that exposes new interfaces so FE can carry on
 */

const HEADER_ACCOUNT_ID = 'x-account-id';

export abstract class StablehouseClient extends ApiClient {
  protected defaultAccountId: string | null = null;

  constructor(
    baseUrl?: string,
    http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
  ) {
    super(baseUrl, http);
  }

  protected abstract getAccessToken(): Promise<string | null>;

  public setDefaultAccountId(id: string | null) {
    this.defaultAccountId = id;
  }

  protected async getDefaultHeaders(): Promise<HeadersInit> {
    const accessToken = await this.getAccessToken();

    const headers: HeadersInit = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    if (!headers[HEADER_ACCOUNT_ID]) {
      headers[HEADER_ACCOUNT_ID] =
        this.defaultAccountId ?? 'stablehouse-fallback-account-id';
    }

    return headers;
  }

  protected async processGetActivityTransactionsCsv(
    response: Response
  ): Promise<void> {
    return await processResponse(
      response,
      this.onError,
      this.jsonParseReviver,
      convertResponseToBlob
    );
  }

  protected async processGetFundsCsv(response: Response): Promise<void> {
    return await processResponse(
      response,
      this.onError,
      this.jsonParseReviver,
      convertResponseToBlob
    );
  }

  protected async processGetAccountHoldingsCsv(
    response: Response
  ): Promise<void> {
    return await processResponse(
      response,
      this.onError,
      this.jsonParseReviver,
      convertResponseToBlob
    );
  }
}

export * from './api';
