export enum SendSteps {
  Form = 'send-form',
  Preview = 'send-preview',
  Kyt = 'send-kyt',
  PreviewTwoFaWidget = 'preview-two-fa-widget',
  Code = 'send-code',
  ConfirmCancellation = `send-confirm-cancellation`,
  Cancelled = `send-cancelled`,
  Summary = 'send-summary',
  AccountLocked = 'account-locked',
  AddressBook = 'address-book',
  AddCryptoAddress = 'add-crypto-address',
}
