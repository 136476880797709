import { API } from '@xbto/api-client';
import { ActivityAmount, ActivityDate } from '../shared';
import {
  CellActivityStatus,
  CellTransferAccount,
} from '~/components/transactions/cells';
import { Enriched } from 'common';

export const getFundSubscriptionRecords = (
  activity: API.ActivityTransaction,
  account: Enriched.ListAccountItem | undefined,
) => {
  return {
    '': <CellActivityStatus status={activity?.statusFilter || ''} />,
    Amount: (
      <ActivityAmount
        amount={activity?.amount1}
        code={activity?.currency1?.displayCode}
        decimals={activity?.currency1?.decimals}
      />
    ),
    To: <CellTransferAccount data={account} />,
    Date: <ActivityDate date={activity?.creationDate} />,
  };
};
