import { ReactNode } from 'react';

export * from './actions';
export * from './quorum';
export * from './storage';
export * from './alert';
export * from './charting';
export * from './network';
export * from './enriched';
export * from './kyt';

export type Balances = {
  total: string;
  hasPendingBalance: boolean;
  formattedTotal: string;
  formattedTotalWithCurrencyCode: string;
  pendingSettlement: number;
  formattedPendingSettlement: string;
  pendingIncoming: number;
  formattedPendingIncoming: string;
  pendingReceive: number;
  formattedPendingReceive: string;
  pendingTransferIn: number;
  formattedPendingTransferIn: string;
  pendingOutgoing: number;
  formattedPendingOutgoing: string;
  pendingSend: number;
  formattedPendingSend: string;
  pendingTransferOut: number;
  formattedPendingTransferOut: string;
  openOrders: number;
  formattedOpenOrders: string;
  tradable?: number;
  formattedTradable?: string;
  withdrawable: string;
  formattedWithdrawable: string;
};

export type IntervalId = ReturnType<typeof setInterval>;

export type AllowedWorkflowType =
  | 'workflow-buy'
  | 'workflow-sell'
  | 'workflow-send'
  | 'workflow-receive'
  | 'workflow-add-cash'
  | 'workflow-withdraw-cash'
  | 'workflow-transfer';

export interface ContentCardProps {
  id: string;
  url?: string;
  icon?: string;
  title: string;
  imageWeb: string;
  ctaTitle: string;
  imageMobile: string;
  content: string[] | string;
}

export interface AccountTypePermissionsProps {
  hasAccountOfTypeCustody?: boolean;
  hasAccountOfTypeTrading?: boolean;
  hasAccountOfTypeProTrading?: boolean;
}

export enum AccountTypeLabel {
  Interest = 'Trading',
  Pool = 'Trading',
  Trading = 'Pro Trading',
  ProTrading = 'Pro Trading',
  Custody = 'Vault',
  Vault = 'Vault',
  Fund = 'Fund',
  closed = 'Closed',
}

export enum InvoiceStatusTitle {
  WaitingPayment = 'Unpaid',
  Paid = 'Paid',
  Open = 'Open',
  Cancelled = 'Cancelled',
}

export interface BaseComponentProps {
  children?: ReactNode;
}
