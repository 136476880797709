import { API } from '@xbto/api-client';

import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import { Enriched } from '../../types';
import { maskLead } from '../mask-lead';

export function enrichListAccountItem(
  item: API.ListAccountItem,
  fiatCurrencyCodes: string[]
): Enriched.ListAccountItem {
  return {
    ...item,
    hasBalance: Number(item.totalAccountBalanceUsd ?? '0') > 0,
    formatted: {
      totalUsd: formatters.getCurrency(
        item.totalAccountBalanceUsd,
        DEFAULTS.DECIMAL_SCALE,
        'USD',
        fiatCurrencyCodes
      ),
      maskedAccountNumber: item.account?.accountNumber
        ? maskLead(item.account.accountNumber)
        : null,
    },
  };
}
