import { API } from '@xbto/api-client';
import { Enriched, EnrichedCurrencyInformation } from '../../types';
import { formatters } from '../formatters';
import { DEFAULTS } from '../../constants';
import { createSendDestination } from './create-send-destination';

export const enrichWithdrawalSimulation = (
  simulation: API.WithdrawalSimulation | null,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[]
): Enriched.WithdrawalSimulation | null => {
  if (!simulation) {
    return null;
  }

  const currency =
    currencies.find(({ code }) => code === simulation.currencyCode) ?? null;

  if (!currency) {
    return null;
  }

  const amountWithCurrencyCode = `${simulation.amount} ${currency.displayCode}`;
  //   formatters.getCurrency(
  //   simulation.amount,
  //   currency.decimals,
  //   currency.displayCode,
  //   fiatCurrencyCodes
  // );

  const amountWithCurrencyCodeAndSign = formatters.forceSign(
    amountWithCurrencyCode,
    '-'
  );

  const amountUsdWithCurrencyCode = formatters.getCurrency(
    simulation.homeCurrencyAmount,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  const sendingAmountWithCurrencyCode = `${simulation.sendingAmount} ${currency.displayCode}`;
  // formatters.getCurrency(
  //   simulation.sendingAmount,
  //   currency.decimals,
  //   currency.displayCode,
  //   fiatCurrencyCodes
  // );

  const sendingAmountUsdWithCurrencyCode = formatters.getCurrency(
    simulation.homeCurrencySendingAmount,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  const transactionFeeWithCurrencyCode = `${simulation.transactionFee} ${currency.displayCode}`;
  //   formatters.getCurrency(
  //   simulation.transactionFee,
  //   currency.decimals,
  //   currency.displayCode,
  //   fiatCurrencyCodes
  // );

  const invoicedFeeWithCurrencyCode = formatters.getCurrency(
    simulation.invoicedFee,
    currency.decimals,
    currency.displayCode,
    fiatCurrencyCodes
  );

  const invoicedFeeUsdWithCurrencyCode = formatters.getCurrency(
    simulation.invoicedFeeUsd,
    DEFAULTS.DECIMAL_SCALE,
    'USD',
    fiatCurrencyCodes
  );

  return {
    ...simulation,
    isExternal: true,
    formatted: {
      amountWithCurrencyCode,
      amountWithCurrencyCodeAndSign,
      amountUsdWithCurrencyCode,
      destination: createSendDestination(simulation, currency),
      sendingAmountWithCurrencyCode,
      sendingAmountUsdWithCurrencyCode,
      transactionFeeWithCurrencyCode,
      invoicedFeeWithCurrencyCode,
      invoicedFeeUsdWithCurrencyCode,
    },
  };
};
