import cx from 'classnames';

export function getSelectInputStyle(
  hasError: boolean,
  asAddon?: boolean,
  isActive?: boolean,
  leftAddonBorder = true,
  ...classNames: string[]
) {
  return cx(
    'full',
    {
      errored: hasError,
      'app-input-select': !asAddon,
      'app-input-select-addon': asAddon,
      'app-input-select-addon-open': isActive && asAddon,
      'app-input-select-addon custom-min-height': !leftAddonBorder && asAddon,
    },
    ...classNames
  );
}
