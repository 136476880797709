import { useCallback, useState } from 'react';
export const useInputFocusManager = ({ onFocus = undefined, onBlur = undefined, } = {}) => {
    /**
     * State
     */
    const [isFocused, setIsFocused] = useState(false);
    /**
     * Methods
     */
    const handleOnFocus = useCallback(e => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    }, [setIsFocused, onFocus]);
    const handleOnBlur = useCallback(e => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(e);
        }
    }, [setIsFocused, onBlur]);
    /**
     * Result
     */
    return {
        isFocused,
        onFocus: handleOnFocus,
        onBlur: handleOnBlur,
    };
};
