import { API } from '@xbto/api-client';
import {
  AppIconType,
  BaseComponentProps,
  CurrencyIcon,
  QuorumOperationModalKind,
  QuorumOperationModalType,
} from 'common';
import { FC } from 'react';
import { AppIcon } from 'common';
import cx from 'classnames';
import { Error } from '../common/error';
import { DataStore } from '../../store';
import { getAccountAndUserToggleStatus } from '../../utils/get-account-and-user-toggle-status';
interface Props {
  kind: Partial<API.QuorumOperationKind | QuorumOperationModalKind>;
  onClose: (addressId?: string | null) => void;
  showGenericButton?: boolean;
  modalType?: QuorumOperationModalType | null;
  createWithdrawalAddressData?: API.WithdrawalAddress | undefined;
  createBankAccountAddressData?: API.BankAccount | undefined;
  approvalData?: API.QuorumOperation | API.QuorumOperation | undefined;
  accountNameDisable?: string | null;
}

interface ButtonProps extends BaseComponentProps {
  itemId?: string;
  cls?: string;
  onClose: (addressId?: string | null) => void;
}

enum IconOptions {
  close = 'close-button',
  check = 'check',
}

const buttonText = {
  backHome: 'Back to Home',
  viewApprovals: 'View Approvals',
  cancel: 'Cancel',
};

const FooterButton: FC<ButtonProps> = ({ children, onClose, itemId, cls }) => (
  <button
    role="button"
    type="button"
    className={cx('w-full flex-1', cls)}
    onClick={e => {
      e.preventDefault();
      onClose(itemId);
    }}
  >
    {children}
  </button>
);

export const ApprovalConfirmationModal: FC<Props> = ({
  kind,
  onClose,
  modalType,
  createWithdrawalAddressData,
  createBankAccountAddressData,
  approvalData,
  showGenericButton,
  accountNameDisable,
}) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);

  /**
   * DOM
   */
  if (!kind) return null;
  const modalIcon =
    modalType === QuorumOperationModalType.rejected
      ? IconOptions.close
      : IconOptions.check;
  const headerTitle =
    kind !== QuorumOperationModalKind.AddBankAccount &&
    kind !== QuorumOperationModalKind.AddWithdrawalAddress &&
    kind !== QuorumOperationModalKind.ConfirmDeleteWithdrawalAddress &&
    kind !== QuorumOperationModalKind.RequestSentForApproval &&
    kind !== QuorumOperationModalKind.ConfirmDisableFund &&
    kind !== QuorumOperationModalKind.ConfirmDisableUser &&
    getAccountAndUserToggleStatus(kind, approvalData?.parameters.state);

  const actionDetails =
    createWithdrawalAddressData ||
    createBankAccountAddressData?.bankAccountDetails ||
    approvalData?.parameters;

  const confirmationData: {
    [key: string]: {
      icon: AppIconType;
      header: string | JSX.Element;
      content: string;
      footerbutton?: JSX.Element;
    };
  } = {
    AddBankAccount: {
      icon: IconOptions.check,
      header: 'Bank added!',
      content: 'Bank account has been added and is now awaiting approval.',
      footerbutton: (
        <FooterButton cls="app-button-outline" onClose={onClose}>
          {buttonText.viewApprovals}
        </FooterButton>
      ),
    },
    AddWithdrawalAddress: {
      icon: IconOptions.check,
      header: 'Address added!',
      content: 'The below address has been added and is now awaiting approval.',
      footerbutton: (
        <FooterButton cls="app-button-outline" onClose={onClose}>
          {buttonText.viewApprovals}
        </FooterButton>
      ),
    },
    GenericCopy: {
      icon: modalIcon,
      header: `${headerTitle} ${modalType}!`,
      content: '',
      footerbutton: (
        <FooterButton cls="app-button-outline" onClose={onClose}>
          {buttonText.backHome}
        </FooterButton>
      ),
    },
    ConfirmDeleteWithdrawalAddress: {
      icon: 'negative-circle-flag',
      header: 'Are you sure you want to remove this address?',
      content: 'All associated details to this address will be removed.',
      footerbutton: (
        <>
          {showGenericButton ? (
            <FooterButton cls="app-button-outline" onClose={onClose}>
              {buttonText.backHome}
            </FooterButton>
          ) : (
            <>
              {/* cancel button  */}
              <FooterButton cls="app-button-outline mr-3" onClose={onClose}>
                {buttonText.cancel}
              </FooterButton>
              {/* remove button  */}
              <FooterButton
                onClose={onClose}
                cls="app-button-accent"
                itemId={createWithdrawalAddressData?.id}
              >
                Remove address
              </FooterButton>
            </>
          )}
        </>
      ),
    },
    ConfirmDisableFund: {
      icon: 'negative-circle-flag',
      header: 'Are you sure you want to disable this fund?',
      content:
        'When submitted, this request will be sent for approval to the relevant administrator.',
      footerbutton: (
        <>
          {showGenericButton ? (
            <FooterButton cls="app-button-outline" onClose={onClose}>
              {buttonText.backHome}
            </FooterButton>
          ) : (
            <>
              {/* cancel button  */}
              <FooterButton cls="app-button-outline mr-3" onClose={onClose}>
                {buttonText.cancel}
              </FooterButton>
              {/* remove button  */}
              <FooterButton
                onClose={onClose}
                cls="app-button-accent"
                itemId="delete"
              >
                Send for approval
              </FooterButton>
            </>
          )}
        </>
      ),
    },
    ConfirmDisableUser: {
      icon: 'negative-circle-flag',
      header: (
        <span>
          Are you sure you want <br />
          to disable account access for:
          <br />
          {accountNameDisable}?
        </span>
      ),
      content:
        'When submitted, this request will be sent for approval to the relevant administrator.',
      footerbutton: (
        <>
          {showGenericButton ? (
            <FooterButton cls="app-button-outline" onClose={onClose}>
              {buttonText.backHome}
            </FooterButton>
          ) : (
            <>
              {/* cancel button  */}
              <FooterButton cls="app-button-outline mr-3" onClose={onClose}>
                {buttonText.cancel}
              </FooterButton>
              {/* remove button  */}
              <FooterButton
                onClose={onClose}
                cls="app-button-accent"
                itemId="delete"
              >
                Send for approval
              </FooterButton>
            </>
          )}
        </>
      ),
    },
  };

  const { icon, header, content, footerbutton } =
    confirmationData[kind] || confirmationData.GenericCopy;

  return (
    <div className="flex flex-col text-primary">
      <div className="flex flex-col items-center mt-16 mb-6 mx-10">
        <AppIcon icon={icon} size="lg" />
        {/* header  */}
        <h3 className="mt-4 mb-5 mt-6 font-bold text-center">{header}</h3>
        {/* content  */}
        <h4 className="text-gray-400 mb-3 text-base text-center">{content}</h4>
        {actionDetails && (
          <h5 className="mt-4 mb-5 mt-6 font-base flex flex-row items-center">
            {actionDetails?.address && (
              <CurrencyIcon
                currencyCode={actionDetails?.currencyCode}
                showLabel={false}
              />
            )}
            <span className="ml-2 font-semibold">
              {actionDetails?.accountNumber || actionDetails?.address}
            </span>
          </h5>
        )}
      </div>
      {/* api errors  */}
      <Error message={error} cls={`mx-10`} />
      {/* actions  */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b mt-4 py-8 px-10">
        {footerbutton}
      </div>
    </div>
  );
};
