import { FC } from 'react';
import cx from 'classnames';

import { DataStore } from '../../store';
import { API } from '@xbto/api-client';
import { Error } from '../common/error';
import { AppIcon } from 'common';

interface Props {
  bankId: string;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteBankAccount: FC<Props> = ({ bankId, onClose, onDelete }) => {
  /**
   * Store
   */
  const error = DataStore.useStoreState(s => s.error);
  const deleteBankAccount = DataStore.useStoreActions(
    a => a.settings.deleteBankAccount
  );
  const deleteBankAccountOperation = DataStore.useStoreActions(
    a => a.settings.deleteBankAccountOperation
  );
  const getBankAccounts = DataStore.useStoreActions(
    a => a.settings.getBankAccounts
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);

  /**
   * Methods
   */
  const handleDelete = async () => {
    const body: API.DeleteBankAccountRequest = {
      bankAccountId: bankId,
    };
    let success = false;
    if (clientUserType === 'admin') {
      success = await deleteBankAccountOperation(body);
    } else {
      success = await deleteBankAccount(body);
    }
    if (!success) {
      return;
    }
    getBankAccounts();
    onDelete();
  };

  /**
   * DOM
   */
  return (
    <div>
      <div className="flex flex-col items-center px-10">
        <AppIcon
          icon="institutions"
          cls="mt-10 mb-4 p-3 justify-center items-center text-secondary"
        />
        <h4 className="text-center">
          Are you sure you want
          <br />
          to delete these bank account details?
        </h4>
        <p className="py-6 text-center text-gray-400">
          Deleting this bank account means you will lose all its <br />
          details. Adding a new bank account will always require <br />
          your verification via email.
        </p>
      </div>

      {/* api errors  */}
      <Error message={error} cls={`mx-10`} />

      {/* actions   */}
      <div className="flex border-t border-grey-bright bg-gray-100 rounded-b py-6 px-10">
        {/* cancel button  */}
        <button
          className="app-button-outline w-full mr-3 flex-1"
          onClick={e => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </button>
        {/* del button  */}
        <button
          type="button"
          className={cx(
            'flex-1 flex items-center justify-center app-button-accent'
          )}
          onClick={e => {
            e.preventDefault();
            handleDelete();
          }}
        >
          Yes, I&apos;m sure
        </button>
      </div>
    </div>
  );
};
