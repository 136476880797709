import { FC, useCallback, useEffect, useState } from 'react';
import { DataStore } from '../../../store';
import {
  COLORS,
  FundDetailsType,
  FundDetailsValue,
  QuorumOperationModalKind,
  useAppDialog,
} from 'common';
import cx from 'classnames';
import { API } from '@xbto/api-client';
import { AppDialog } from 'common';
import { AddCashWorkflow } from '../../../components/workflows/add-cash';
import { ReceiveWorkflow } from '../../../components/workflows/receive';
import { ApprovalConfirmationModal } from '../../../components/approval-confirmation-modal';
import { SummaryApproval } from '../../../components/dialogs/summary-approval';
import { useLocation } from 'react-router-dom';
import { AppLoader } from '~/components/app-loader';

const PendingStatus: FC = () => (
  <span className="font-bold">
    Pending approval{' '}
    <span
      className="rounded-full ml-2 inline-block"
      style={{
        backgroundColor: COLORS.STATUS.WARNING,
        color: 'black',
        width: 10,
        height: 10,
      }}
    ></span>
  </span>
);

const pendingOperationsRequest = {
  page: 1,
  types: [
    API.QuorumOperationKind.ToggleAccountStatus,
    API.QuorumOperationKind.ToggleUserStatus,
  ],
  entityIds: null,
};

export const AdminFundManageFund: FC = () => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.busy);
  const userList = DataStore.useStoreState(s => s.user.userList);
  const listAccountUsers = DataStore.useStoreActions(
    s => s.user.listAccountUsers
  );
  const toggleAccountStateOperation = DataStore.useStoreActions(
    s => s.user.toggleAccountStateOperation
  );
  const toggleUserStateOperation = DataStore.useStoreActions(
    s => s.user.toggleUserStateOperation
  );
  const getPendingOperations = DataStore.useStoreActions(
    s => s.quorum.getPendingOperations
  );
  const fundAccounts = DataStore.useStoreState(s => s.admin.fundAccounts);
  const pendingOperations = DataStore.useStoreState(
    s => s.quorum.pendingOperations
  );
  const globalAppSettings = DataStore.useStoreState(
    a => a.settings.globalAppSettings
  );

  /**
   * Store
   */
  const [account, setAccount] = useState<API.ApexFund | null>(null);
  const [fundDetailsType, setFundDetailsType] =
    useState<FundDetailsType | null>(null);

  /**
   * Hooks
   */
  const location = useLocation();
  const { showDialog, hideDialog } = useAppDialog();
  useEffect(() => {
    if (!location.state) {
      return;
    }

    const fund = location.state as API.ApexFund;
    const fundAccount =
      fundAccounts?.result?.find(a => a.accountId == fund.accountId) || null;
    setAccount(fundAccount);

    listAccountUsers();
    getPendingOperations({
      isBackgroundXHR: true,
      request: pendingOperationsRequest,
    });
  }, [location.state]);

  /**
   * Methods
   */
  const handleDetailsDialog = useCallback(() => {
    setFundDetailsType(null);
  }, [setFundDetailsType]);

  /**
   * DOM
   */
  const isPending = (id: string) =>
    !!pendingOperations?.result?.find(p => p.elementId === id);

  const accountAction = account?.active ? 'Disable' : 'Enable';
  const disableActions =
    globalAppSettings?.userType !== API.UserType.ClientAdminReadWrite || busy;

  if (busy) {
    return <AppLoader isFixed spinnerTop="104px" />;
  }

  return (
    <>
      {/* view bank details dialog */}
      <AppDialog
        isOpen={fundDetailsType === FundDetailsValue.BankDetails}
        onClose={handleDetailsDialog}
      >
        <AddCashWorkflow
          fundDetailsType={fundDetailsType}
          onClose={() => setFundDetailsType(null)}
        />
      </AppDialog>
      {/* view crypto details dialog */}
      <AppDialog
        isOpen={fundDetailsType === FundDetailsValue.CryptoDetails}
        onClose={handleDetailsDialog}
      >
        <ReceiveWorkflow
          fundDetailsType={fundDetailsType}
          onClose={() => setFundDetailsType(null)}
        />
      </AppDialog>
      {/* <AppContainer> */}
      <div className="rounded-4 mt-6 border-1 border-grey-bright bg-white py-8 px-4 md:px-8 text-primary">
        <div className="font-bold">Deposit details</div>
        <hr className="bg-grey-bright my-4" />
        <div className="flex flex-col sm:flex-row items-center gap-6 sm:gap-12 mb-12">
          <div className="w-full sm:w-80 flex flex-col gap-2">
            <span className="text-gray-400">For bank deposits:</span>
            <button
              className="app-button-outline text-sm"
              onClick={() => {
                setFundDetailsType(FundDetailsValue.BankDetails);
              }}
            >
              View bank deposit details
            </button>
          </div>
          <div className="w-full sm:w-80 flex flex-col gap-2">
            <span className="text-gray-400">For crypto deposits:</span>
            <button
              className="app-button-outline text-sm"
              onClick={() => {
                setFundDetailsType(FundDetailsValue.CryptoDetails);
              }}
            >
              View crypto deposit details
            </button>
          </div>
        </div>
        <div className="font-bold">Account access</div>
        <hr className="bg-grey-bright my-4" />
        <div className="flex justify-between mr-3 text-gray-400 mb-4">
          <div>Enable / Disable account access</div>
          <div>Action / Status</div>
        </div>
        <div className="flex justify-between items-center bg-accent-light p-3 mb-8 rounded">
          <div className="font-bold">{accountAction} entire account</div>
          <div>
            {isPending(account?.accountId || '') ? (
              <div className="flex items-center gap-x-4">
                {!account?.active && (
                  <span className="text-gray-400">Currently disabled</span>
                )}
                <PendingStatus />
              </div>
            ) : (
              <div className="flex items-center gap-x-4">
                {!account?.active && (
                  <span className="text-gray-400">Currently disabled</span>
                )}
                <button
                  className={cx('app-button-outline', {
                    success: !account?.active,
                    failure: account?.active,
                  })}
                  disabled={
                    disableActions || isPending(account?.accountId || '')
                  }
                  onClick={() =>
                    showDialog(
                      <ApprovalConfirmationModal
                        kind={QuorumOperationModalKind.ConfirmDisableFund}
                        onClose={async action => {
                          if (action !== 'delete') {
                            hideDialog();
                            return;
                          }
                          await toggleAccountStateOperation(!account?.active);
                          await getPendingOperations({
                            isBackgroundXHR: false,
                            request: pendingOperationsRequest,
                          });
                          showDialog(<SummaryApproval onClose={hideDialog} />);
                        }}
                      />
                    )
                  }
                >
                  {accountAction}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between mr-3 text-gray-400 mb-4">
          <div>Enable / Disable user access</div>
          <div>Action / Status</div>
        </div>
        {userList?.map(user => (
          <div key={user.email}>
            <hr className="bg-grey-bright my-4" />
            <div className="mx-3 flex justify-between items-center">
              <div>{user.firstname + ' ' + user.lastname}</div>
              {isPending(user.userId) ? (
                <PendingStatus />
              ) : (
                <div className="flex items-center gap-x-4">
                  {!user?.active && (
                    <span className="text-gray-400">Currently disabled</span>
                  )}
                  <button
                    className={cx('app-button-outline', {
                      success: !user?.active,
                      failure: user?.active,
                    })}
                    disabled={disableActions}
                    onClick={() =>
                      showDialog(
                        <ApprovalConfirmationModal
                          kind={QuorumOperationModalKind.ConfirmDisableUser}
                          accountNameDisable={
                            user.firstname + ' ' + user.lastname
                          }
                          onClose={async action => {
                            if (action !== 'delete') {
                              hideDialog();
                              return;
                            }
                            await toggleUserStateOperation({
                              userId: user.userId,
                              state: !user?.active,
                            });
                            await listAccountUsers();
                            await getPendingOperations({
                              isBackgroundXHR: true,
                              request: pendingOperationsRequest,
                            });
                            showDialog(
                              <SummaryApproval onClose={hideDialog} />
                            );
                          }}
                        />
                      )
                    }
                  >
                    {user.active ? 'Disable' : 'Enable'} account access
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* </AppContainer> */}
    </>
  );
};
