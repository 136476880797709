import { FC, ChangeEvent } from 'react';
import { Field, FieldProps } from './field';
import { InputProps, TextInput, TextInputProps } from 'common';

type Props = FieldProps & TextInputProps & { uppercase?: boolean };

export const TextField: FC<Props> = ({
  label,
  name,
  onChange: handleChange,
  validator,
  validate,
  uppercase,
  ...rest
}) => {
  return (
    <Field name={name} label={label} validator={validator} validate={validate}>
      {(field, meta, helpers) => (
        <TextInput
          {...field}
          errored={!!meta.error && !!meta.touched}
          {...(rest as TextInputProps & InputProps)}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            if (handleChange) {
              handleChange(ev);
            }
            const value = ev.target.value;
            helpers?.setValue(uppercase ? value.toUpperCase() : value);
          }}
        />
      )}
    </Field>
  );
};
