import cx from 'classnames';
import {
  BankAccountFormikField,
  getAccountNumberLabel,
  getBankIdentifierLabel,
} from 'common';
import { DataStore } from '~/store';
import { TextField } from '../../forms/text-field';
import { API } from '@xbto/api-client';

interface AccountIdentificationProps {
  accountNumberLabel: string;
  bankIdentifierLabel: string;
}

interface IntermediaryAccountInfoProps {
  bankAccountRequirements: API.BankAccountRequirementsOutput | null;
}

const AccountIdentification = ({
  accountNumberLabel,
  bankIdentifierLabel,
}: AccountIdentificationProps) => {
  /**
   * DOM
   */
  return (
    <div className="flex flex-col gap-5 my-5 capitalize">
      {/* bank id  */}
      <div>
        <TextField
          spellCheck={false}
          name={BankAccountFormikField.bankIdentifier}
          label={bankIdentifierLabel}
          placeholder={`Please enter your ${bankIdentifierLabel}`}
          uppercase={bankIdentifierLabel.indexOf('BIC') >= 0}
        />
      </div>
      {/* acc no  */}
      <div>
        <TextField
          spellCheck={false}
          name={BankAccountFormikField.accountNumber}
          label={accountNumberLabel}
          placeholder={`Please enter your ${accountNumberLabel}`}
        />
      </div>
    </div>
  );
};

const BankName = () => {
  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      <TextField
        spellCheck={false}
        name={BankAccountFormikField.bankName}
        label="Bank name"
        placeholder="Please enter the bank name"
      />
    </div>
  );
};

const IntermediaryAccountInfo = ({
  bankAccountRequirements,
}: IntermediaryAccountInfoProps) => {
  /**
   * DOM
   */
  return (
    <div className="flex flex-col">
      <div className={cx('flex flex-col -mx-10 px-10 pb-6')}>
        <div className="flex flex-col flex-1">
          {(bankAccountRequirements?.intermediaryBicSwift ===
            API.BankAccountRequirementType.Required ||
            bankAccountRequirements?.intermediaryBicSwift ===
              API.BankAccountRequirementType.Optional) && (
            <TextField
              name={BankAccountFormikField.intermediaryBankIdentifier}
              label={
                bankAccountRequirements?.intermediaryBicSwift ===
                API.BankAccountRequirementType.Required
                  ? 'Intermediary bank'
                  : 'Intermediary bank (optional)'
              }
              placeholder="Please enter the bank Swift / BIC code"
              uppercase
            />
          )}
        </div>

        {(bankAccountRequirements?.intermediaryAba ===
          API.BankAccountRequirementType.Required ||
          bankAccountRequirements?.intermediaryAba ===
            API.BankAccountRequirementType.Optional) && (
          <div className="flex flex-col flex-1">
            <TextField
              spellCheck={false}
              name={BankAccountFormikField.intermediaryAccountNumber}
              label={
                bankAccountRequirements?.intermediaryAba ===
                API.BankAccountRequirementType.Required
                  ? 'ABA / Routing number'
                  : 'ABA / Routing number (optional)'
              }
              placeholder="Please enter the ABA / Routing number"
            />
          </div>
        )}
      </div>
    </div>
  );

  return null;
};

export const AccountInfo = () => {
  /**
   * Store state
   */
  const bankAccountRequirements = DataStore.useStoreState(
    s => s.settings.bankAccountRequirements
  );

  /**
   * DOM
   */
  const accountNumberLabel = getAccountNumberLabel(bankAccountRequirements);
  const bankIdentifierLabel = getBankIdentifierLabel(bankAccountRequirements);
  return (
    <>
      <BankName />
      <AccountIdentification
        accountNumberLabel={accountNumberLabel}
        bankIdentifierLabel={bankIdentifierLabel}
      />
      <IntermediaryAccountInfo
        bankAccountRequirements={bankAccountRequirements}
      />
    </>
  );
};
