import { memo, useCallback, useState } from 'react';
import cx from 'classnames';
import { StepWizardChildProps } from 'react-step-wizard';
import { useBanners, UserSplashScreen } from 'common';
import CheckBox from 'rc-checkbox';
import { AppHeader } from '../app-header';
import { DataStore } from '~/store';
import { Banner } from '@xbto/universal-components';

export const DismissibleBanner: React.FC<Partial<StepWizardChildProps>> = memo(
  ({ nextStep }) => {
    // Vars

    // TODO(Benoit): pagination when multiple banners? Right now they just loop
    const { banner, handleDismiss: onDismiss } = useBanners(DataStore);

    // Handlers
    const handleDismiss = useCallback((id: string) => {
      onDismiss(id, nextStep);
    }, []);

    // Render
    if (!banner?.text) {
      return null;
    }

    return (
      <Banner
        id={banner.id}
        level={banner.alertLevel ?? undefined}
        message={banner.text}
        onDismiss={banner.dismissable ? handleDismiss : undefined}
        title={banner.title ?? undefined}
        url={banner.linkUrl ?? undefined}
        urlText={banner.linkText ?? undefined}
      />
    );
  }
);

type SplashBannerProps = Partial<StepWizardChildProps & UserSplashScreen> & {
  onDismiss: (id: string, arg?: () => void) => void;
  onAccept: (id: string, arg?: () => void) => void;
};

export const SplashBanner: React.FC<SplashBannerProps> = memo(
  ({ nextStep, onAccept, onDismiss, currentStep, totalSteps, ...rest }) => {
    if (!rest?.id) {
      return null;
    }
    /**
     * Store
     */
    const isLoading = DataStore.useStoreState(s => s.alerts.busy);

    /**
     * State
     */
    const [confirmed, setConfirmed] = useState(!rest.ackText);

    const handleNext = useCallback(() => {
      if (!nextStep || !onAccept || !rest.id) {
        return;
      }
      return onAccept(rest.id, () => {
        if (currentStep !== totalSteps) {
          return nextStep();
        }
      });
    }, [rest.id, nextStep, totalSteps, currentStep, onAccept]);

    const handleClose = useCallback(() => {
      if (!nextStep || !onAccept || !rest.id) {
        return;
      }
      return onDismiss(rest.id, () => {
        if (currentStep !== totalSteps) {
          return nextStep();
        }
      });
    }, [rest.id, nextStep, totalSteps, currentStep, onDismiss]);

    return (
      <>
        <AppHeader splashHeader />
        <div
          className={cx(
            rest.pdfUrl ? 'h-5/6 overflow-scroll' : 'h-full',
            'relative py-2 flex flex-col justify-center flex-1 items-center'
          )}
        >
          <div className="flex-1 h-full max-w-3xl w-full px-4">
            <div className="flex flex-col items-center gap-y-1 flex-1 h-5/6">
              <span className="text-primary typo-b1 font-bold sm:typo-24">
                {rest.title}
              </span>
              <span className="text-primary typo-b3 text-center text-grey-darker">
                {rest.subtitle}
              </span>
              <div className="flex-1 overflow-auto bg-white overflow-x-hidden overflow-y-auto mt-4 rounded-lg w-full">
                {rest.pdfUrl && (
                  <iframe
                    src={`https://docs.google.com/viewer?url=${rest.pdfUrl}&embedded=true`}
                    width="100%"
                    style={{ minHeight: '100%', border: 'none' }}
                  ></iframe>
                )}
                {rest.text && !rest.pdfUrl && (
                  <p className="text-primary p-6 pb-12 text-sm">{rest.text}</p>
                )}
              </div>
            </div>
          </div>

          <div
            className={cx(
              'fixed flex flex-col left-0 right-0 bg-white z-40 bottom-0 w-full bg-grey-brightest gap-2 items-center justify-center'
            )}
          >
            {!!rest.ackText && (
              <label className="flex flex-row gap-2 items-center w-full py-6 px-4 text-sm font-bold text-primary max-w-3xl">
                <CheckBox
                  data-testid="receive-attention-checkbox"
                  className="app-input-checkbox-wrap"
                  checked={confirmed}
                  onChange={() => setConfirmed(arg => !arg)}
                />
                {rest.ackText}
              </label>
            )}
            <span className="flex flex-row gap-2 items-center justify-center border-t w-full py-6 px-10 bg-grey-brighter ">
              {rest.dismissable && (
                <button
                  onClick={() => handleClose()}
                  disabled={
                    isLoading ||
                    (!rest.acceptable && !!rest.ackText ? !confirmed : false)
                  }
                  className="app-button-outline text-sm button-lg"
                >
                  {!rest.acceptable ? `Close` : `Decline`}
                </button>
              )}
              {rest.acceptable && (
                <button
                  disabled={!confirmed || isLoading}
                  onClick={() => handleNext()}
                  className="app-button-accent text-sm"
                >
                  Accept
                </button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  }
);
