import { FC } from 'react';
import { API } from '@xbto/api-client';
import { ERROR_MESSAGES, dataTestIds, Enriched } from 'common';

interface Props {
  data: Enriched.ActivityTransaction;
  showMeta?: boolean;
}

export const CellAmountCurrency: FC<Props> = ({ data }) => {
  /**
   * DOM
   */
  if (!data) {
    return null;
  }

  if (data.type === API.ActivityType.Unknown) {
    return (
      <span
        data-testid={
          dataTestIds.components.transactions.cellActivityAmountCurrency.root
        }
        className="text-sm font-bold"
      >
        {ERROR_MESSAGES.NOT_APPLICABLE}
      </span>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row text-sm font-bold">
        <span>{data.formatted.primaryAmountWithCurrencyCodeAndSign}</span>
      </div>

      {!!data.formatted.secondaryAmountWithCurrencyCodeAndSign && (
        <div className="flex flex-row justify-end md:justify-start text-gray-400 text-xs">
          <span>{data.formatted.secondaryAmountWithCurrencyCodeAndSign}</span>
        </div>
      )}
    </div>
  );
};
