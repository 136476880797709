import { API } from '@xbto/api-client';
import {
  action,
  Action,
  computed,
  Computed,
  effectOn,
  EffectOn,
  thunk,
  Thunk,
} from 'easy-peasy';
import {
  ChartTimeRanges,
  EnrichedCurrencyInformation,
  FundChartTimeRanges,
  IntervalId,
} from '../types';
import {
  AppTenant,
  factories,
  getApiErrorMessage,
  getCurrencyInfoForCode,
  getPercentageChange,
  orderBy,
  StablehousePusherChannel,
  subscribePusher,
} from '../utils';
import { DataModel } from './data-store';
import { AdditionalOptionsXHR, Injections } from './types';
import {
  ChartSeriesType,
  ChartTimeRange,
  TradingViewChartData,
} from '../types/charting';
import {
  getChartingOptions,
  getOhlcvChartingData,
} from '../utils/charting-utils';
import debounce from 'lodash.debounce';
import { SORT_ASSETS_BY, SORT_DIR } from '../config';
import { TIMERS } from '../constants';
import {
  getFlop24hChangeCurrencies,
  getTop24hChangeCurrencies,
  orderCurrenciesBy24hChange,
} from '../utils/order-currencies-by-24h-change';
import { BaseModel, createBaseModel } from './base-store';

/**
 * Note:
 * The intent for this store is to manage both
 * - static data & (eg: currencies)
 * - streaming data (eg: pusher updates for fx rates)
 */
export interface MetaDataModel extends BaseModel {
  // reset
  resetStore: Thunk<MetaDataModel, undefined, Injections, DataModel>;

  // charting meta data
  chartingTimeRanges: Computed<MetaDataModel, ChartTimeRange[]>;

  // <api>/v1/currencies
  _currencies: API.CurrencyInformation[] | null;
  _setCurrencies: Action<MetaDataModel, API.CurrencyInformation[] | null>;
  getCurrencies: Thunk<
    MetaDataModel,
    {
      tenant: AppTenant | undefined;
    } & AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<void>
  >;
  currencies: Computed<MetaDataModel, EnrichedCurrencyInformation[]>;
  _currenciesBy24hChange: Computed<
    MetaDataModel,
    EnrichedCurrencyInformation[]
  >;
  topGainers: Computed<MetaDataModel, EnrichedCurrencyInformation[]>;
  topLosers: Computed<MetaDataModel, EnrichedCurrencyInformation[]>;
  currencyCodes: Computed<MetaDataModel, string[]>;
  fiatCurrencyCodes: Computed<MetaDataModel, string[]>;

  withdrawableCurrencies: Computed<
    MetaDataModel,
    EnrichedCurrencyInformation[]
  >;
  withdrawableFiatCurrencies: Computed<
    MetaDataModel,
    EnrichedCurrencyInformation[]
  >;

  // <api>/v1/fx-rates
  _fxRates: API.FxRate[] | null;
  _setFxRates: Action<MetaDataModel, API.FxRate[] | null>;
  getFxRates: Thunk<MetaDataModel, AdditionalOptionsXHR, Injections, DataModel>;

  // pusher - streaming data updates
  _pusherSubscriptions: { (): void }[];
  _setPusherSubscriptions: Action<MetaDataModel, { (): void }[]>;
  connectToStreamingUpdates: Thunk<
    MetaDataModel,
    {
      pusher: any;
      env: string;
      accountIds: string[];
      orgId: string | null | undefined;
      userId: string;
    },
    Injections,
    DataModel
  >;
  disconnectStreamingUpdates: Thunk<
    MetaDataModel,
    undefined,
    Injections,
    DataModel
  >;

  // <api>/v1/ohlcv
  assetOhlcvSeriesTypes: Computed<MetaDataModel, ChartSeriesType[], DataModel>;
  disableAssetOhlcvSelection: Computed<MetaDataModel, boolean>;
  assetOhlcvRatePercentageChangeBasedOnTimeRange: Computed<
    MetaDataModel,
    number | undefined
  >;
  assetOhlcvData: TradingViewChartData | null;
  setAssetOhlcvData: Action<MetaDataModel, TradingViewChartData | null>;
  getAssetOhlcvData: Thunk<
    MetaDataModel,
    {
      currencyCode: string | undefined;
      timeRange: ChartTimeRange | undefined;
      seriesType: ChartSeriesType | undefined;
    },
    Injections,
    DataModel
  >;

  _pollCurrenciesIntervalId: IntervalId | null;
  _setPollCurrenciesIntervalId: Action<MetaDataModel, IntervalId | null>;
  _pollCurrencies: EffectOn<MetaDataModel, DataModel, Injections>;

  staticData: API.StaticDataResponse | null;
  setStaticData: Action<MetaDataModel, API.StaticDataResponse | null>;
  getStaticData: Thunk<
    MetaDataModel,
    AdditionalOptionsXHR,
    Injections,
    DataModel
  >;
}

export const metaDataModel: MetaDataModel = {
  ...createBaseModel(),

  // reset
  resetStore: thunk(actions => {
    actions._setCurrencies(null);
    actions._setFxRates(null);
  }),

  // charting meta data
  chartingTimeRanges: computed(
    [s => s.currencies ?? [], s => s.assetOhlcvData],
    (currencies, ohlcvData) => {
      const isFund = currencies.find(
        c => c?.code === ohlcvData?.currencyCode
      )?.isAssetOfTypeFund;
      return isFund ? FundChartTimeRanges : ChartTimeRanges;
    }
  ),

  // <api>/v1/currencies
  _currencies: null,
  _setCurrencies: action((state, payload) => {
    state._currencies = payload;
  }),
  getCurrencies: thunk(
    async (
      actions,
      { tenant, isBackgroundXHR = false, throwOnError = false },
      { injections, getStoreActions }
    ) => {
      const storeActions = getStoreActions();
      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
          storeActions.setError(null);
        }

        // get -> currencies
        const currenciesResponse =
          await injections.apiClient.getCurrencies(tenant);
        if (!currenciesResponse.isSuccessful) {
          storeActions.setBusy(false);
          if (!isBackgroundXHR) {
            storeActions.setError(currenciesResponse.errorMessage);
          }

          return;
        }
        actions._setCurrencies(currenciesResponse.result);
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
        if (throwOnError) {
          throw error;
        }
      }
    }
  ),
  currencies: computed(
    [s => s._currencies ?? [], s => s._fxRates ?? []],
    (_currencies, _fxRates) => {
      const accumulator: EnrichedCurrencyInformation[] = [];
      const enriched = _currencies.reduce((out, ccy) => {
        out.push(factories.enrichCurrencyInformation(ccy, _fxRates));
        return out;
      }, accumulator);
      return orderBy(enriched, SORT_DIR.ASC, SORT_ASSETS_BY.NAME);
    }
  ),
  _currenciesBy24hChange: computed([s => s.currencies], currencies => {
    return orderCurrenciesBy24hChange(currencies);
  }),
  topGainers: computed([s => s._currenciesBy24hChange], currencies =>
    getTop24hChangeCurrencies(currencies)
  ),
  topLosers: computed([s => s._currenciesBy24hChange], currencies =>
    getFlop24hChangeCurrencies(currencies)
  ),
  withdrawableCurrencies: computed([s => s.currencies], currencies => {
    return currencies.filter(_currency => _currency.withdrawalsAllowed);
  }),
  withdrawableFiatCurrencies: computed(
    [s => s.withdrawableCurrencies],
    currencies => {
      return currencies.filter(_currency => _currency.isAssetOfTypeFiat);
    }
  ),
  currencyCodes: computed([s => s.currencies], currencies => {
    return currencies.map(({ code }) => code);
  }),
  fiatCurrencyCodes: computed([s => s.currencies], currencies => {
    if (!currencies) {
      return [];
    }
    const accumulator: string[] = [];
    return currencies.reduce((out, ccy) => {
      if (!ccy.isAssetOfTypeFiat) {
        return out;
      }
      out.push(ccy.code);
      return out;
    }, accumulator);
  }),

  // <api>/v1/fx-rates
  _fxRates: null,
  _setFxRates: action((state, payload) => {
    state._fxRates = payload;
  }),
  getFxRates: thunk(
    async (
      actions,
      { isBackgroundXHR = false, throwOnError },
      { injections, getStoreActions }
    ) => {
      const storeActions = getStoreActions();
      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
          storeActions.setError(null);
        }

        // fx-rates
        const fxRatesResponse = await injections.apiClient.getFxRates();
        if (fxRatesResponse.isSuccessful && fxRatesResponse.result) {
          actions._setFxRates(fxRatesResponse.result.fxRates);
        }
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
        if (throwOnError) {
          throw error;
        }
      }
    }
  ),

  // pusher - streaming data updates
  _pusherSubscriptions: [],
  _setPusherSubscriptions: action((state, payload) => {
    state._pusherSubscriptions = payload;
  }),

  connectToStreamingUpdates: thunk(
    async (
      actions,
      payload,
      { getStoreActions, getStoreState, injections: { marketsDataAggregator } }
    ) => {
      const isAdminUser = getStoreState().user.clientUserType === 'admin';

      if (isAdminUser) {
        /**
         * Note:
         * In today's implementation `PusherProvider` is initialized as soon as the app bootstraps (both web & mobile)
         *
         * For RJ/ APEX
         * This needs to work differently, if we need to stream pusher updates with a fund admin
         * drills down into the fund view, given pusher context should only bootstrap in that route (web)/ screen navigation (mobile)
         *
         * No one has asked for this functionality, so stopping at this note, rather than making a JIRA ticket
         */
        return;
      }
      const tenant = getStoreState().tenant;
      if (!tenant) {
        return;
      }

      const { pusher, env, orgId, accountIds, userId } = payload;
      if (!pusher || !env) {
        return;
      }
      marketsDataAggregator.intialize({
        pusher,
        env,
        tenant,
      });

      const _oneSecondInMs = 1000;
      const _debounceOptions = {
        leading: true,
        trailing: false,
      };

      const _subscriptions: { (): void | undefined }[] = [];

      const _subscribeForFxRatesPusherUpdates = () => {
        if (!orgId) {
          return;
        }

        const _handler = async (err, data?: API.FxRate[]) => {
          if (!getStoreState().user.isAuthenticated) {
            return;
          }
          if (err || !data || !data.length) {
            console.error(`Pusher: error ${StablehousePusherChannel.FX_RATES}`);
            return;
          }

          actions._setFxRates(data);
          // Note: refresh chart data when FX rates change if in view which uses the chart data
          if (getStoreState().metaData.assetOhlcvData) {
            const currencyCode =
              getStoreState().metaData.assetOhlcvData?.currencyCode;
            const timeRange =
              getStoreState().metaData.assetOhlcvData?.timeRange;
            const seriesType =
              getStoreState().metaData.assetOhlcvData?.seriesType;
            if (!currencyCode || !timeRange || !seriesType) {
              return;
            }
            actions.getAssetOhlcvData({
              currencyCode,
              timeRange,
              seriesType,
            });
          }
        };
        const sub = subscribePusher(
          pusher,
          StablehousePusherChannel.FX_RATES,
          env,
          _handler,
          orgId
        );
        if (sub) {
          _subscriptions.push(sub);
        }
      };

      const _subscribeForAccountsStatusChanged = () => {
        const _hasAccounts = accountIds && accountIds.length > 0;
        if (!_hasAccounts) {
          return;
        }

        const _handler = debounce(
          async (err, data?: API.AccountDetail) => {
            const storeState = getStoreState();
            if (!storeState.user.isAuthenticated) {
              return;
            }
            if (err) {
              console.error(
                `Pusher: error ${StablehousePusherChannel.ACCOUNT_DETAILS_STATUS}`
              );
              return;
            }
            await getStoreActions().alerts.getMessages();
            const currentAccountId =
              storeState.portfolio._accountDetail?.account?.accountId;
            if (
              currentAccountId &&
              currentAccountId === data?.account?.accountId
            ) {
              await getStoreActions().portfolio.getAccountDetail({
                isBackgroundXHR: true,
                accountId: currentAccountId,
              });
            }
          },
          _oneSecondInMs * 10,
          _debounceOptions
        );

        for (const accountId of accountIds) {
          if (!accountId || !accountId.length) {
            return;
          }
          const id = `${accountId}-${userId}`;
          const sub = subscribePusher(
            pusher,
            StablehousePusherChannel.ACCOUNT_DETAILS_STATUS,
            env,
            _handler,
            id
          );
          if (sub) {
            _subscriptions.push(sub);
          }
        }
      };

      const _subscribeForOrgUserBalanceChanged = () => {
        const _handler = debounce(
          async err => {
            if (err) {
              console.error(
                `Pusher: error ${StablehousePusherChannel.USER_BALANCE_ORGANIZATION}`
              );
              return;
            }

            if (!getStoreState().user.isAuthenticated) {
              return;
            }

            const storeActions = getStoreActions();

            /**
             * Note:
             * when `user-balances` changes per account - update
             * - portfolio (always)
             * - asset-holdings (if in focus)
             * - account-detail (if in focus)
             * - account-activities (if in focus)
             */
            await storeActions.portfolio.getPortfolio({
              isBackgroundXHR: true,
            });

            if (getStoreState().portfolio._accountDetail) {
              const accId =
                getStoreState().portfolio._accountDetail?.account?.accountId;
              if (!accId) {
                // ignore pusher for other accounts that are currently not in focus
                return;
              }
              if (!accId || !accId.length || !accountIds.includes(accId)) {
                return;
              }
              await storeActions.portfolio.getAccountDetail({
                isBackgroundXHR: true,
                accountId: accId,
              });

              if (getStoreState().client === 'web') {
                storeActions.transactions.getPaginatedActivities({
                  request:
                    getStoreState().transactions.paginatedActivitiesFilters,
                  accountId: accId,
                  clearError: false,
                  isBackgroundXHR: true,
                });
              }
              if (getStoreState().client === 'mobile') {
                storeActions.transactions.getInfiniteScrollActivities({
                  request: {
                    ...getStoreState().transactions.paginatedActivitiesFilters,
                    // Always fetch first page of whatever filters are applied
                    // We want to see the latest at the top of the infinite scroll
                    page: 1,
                  },
                  accountId: accId,
                  isBackgroundXHR: true,
                });
              }
            }

            if (getStoreState().portfolio._assetHoldings) {
              const ccyCode =
                getStoreState().portfolio._assetHoldings?.currency?.code;
              if (!ccyCode) {
                return;
              }
              await storeActions.portfolio.getAssetHoldings({
                currencyCode: ccyCode,
                isBackgroundXHR: true,
              });
            }
          },
          _oneSecondInMs,
          _debounceOptions
        );
        const sub = subscribePusher(
          pusher,
          StablehousePusherChannel.USER_BALANCE_ORGANIZATION,
          env,
          _handler,
          payload.orgId || ''
        );
        if (sub) {
          _subscriptions.push(sub);
        }
      };

      _subscribeForFxRatesPusherUpdates(); // channel: public-fx-rates
      _subscribeForAccountsStatusChanged(); // channel: private-account-details-status
      _subscribeForOrgUserBalanceChanged(); // channel: private-user-balances-org

      actions._setPusherSubscriptions(_subscriptions);
      getStoreActions().setBootedPusher(true);
    }
  ),
  disconnectStreamingUpdates: thunk(
    async (
      _actions,
      _payload,
      { getState, getStoreActions, injections: { marketsDataAggregator } }
    ) => {
      const state = getState();
      if (state._pusherSubscriptions.length <= 0) {
        return;
      }
      for (const unSub of state._pusherSubscriptions) {
        unSub();
      }

      getStoreActions().setBootedPusher(false);
      marketsDataAggregator.flush();
      console.info(`pusher - unsub - from all channels`);
    }
  ),

  // <api>/v1/ohlcv
  assetOhlcvSeriesTypes: computed(
    [
      state => state.assetOhlcvData,
      (_s, storeState) => storeState.metaData.currencies,
    ],
    (_assetOhlcvData, _currencies) => {
      const defaultValues = Object.values(ChartSeriesType);
      if (!_currencies || !_currencies.length) {
        return defaultValues;
      }
      const ccyInfo = _currencies.find(
        ccy => ccy.code === _assetOhlcvData?.currencyCode
      );
      if (!ccyInfo) {
        return defaultValues;
      }
      if (ccyInfo.isAssetOfTypeFund) {
        return [ChartSeriesType.CandleStick];
      }
      return defaultValues;
    }
  ),
  disableAssetOhlcvSelection: computed(
    [state => state.assetOhlcvData, state => state.busy],
    (data, busy) => {
      return (
        busy ||
        !(
          data &&
          !data.errored &&
          data.areaChartDataPoints &&
          data.candleStickChartDataPoints
        )
      );
    }
  ),
  assetOhlcvRatePercentageChangeBasedOnTimeRange: computed(
    [state => state.assetOhlcvData],
    data => {
      const firstItemValue = data?.areaChartDataPoints?.at(0)?.value;
      const lastItemValue =
        data?.areaChartDataPoints?.[data?.areaChartDataPoints?.length - 1]
          ?.value;
      if (!firstItemValue || !lastItemValue) {
        return undefined;
      }
      const percentage = getPercentageChange(firstItemValue, lastItemValue);
      // console.log(firstItemValue, lastItemValue, percentage);
      return percentage;
    }
  ),
  assetOhlcvData: null,
  setAssetOhlcvData: action((state, payload) => {
    state.assetOhlcvData = payload;
  }),
  getAssetOhlcvData: thunk(
    async (
      actions,
      { currencyCode, timeRange, seriesType },
      { injections, getStoreState, getState }
    ) => {
      actions.setBusy(true);

      try {
        if (getState().assetOhlcvData?.currencyCode !== currencyCode) {
          actions.setAssetOhlcvData(null);
        }

        const options = getChartingOptions(
          timeRange,
          'asset',
          currencyCode
        ) as API.OhlcvsRequest;
        const { isSuccessful, result } =
          await injections.apiClient.getOhlcvs(options);
        if (!isSuccessful) {
          actions.setAssetOhlcvData({
            priceAxisTitle: '',
            currencyCode,
            timeRange,
            areaChartDataPoints: null,
            candleStickChartDataPoints: null,
            seriesType,
            errored: true,
          });
          return;
        }
        const storeState = getStoreState();
        const state = getState();
        const ccyInfo = getCurrencyInfoForCode(
          currencyCode,
          storeState.metaData.currencies
        );
        const priceAxisTitle = ccyInfo?.isAssetOfTypeFund
          ? 'Growth of 100 units'
          : '';

        const chartData = getOhlcvChartingData(
          result,
          ccyInfo?.fxRate.rate,
          state.fiatCurrencyCodes,
          ccyInfo?.isAssetOfTypeFund
        );
        const data: TradingViewChartData = {
          priceAxisTitle,
          currencyCode,
          timeRange,
          areaChartDataPoints: chartData?.areaChartDataPoints || null,
          candleStickChartDataPoints:
            chartData?.candleStickChartDataPoints || null,
          decimalPrecision: chartData?.decimalPrecision,
          errored: false,
          seriesType,
        };
        actions.setAssetOhlcvData(data);
      } catch (error) {
        console.error(error);
        actions.setAssetOhlcvData({
          priceAxisTitle: '',
          currencyCode,
          timeRange,
          areaChartDataPoints: null,
          candleStickChartDataPoints: null,
          seriesType,
          errored: true,
        });
      } finally {
        actions.setBusy(false);
      }
    }
  ),

  _pollCurrenciesIntervalId: null,
  _setPollCurrenciesIntervalId: action((state, payload) => {
    state._pollCurrenciesIntervalId = payload;
  }),
  _pollCurrencies: effectOn(
    [state => state._currencies],
    (actions, change, { getState, getStoreState }) => {
      const state = getState();
      const currentIntervalId = state._pollCurrenciesIntervalId;

      const [oldValue] = change.prev;
      const [newValue] = change.current;

      if (newValue === null) {
        if (currentIntervalId !== null) {
          clearInterval(currentIntervalId);
          actions._setPollCurrenciesIntervalId(null);
        }
      } else if (oldValue === null) {
        const storeState = getStoreState();
        const tenant = storeState.tenant ?? undefined;

        const newIntervalId = setInterval(() => {
          actions.getCurrencies({
            isBackgroundXHR: true,
            tenant,
            throwOnError: false,
          });
        }, TIMERS.POLL_CURRENCIES);

        actions._setPollCurrenciesIntervalId(newIntervalId);
      }

      return () => {
        if (currentIntervalId) {
          clearInterval(currentIntervalId);
          actions._setPollCurrenciesIntervalId(null);
        }
      };
    }
  ),

  staticData: null,
  setStaticData: action((state, payload) => {
    state.staticData = payload;
  }),
  getStaticData: thunk(
    async (
      actions,
      { isBackgroundXHR, throwOnError },
      { injections, getStoreActions }
    ) => {
      const storeActions = getStoreActions();
      try {
        if (!isBackgroundXHR) {
          storeActions.setBusy(true);
          storeActions.setError(null);
        }
        const { isSuccessful, result } =
          await injections.apiClient.getStaticData();
        if (!isSuccessful) {
          actions.setStaticData(null);
          return;
        }
        actions.setStaticData(result);
        storeActions.setBusy(false);
      } catch (error) {
        actions.setStaticData(null);
        const message = getApiErrorMessage(error);
        if (!isBackgroundXHR) {
          storeActions.setError(message);
        }
        if (throwOnError) {
          throw error;
        }
      }
    }
  ),
};
