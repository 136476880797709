import { API } from '@xbto/api-client';
import {
  Enriched,
  EnrichedCurrencyInformation,
  getCurrencyInfoForCode,
} from 'common';
import { ActivityAmount, ActivityHash, ActivityNetwork } from '../shared';
import { ActivityDate } from '../shared/activity-date';
import {
  CellActivityStatus,
  CellTransferAccount,
} from '~/components/transactions/cells';

export const getReceiveRecords = (
  data: API.Deposit,
  status: string,
  currencies: EnrichedCurrencyInformation[],
  account: Enriched.ListAccountItem | undefined
) => {
  const ccyInfo = getCurrencyInfoForCode(data.currencyCode, currencies);
  return {
    '': <CellActivityStatus {...data} status={status} />,
    To: <ActivityHash hash={data.destinationAddress} />,
    Network: <ActivityNetwork ccyInfo={ccyInfo} />,
    Receive: (
      <ActivityAmount
        sign="+"
        amount={data.amount}
        code={ccyInfo?.displayCode}
        decimals={ccyInfo?.decimals}
        meta={<CellTransferAccount data={account} />}
      />
    ),
    'Reference code': <ActivityHash hash={data.humanReadableIdentifier} />,
    'Transaction hash': <ActivityHash hash={data.txId} />,
    Date: <ActivityDate date={data.creationDate} />,
  };
};
