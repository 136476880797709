import { FC } from 'react';
import cx from 'classnames';
import { API } from '@xbto/api-client';

interface Props {
  status: API.QuorumOperationStatus | null;
  cls?: string;
  hideLabel?: boolean;
  hideIcon?: boolean;
  margin?: string;
}

export const QuorumStatus: FC<Props> = ({
  status,
  cls,
  margin = 'mt-0',
  hideLabel = false,
  hideIcon = false,
}) => {
  /**
   * DOM
   */
  const accountStatus: { [index: string]: string } = {
    WaitingForApproval: 'Pending',
    Executed: 'Approved',
    Cancelled: 'Cancelled',
    Failed: 'Rejected',
  };

  if (!status) return null;

  return (
    <div className={cx(cls)}>
      <div className="overflow-hidden truncate flex flex-rows items-center">
        {!hideLabel && <span>{accountStatus[status]}</span>}
        {!hideIcon && (
          <span
            className={cx(
              `lock w-3 h-3 bg-black ml-2 rounded-full`,
              margin,
              {
                'bg-warning':
                  status === API.QuorumOperationStatus.WaitingForApproval &&
                  'Pending',
              },
              {
                'bg-success': status === API.QuorumOperationStatus.Executed,
              },
              {
                'bg-error': status === API.QuorumOperationStatus.Failed,
              }
            )}
          ></span>
        )}
      </div>
    </div>
  );
};
