import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { createStyles } from '../../theme';
import { TextBody } from '../text';
export function RadioButton({ onPress, title, value, style, }) {
    /**
     * Methods
     */
    const handlePress = useCallback(() => {
        onPress === null || onPress === void 0 ? void 0 : onPress(value);
    }, [onPress, value]);
    /**
     * DOM
     */
    return (_jsx(Pressable, Object.assign({ onPress: handlePress, style: ({ pressed, hovered }) => [
            $.container,
            (pressed || hovered) && $.active,
            style,
        ] }, { children: ({ pressed, hovered }) => {
            const texColor = pressed || hovered ? 'onPrimarySurface' : undefined;
            return (_jsx(TextBody, Object.assign({ em: "600", color: texColor, center: true }, { children: title })));
        } })));
}
const $ = createStyles({
    container: {
        borderRadius: 'rad1',
        backgroundColor: 'surfaceAlt',
        p: 'm',
        justifyContent: 'center',
        alignContent: 'center',
    },
    active: {
        backgroundColor: 'primarySurface',
    },
});
