import { COLORS } from '../constants';

export const getActivityStatusColor = (status: string) => {
  const colors: Record<
    'success' | 'failure' | 'warning',
    {
      fg: string;
      bg: string;
    }
  > = {
    success: {
      fg: 'white',
      bg: COLORS.STATUS.SUCCESS,
    },
    failure: {
      fg: 'white',
      bg: COLORS.STATUS.FAILURE,
    },
    warning: {
      fg: 'black',
      bg: COLORS.STATUS.WARNING,
    },
  };

  if (status === 'Confirmed') {
    return colors.success;
  }
  if (status === 'Cancelled') {
    return colors.failure;
  }
  return colors.warning;
};
