var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { API } from '@xbto/api-client';
import { useMemo } from 'react';
import { SortableHeaders, } from '../sortable-headers';
export function SortableHeader(_a) {
    var { defaultSortDir = API.SortDirection.Descending, id, label } = _a, rest = __rest(_a, ["defaultSortDir", "id", "label"]);
    // Vars
    const items = useMemo(() => {
        return [{ id, label, defaultSortDir }];
    }, [defaultSortDir, id, label]);
    // Render
    return _jsx(SortableHeaders, Object.assign({}, rest, { items: items }));
}
