import { DataStoreType } from '../store';
import { dateRanges } from '../utils';
import { Enriched } from '../types';
import { useCallback, useMemo, useState } from 'react';

export interface ListFilters {
  dateRange: string;
  startDate: Date | null;
  endDate: Date | null;
  accountId: string;
}

export const useStatementsFilters = (
  DataStore: DataStoreType,
  onSubmit?: (f?: ListFilters) => void,
  onReset?: () => void,
  initialFilters?: Partial<ListFilters>
) => {
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const filters = DataStore.useStoreState(s => s.statements.filters);
  const findAccount = DataStore.useStoreState(s => s.portfolio.findAccount);

  const { getList, setFilters, resetFilters } = DataStore.useStoreActions(
    a => ({
      getList: a.statements.getList,
      setFilters: a.statements.setFilters,
      resetFilters: a.statements.resetFilters,
    })
  );

  // State
  const [accountId, setAccountId] = useState<string | null>(
    (initialFilters?.accountId || filters?.accountId) ?? null
  );
  const [startDate, setStartDate] = useState<Date | null>(
    (initialFilters?.startDate || filters?.startDate) ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    (initialFilters?.endDate || filters?.endDate) ?? null
  );
  const [dateRangeId, setDateRangeId] = useState<string | null>(
    (initialFilters?.dateRange || filters?.dateRange) ?? null
  );

  // Vars
  const dateRangeValues = useMemo(() => {
    return Object.values(dateRanges).map(dr => ({
      id: dr.text,
      label: dr.text,
      value: [dr.startDate, dr.endDate],
    }));
  }, []);

  const account = useMemo(() => {
    return accounts.find(_account => _account.account?.accountId === accountId);
  }, [accountId, accounts]);

  // Handlers
  const handleChangeAccount = useCallback(
    (account: Enriched.ListAccountItem | string) => {
      if (typeof account === 'string') {
        setAccountId(
          findAccount(accounts, account)?.account?.accountId ?? null
        );
      } else {
        setAccountId(account.account?.accountId ?? null);
      }
    },
    [accounts, findAccount]
  );

  const handleChangeDateRange = useCallback(
    (id: string) => {
      if (id === dateRangeId) {
        setDateRangeId(null);
        setStartDate(null);
        setEndDate(null);
      } else {
        const value = dateRangeValues.find(_dateRange => _dateRange.id === id);

        if (value) {
          setDateRangeId(id);
          setStartDate(value.value[0]);
          setEndDate(value.value[1]);
        }
      }
    },
    [dateRangeId, dateRangeValues]
  );

  const handleReset = useCallback(() => {
    setAccountId(null);
    setStartDate(null);
    setEndDate(null);
    setDateRangeId(null);

    resetFilters();

    getList({
      limit: null,
      startDate: null,
      endDate: null,
      accountId: null,
      assetCode: null,
    });
    onReset?.();
  }, [getList, resetFilters, onReset]);

  const handleSubmit = useCallback(() => {
    const newFilters = {
      dateRange: dateRangeId ?? '',
      startDate: startDate || null,
      endDate: endDate || null,
      accountId: accountId ?? '',
    };

    setFilters(newFilters);

    getList({
      limit: null,
      startDate,
      endDate,
      accountId,
      assetCode: null,
    });
    onSubmit?.(newFilters);
  }, [
    setFilters,
    dateRangeId,
    startDate,
    endDate,
    accountId,
    getList,
    onSubmit,
  ]);

  return {
    handleReset,
    handleSubmit,
    handleChangeDateRange,
    handleChangeAccount,
    accounts,
    account,
    accountId,
    startDate,
    endDate,
    dateRangeId,
    dateRangeValues,
  };
};
