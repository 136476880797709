import { API } from '@xbto/api-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import _memoize from 'lodash/memoize';
import { DataStoreType } from '../../../store';
import { TIMERS } from '../../../constants';
import {
  SendDestinationType,
  SendForm,
  validateSendForm,
} from '../../../utils';
import { EnrichedCryptoAddress } from '../../../types';
import { compareFormValues, FormErrors } from '../../../utils/form';

// Local _specific_ "Is destination a SH username?" logic
function isDestinationUsername(_destination: string) {
  // Cannot use REGEX.STABLEHOUSE_TAG cause it's returning `false` for `@a` & `@an` values.
  return _destination.indexOf('@') === 0;
}

interface Options {
  apiClient: API.StablehouseClient;
  DataStore: DataStoreType;
}

export function useSendForm({ apiClient, DataStore }: Options) {
  /**
   * Usage check
   */
  if (!DataStore) {
    throw new Error(
      `Hook: useSendForm can only be used inside the DataStore.Provider`
    );
  }

  /**
   * Store
   */
  const asset = DataStore.useStoreState(s => s.send.asset);
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const allowedAssets = DataStore.useStoreState(s => s.send.allowedAssets);
  const error = DataStore.useStoreState(s => s.send.error);
  const formInitialValues = DataStore.useStoreState(
    s => s.send.formInitialValues
  );
  const formValues = DataStore.useStoreState(s => s.send.formValues);
  const formValuesTouched = DataStore.useStoreState(
    s => s.send.formValuesTouched
  );
  const formErrors = DataStore.useStoreState(s => s.send.formErrors);
  const simulation = DataStore.useStoreState(s => s.send.simulation);
  const validatedAddress = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.validatedAddress
  );
  const withdrawalAddress = DataStore.useStoreState(
    s => s.send.withdrawalAddress
  );
  const withdrawalAddresses = DataStore.useStoreState(
    // Note: These are only the addresses of current asset
    s => s.send.withdrawalAddresses
  );
  const isSendLoading = DataStore.useStoreState(s => s.send.busy);
  const addressBook = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.verifiedWithdrawalAddresses
  );
  const isCryptoAddressesLoading = DataStore.useStoreState(
    s => s.settings.cryptoAddresses.busy
  );
  const withdrawalRequirements = DataStore.useStoreState(
    s => s.send.withdrawalRequirements
  );

  const {
    create,
    getWithdrawalAddresses,
    resetForm,
    setError,
    setFormErrors,
    setFormValues,
    setFormValueTouched,
    setSimulation,
    setValidatedAddress,
    simulate,
    validateAddress,
  } = DataStore.useStoreActions(a => ({
    create: a.send.create,
    getWithdrawalAddresses: a.settings.cryptoAddresses.getWithdrawalAddresses,
    resetForm: a.send.resetForm,
    setError: a.send.setError,
    setFormErrors: a.send.setFormErrors,
    setFormValues: a.send.setFormValues,
    setFormValueTouched: a.send.setFormValueTouched,
    setSimulation: a.send.setSimulation,
    setValidatedAddress: a.settings.cryptoAddresses.setValidatedAddress,
    simulate: a.send.simulate,
    validateAddress: a.settings.cryptoAddresses.validateAddress,
  }));

  /**
   * State
   */
  const [isValidating, setValidating] = useState(false);

  /**
   * Vars
   */
  const accountId = accountDetail?.account?.accountId;
  const blockchainCode = asset?.currency.blockchain;

  const isValid = useMemo(() => {
    return !Object.keys(formErrors).length;
  }, [formErrors]);

  const errors = useMemo(() => {
    const result: FormErrors<SendForm> = {};

    for (const key in formErrors) {
      if (formValuesTouched[key]) {
        // Only show errors of touched inputs
        result[key] = formErrors[key];
      }
    }

    return result;
  }, [formErrors, formValuesTouched]);

  /**
   * Methods
   */
  const touch = _memoize((name: keyof SendForm) => {
    return () => {
      // Note: Capture `name` var in closure
      setFormValueTouched(name);
    };
  });

  const debouncedSimulate = useMemo(
    () => _debounce(simulate, TIMERS.SIMULATE_DEBOUNCE),
    [simulate]
  );

  const debouncedValidateAddress = useMemo(
    () =>
      _debounce((address: string, blockchainCode: string) => {
        return validateAddress({ address, blockchainCode });
      }, TIMERS.SIMULATE_DEBOUNCE),
    [validateAddress]
  );

  /**
   * Handlers
   */
  const validate = useCallback(
    async (newValues: SendForm) => {
      if (accountId) {
        setValidating(true);

        const newErrors = await validateSendForm(
          newValues,
          asset,
          apiClient,
          accountId,
          !accountDetail?.canWithdrawToNonWhitelistedAddress
        );

        const isValid = !Object.keys(newErrors).length;

        setFormErrors(newErrors);

        setValidating(false);

        return isValid;
      }

      return false;
    },
    [
      accountDetail?.canWithdrawToNonWhitelistedAddress,
      accountId,
      apiClient,
      asset,
      setFormErrors,
    ]
  );

  const onChange = useCallback(
    async (newValues: SendForm) => {
      // Reset
      setError(null);

      // Always cancel latest
      debouncedSimulate.cancel();

      const oldValues = formValues; // alias
      const changed = compareFormValues(oldValues, newValues);

      if (changed.destination || changed.withdrawalAddressId) {
        setValidatedAddress(null);
      }

      setFormValues(newValues);

      const isValid = await validate(newValues);

      // Simulate again ?
      const reSimulate =
        changed.currencyCode ||
        changed.amount ||
        changed.destination ||
        changed.withdrawalAddressId;

      if (isValid && reSimulate) {
        setSimulation(null);

        debouncedSimulate();
      }
    },
    [
      debouncedSimulate,
      formValues,
      setError,
      setFormValues,
      setSimulation,
      setValidatedAddress,
      validate,
    ]
  );

  const setAmount = useCallback(
    (value: string) => {
      onChange({ ...formValues, amount: value });
    },
    [formValues, onChange]
  );

  const setCurrencyCode = useCallback(
    (value: string) => {
      // Reset (start flow)
      resetForm();
      setValidatedAddress(null);
      if (asset?.currency.code !== value) {
        setSimulation(null);
      }

      const newValues = {
        ...formInitialValues,
        currencyCode: value,
      };
      const newAsset = allowedAssets.find(
        _asset => _asset.currency.code === value
      );

      if (newAsset) {
        const hasAddress = addressBook.some(
          _address => _address.currencyCode === newAsset.currency.code
        );

        newValues.destinationType = hasAddress
          ? SendDestinationType.book
          : SendDestinationType.crypto;

        newValues.network = newAsset.currency.network;
      }

      onChange(newValues);
    },
    [
      resetForm,
      setValidatedAddress,
      formInitialValues,
      allowedAssets,
      onChange,
      addressBook,
      asset?.currency.code,
      setSimulation,
    ]
  );

  const setDescription = useCallback(
    (value: string) => {
      onChange({ ...formValues, description: value });
    },
    [formValues, onChange]
  );

  const setDestination = useCallback(
    (value: string) => {
      const newValues = {
        ...formValues,
        destination: value,
        withdrawalAddressId: null,
      };

      if (value) {
        newValues.destinationType = isDestinationUsername(value)
          ? SendDestinationType.transfer
          : SendDestinationType.crypto;
      }

      onChange(newValues);

      debouncedValidateAddress.cancel();

      if (value && blockchainCode) {
        debouncedValidateAddress(value, blockchainCode);
      }
    },
    [blockchainCode, debouncedValidateAddress, formValues, onChange]
  );

  const setDestinationType = useCallback(
    (value: SendDestinationType) => {
      onChange({
        ...formValues,
        destination: '',
        destinationType: value,
        withdrawalAddressId: null,
      });
    },
    [formValues, onChange]
  );

  const setWithdrawalAddress = useCallback(
    (address: EnrichedCryptoAddress) => {
      onChange({
        ...formValues,
        currencyCode: address.currencyCode,
        destination: address.address,
        destinationType: SendDestinationType.book,
        withdrawalAddressId: address.id,
      });
    },
    [formValues, onChange]
  );

  const setWithdrawalAddressId = useCallback(
    (value: string | null) => {
      const _withdrawalAddress = withdrawalAddresses.find(
        _address => _address.id === value
      );

      if (_withdrawalAddress) {
        setWithdrawalAddress(_withdrawalAddress);
      }
    },
    [setWithdrawalAddress, withdrawalAddresses]
  );

  const submit = useCallback(() => {
    setError(null);

    return create();
  }, [create, setError]);

  /**
   * Effects
   */
  useEffect(() => {
    // Init
    if (accountId) {
      getWithdrawalAddresses({ accountId });
    }
  }, [getWithdrawalAddresses, accountId]);

  useEffect(() => {
    if (
      validatedAddress?.normalized &&
      validatedAddress.normalizedAddress &&
      validatedAddress.normalizedAddress !== formValues.destination
    ) {
      // Override User input
      setFormValues({
        ...formValues,
        destination: validatedAddress.normalizedAddress,
      });
    }
  }, [formValues, setFormValues, validatedAddress]);

  /**
   * Public API
   */
  const _isLoading = isSendLoading || isCryptoAddressesLoading || isValidating;
  const _isPreviewDisabled =
    !!error ||
    _isLoading ||
    !isValid ||
    withdrawalRequirements === null ||
    (formValues.destinationType === SendDestinationType.crypto && !simulation);
  return {
    asset,
    create,
    error,
    errors,
    values: formValues,
    isLoading: _isLoading,
    isValid,
    isPreviewDisabled: _isPreviewDisabled,
    isWithdrawalAddress: !!withdrawalAddress,
    setAmount,
    setCurrencyCode,
    setDescription,
    setDestination,
    setDestinationType,
    setWithdrawalAddress,
    setWithdrawalAddressId,
    submit,
    simulation,
    touch,
    validatedAddress,
    withdrawalAddress,
    withdrawalAddresses,
    collectKyt:
      !withdrawalRequirements?.isValid &&
      !withdrawalRequirements?.organizationTravelRuleAddressInfoId &&
      !!withdrawalRequirements?.dataRequired,
  };
}
