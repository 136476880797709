import numbro from 'numbro';
import esES from 'numbro/languages/es-ES';
import frFR from 'numbro/languages/fr-FR';
import numberParse from 'multi-number-parse';
import { DEFAULTS, FIAT_SYMBOLS } from '../constants';
import { getFallbackMantissa } from './format-amount';

type CurrencyFormatOptions = Partial<numbro.Format> & {
  fiat?: boolean;
  decipherPrecision?: boolean;
};

numbro.registerLanguage(esES);
numbro.registerLanguage(frFR);

const _setLocale = (locale: string) => {
  numbro.setLanguage(locale, 'en-US');
};

const _formatDefaults: numbro.Format = {
  thousandSeparated: true,
  mantissa: DEFAULTS.DECIMAL_SCALE,
  trimMantissa: true, // Note: Product requirement to trim trailing zeros - got from @dipen
};

const _getAmount = (
  amount: string | number | undefined | null,
  decimals: number | undefined,
  isFiat = false,
  { trimMantissa, ...options }: Partial<numbro.Format> = {}
) => {
  const value = numberParse(amount ?? '0');

  return numbro(value).format({
    ..._formatDefaults,
    ...(options ?? {}),
    mantissa: decimals,
    trimMantissa: typeof trimMantissa !== 'undefined' ? trimMantissa : !isFiat,
  });
};

const _getCurrency = (
  amount: string | number | undefined | null,
  decimals: number | undefined,
  ccyCode: string,
  fiatCurrencyCodes: string[],
  preferCurrencyCode = false,
  { fiat, decipherPrecision = false, ...options }: CurrencyFormatOptions = {}
) => {
  const value = numberParse(amount || 0);
  // TODO: Remove `fiatCurrencyCodes` dependency, FIAT_SYMBOLS should be enough to know "if fiat" (can hardcode list: ['USD', 'EUR', ...])
  const isFiat = fiat === true || fiatCurrencyCodes.includes(ccyCode);
  const fiatSymbol = FIAT_SYMBOLS[ccyCode];
  const _preferCurrencyCode = !isFiat || !fiatSymbol || preferCurrencyCode;
  const currencySymbol = _preferCurrencyCode ? ccyCode : fiatSymbol || ccyCode; // TODO: Should default to `displayCode`

  if (isFiat && !fiatSymbol) {
    // Note: should enhance this to a granular throw - for future
    // BMD, CAD, CHF, JPY, XAU
    // console.info(`Bug: FIAT_SYMBOLS enumeration does not cater for ${ccyCode}`);
  }

  const fallbackMatissa = getFallbackMantissa(value);
  const mantissa =
    typeof decimals !== 'undefined'
      ? decimals
      : decipherPrecision
        ? fallbackMatissa
        : isFiat
          ? DEFAULTS.DECIMAL_SCALE
          : fallbackMatissa;
  const shouldPrefix = isFiat && !preferCurrencyCode;

  return numbro(value).formatCurrency({
    ..._formatDefaults,
    ...(options ?? {}),
    mantissa: options?.average ? 1 : mantissa,
    trimMantissa: false,
    currencyPosition: shouldPrefix ? 'prefix' : 'postfix',
    currencySymbol,
    spaceSeparatedCurrency: !shouldPrefix,
  });
};

const _forceSign = (value: string, sign: string): string => {
  return `${sign}${value}`;
};

function getFiatAmount(value: string | number | undefined | null) {
  return _getAmount(value, DEFAULTS.DECIMAL_SCALE, true);
}

export const formatters = {
  setLocale: _setLocale,
  getAmount: _getAmount,
  getFiatAmount,
  getCurrency: _getCurrency,
  forceSign: _forceSign,
};
