import { API } from '@xbto/api-client';
import { Enriched } from '../../types';
import { formatters } from '../formatters';
import numberParse from 'multi-number-parse';
import { formatMoney } from '../format-money';
import { activitySign } from '../get-activity-sign';

export const enrichFiatDeposit = (
  deposit: API.FiatDeposit | null,
  fiatCurrencyCodes: string[]
): Enriched.FiatDeposit | null => {
  if (!deposit || !deposit.amount || !deposit.currency?.code) {
    return null;
  }

  const amountSent = `${numberParse(deposit.amount)}`;
  const amountWithCurrencyCode = formatters.getCurrency(
    amountSent,
    deposit.currency.decimals,
    deposit.currency.displayCode ?? deposit.currency.code,
    fiatCurrencyCodes,
    true
  );

  return {
    ...deposit,
    formatted: {
      amount: formatters.getAmount(deposit.amount, deposit.currency.decimals),
      amountWithCurrencyCode,
      amountUsdWithCurrencyCode: formatMoney(amountSent, 'USD'),
      amountWithCurrencyCodeAndSign: formatters.forceSign(
        amountWithCurrencyCode,
        activitySign[API.ActivityType.FiatDeposit]
      ),
    },
  };
};
