import { API } from '@xbto/api-client';
import { ERROR_MESSAGES, REGEX } from '../../../constants';
import memoize from 'memoizee';
import { RegisterFormikProps, RegisterFormField } from './types';

const isRequired = (
  prop: RegisterFormField,
  value: string | null,
  errors: Record<string, unknown>
) => {
  if (!value) {
    errors[prop] = ERROR_MESSAGES.REQUIRED_VALUE;
  }
};

type ValidatePasswordType = (
  password: string
) => Promise<API.PasswordEntropyResponse | null>;

const validate = async (
  values: RegisterFormikProps,
  prevValues: RegisterFormikProps | null,
  validatePassword: ValidatePasswordType
) => {
  const errors: Record<string, unknown> = {};

  isRequired(RegisterFormField.firstName, values.firstName, errors);

  isRequired(RegisterFormField.lastName, values.lastName, errors);

  if (!values.email) {
    errors.email = ERROR_MESSAGES.REQUIRED_VALUE;
  } else if (!REGEX.EMAIL_ADDRESS.test(values.email)) {
    errors.email = ERROR_MESSAGES.INVALID_EMAIL;
  }

  if (
    values.referralCode &&
    !REGEX.PREFINERY_REFERRAL_CODE.test(values.referralCode) &&
    !REGEX.STABLEHOUSE_REFERRAL_CODE.test(values.referralCode)
  ) {
    errors.referralCode = ERROR_MESSAGES.INVALID_REFERRAL_CODE;
  }

  // pwd check
  if (values.password && values.password !== prevValues?.password) {
    const res = await validatePassword(values.password);
    if (res && !res.isValid) {
      errors.password = ERROR_MESSAGES.INVALID_VALUE;
    }
  }

  if (!values.acceptTerms) {
    errors.acceptTerms = ERROR_MESSAGES.REQUIRED_VALUE;
  }
  return errors;
};

export const validateRegisterFormik = memoize(validate);
