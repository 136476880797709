import { API } from '@xbto/api-client';

export function isBankAccountRequirementRequired(
  requirement: API.BankAccountRequirementType | null | undefined
) {
  return requirement === API.BankAccountRequirementType.Required;
}

export function getAccountNumberLabel(
  requirements: API.BankAccountRequirementsOutput | null
) {
  if (isBankAccountRequirementRequired(requirements?.accountNumber)) {
    return 'account number';
  }

  // requirements?.iban = "Required"
  return 'IBAN';
}

export function getBankIdentifierLabel(
  requirements: API.BankAccountRequirementsOutput | null
) {
  if (isBankAccountRequirementRequired(requirements?.aba)) {
    return 'ABA / Routing number';
  }

  if (isBankAccountRequirementRequired(requirements?.sortCode)) {
    return 'sort code';
  }

  // requirements?.bicSwift = "Required"
  return 'BIC / Swift code';
}
