import { API } from '@xbto/api-client';
import { ExportCsv } from './export-csv';
import { useParams } from 'react-router-dom';
import { AppStore, DataStore } from '~/store';
import { TransferFilterFormikProps } from 'common';
import { convertColumnToSortColumn } from './utils';
import { useCallback, useMemo } from 'react';
import { useGetTransactions } from './use-get-transactions';
import { SortDirection, TransactionColumn } from '~/config';
import { useFilterTransactions } from './transactions-filters/use-filter-transactions';
import { handleEmptyData } from '~/utils/handle-empty-data';
import { convertToArray } from '~/utils/convert-to-array';

export const useActivityFilterOptions = () => {
  /**
   * Store
   */
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const defaultAccountId = DataStore.useStoreState(
    s => s.user.defaultAccountId
  );
  const isLoading = DataStore.useStoreState(s => s.transactions.busy);
  const paginatedActivities = DataStore.useStoreState(
    s => s.transactions.paginatedActivities
  );
  const paginatedActivitiesFilters = DataStore.useStoreState(
    s => s.transactions.paginatedActivitiesFilters
  );

  const { getActivityTransaction } = DataStore.useStoreActions(_ => ({
    getActivityTransaction: _.transactions.getActivityTransaction,
  }));
  const { setDashboardSelectedDialogType } = AppStore.useStoreActions(_ => ({
    setDashboardSelectedDialogType: _.setDashboardSelectedDialogType,
  }));

  /**
   * Vars
   */
  const { fundAccountId } = useParams();
  const accountId =
    clientUserType === 'admin'
      ? defaultAccountId
      : accountDetail?.account?.accountId || '';
  const { getTransactions } = useGetTransactions();
  const { formValues, onResetForm, handleApplyFilters } =
    useFilterTransactions();

  const handleGetData = useCallback(async arg => {
    if (!arg || !Object.values(arg).length) {
      return;
    }
    const {
      page,
      dateEnd,
      dateStart,
      sortColumn,
      statusFilter,
      sortDirection,
      activityLabels,
      currencyCodeFilter,
    } = arg;

    const handleDateDate = dateData => (dateData ? new Date(dateData) : null);
    const handleDateStart = handleDateDate(dateStart);
    const handleDateEnd = handleDateDate(dateEnd);

    const reqData = {
      page: page,
      dateEnd: handleDateEnd,
      dateStart: handleDateStart,
      statusFilter: convertToArray(handleEmptyData(statusFilter)),
      activityLabels: convertToArray(handleEmptyData(activityLabels)),
      currencyCodeFilter: convertToArray(handleEmptyData(currencyCodeFilter)),
    };

    handleApplyFilters({
      filterAssetList: reqData.currencyCodeFilter,
      filterStartDate: reqData.dateStart,
      filterEndDate: reqData.dateEnd,
      filterType: reqData.activityLabels,
      filterStatus: reqData.statusFilter,
    } as TransferFilterFormikProps);

    await getTransactions({
      ...reqData,
      sortColumn: convertColumnToSortColumn(
        sortColumn || TransactionColumn.Date
      ),
      sortDirection:
        sortDirection === SortDirection.ASC
          ? API.SortDirection.Ascending
          : API.SortDirection.Descending,
    });
  }, []);

  const handleRowClick = useCallback(
    async (activity: API.ActivityTransaction | null) => {
      if (!activity || (clientUserType === 'admin' && !fundAccountId)) {
        return;
      }
      const { isSuccessful } = await getActivityTransaction({
        activity,
        accountId,
        impersonatedAccountId: fundAccountId,
        silent: true,
      });
      if (isSuccessful) {
        setDashboardSelectedDialogType('transactions-activity-summary');
      }
    },
    [accountId, clientUserType, fundAccountId]
  );

  const acc = useMemo(() => {
    return accounts.find(
      a =>
        a.account?.accountId === fundAccountId ||
        accountDetail?.account?.accountId
    )?.account;
  }, [accounts, fundAccountId, accountDetail?.account?.accountId]);

  const hasData = useMemo(() => {
    return !!paginatedActivities;
  }, [paginatedActivities]);

  /**
   * Methods
   */
  const handleFilters = useCallback(
    (value: boolean) => {
      if (value) {
        onResetForm(formValues);
        setDashboardSelectedDialogType('transactions-activity-filters');
        return;
      }
      setDashboardSelectedDialogType(null);
    },
    [formValues]
  );
  const handleRefresh = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      await getTransactions(paginatedActivitiesFilters);
    },
    [getTransactions, paginatedActivitiesFilters]
  );

  /**
   * Return
   */
  return {
    hasData,
    isLoading,
    handleRowClick,
    paginatedActivities,
    csvButton: (showRefresh: boolean) => (
      <ExportCsv
        account={acc}
        hasData={hasData}
        showRefresh={showRefresh}
        handleRefresh={handleRefresh}
        handleFilters={handleFilters}
      />
    ),
    handleGetData,
  };
};
