export interface Country {
  name: string;
  code: string;
  codeAlt: string;
}

const excludeList = [
  'ALA',
  'ASM',
  'CXR',
  'CCK',
  'ERI',
  'FLK',
  'GUM',
  'KIR',
  'XK',
  'FSM',
  'NCL',
  'NFK',
  'MNP',
  'PCN',
  'PRI',
  'BLM',
  'SHN',
  'MAF',
  'SPM',
  'TKL',
  'TUV',
  'VIR',
  'WLF',
  'ESH',
];

export const countries: Country[] = [
  {
    name: 'Afghanistan',
    code: 'AFG',
    codeAlt: 'AF',
  },
  {
    name: 'Åland',
    code: 'ALA',
    codeAlt: 'AX',
  },
  {
    name: 'Albania',
    code: 'ALB',
    codeAlt: 'AL',
  },
  {
    name: 'Algeria',
    code: 'DZA',
    codeAlt: 'DZ',
  },
  {
    name: 'American Samoa',
    code: 'ASM',
    codeAlt: 'AS',
  },
  {
    name: 'Andorra',
    code: 'AND',
    codeAlt: 'AD',
  },
  {
    name: 'Angola',
    code: 'AGO',
    codeAlt: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AIA',
    codeAlt: 'AI',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ATG',
    codeAlt: 'AG',
  },
  {
    name: 'Argentina',
    code: 'ARG',
    codeAlt: 'AR',
  },
  {
    name: 'Armenia',
    code: 'ARM',
    codeAlt: 'AM',
  },
  {
    name: 'Aruba Kingdom of the Netherlands',
    code: 'ABW',
    codeAlt: 'AW',
  },
  {
    name: 'Australia',
    code: 'AUS',
    codeAlt: 'AU',
  },
  {
    name: 'Austria',
    code: 'AUT',
    codeAlt: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: 'AZE',
    codeAlt: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BHS',
    codeAlt: 'BS',
  },
  {
    name: 'Bahrain',
    code: 'BHR',
    codeAlt: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BGD',
    codeAlt: 'BD',
  },
  {
    name: 'Barbados',
    code: 'BRB',
    codeAlt: 'BB',
  },
  {
    name: 'Belarus',
    code: 'BLR',
    codeAlt: 'BY',
  },
  {
    name: 'Belgium',
    code: 'BEL',
    codeAlt: 'BE',
  },
  {
    name: 'Belize',
    code: 'BLZ',
    codeAlt: 'BZ',
  },
  {
    name: 'Benin',
    code: 'BEN',
    codeAlt: 'BJ',
  },
  {
    name: 'Bermuda',
    code: 'BMU',
    codeAlt: 'BM',
  },
  {
    name: 'Bhutan',
    code: 'BTN',
    codeAlt: 'BT',
  },
  {
    name: 'Bolivia',
    code: 'BOL',
    codeAlt: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BIH',
    codeAlt: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BWA',
    codeAlt: 'BW',
  },
  {
    name: 'Brazil',
    code: 'BRA',
    codeAlt: 'BR',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BRN',
    codeAlt: 'BN',
  },
  {
    name: 'Bulgaria',
    code: 'BGR',
    codeAlt: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: 'BFA',
    codeAlt: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BDI',
    codeAlt: 'BI',
  },
  {
    name: 'Cabo Verde',
    code: 'CPV',
    codeAlt: 'CV',
  },
  {
    name: 'Cambodia',
    code: 'KHM',
    codeAlt: 'KH',
  },
  {
    name: 'Cameroon',
    code: 'CMR',
    codeAlt: 'CM',
  },
  {
    name: 'Canada',
    code: 'CAN',
    codeAlt: 'CA',
  },
  {
    name: 'Cayman Islands',
    code: 'CYM',
    codeAlt: 'KY',
  },
  {
    name: 'Central African Republic',
    code: 'CAF',
    codeAlt: 'CF',
  },
  {
    name: 'Chad',
    code: 'TCD',
    codeAlt: 'TD',
  },
  {
    name: 'Chile',
    code: 'CHL',
    codeAlt: 'CL',
  },
  {
    name: 'China',
    code: 'CHN',
    codeAlt: 'CN',
  },
  {
    name: 'Chinese Taipei',
    code: 'TWN',
    codeAlt: 'TW',
  },
  {
    name: 'Christmas Island',
    code: 'CXR',
    codeAlt: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CCK',
    codeAlt: 'CC',
  },
  {
    name: 'Colombia',
    code: 'COL',
    codeAlt: 'CO',
  },
  {
    name: 'Comoros',
    code: 'COM',
    codeAlt: 'KM',
  },
  {
    name: 'Congo',
    code: 'COG',
    codeAlt: 'CG',
  },
  {
    name: 'Cook Islands',
    code: 'COK',
    codeAlt: 'CK',
  },
  {
    name: 'Costa Rica',
    code: 'CRI',
    codeAlt: 'CR',
  },
  {
    name: "Côte d'Ivoire",
    code: 'CIV',
    codeAlt: 'CI',
  },
  {
    name: 'Croatia',
    code: 'HRV',
    codeAlt: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CUB',
    codeAlt: 'CU',
  },
  {
    name: 'Curaçao Kingdom of the Netherlands',
    code: 'CUW',
    codeAlt: 'CW',
  },
  {
    name: 'Cyprus',
    code: 'CYP',
    codeAlt: 'CY',
  },
  {
    name: 'Czech Republic',
    code: 'CZE',
    codeAlt: 'CZ',
  },
  {
    name: "Democratic People's Republic of Korea (DPRK)",
    code: 'PRK',
    codeAlt: 'KP',
  },
  {
    name: 'Democratic Republic of the Congo',
    code: 'COD',
    codeAlt: 'CD',
  },
  {
    name: 'Denmark',
    code: 'DNK',
    codeAlt: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJI',
    codeAlt: 'DJ',
  },
  {
    name: 'Dominica',
    code: 'DMA',
    codeAlt: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: 'DOM',
    codeAlt: 'DO',
  },
  {
    name: 'Ecuador',
    code: 'ECU',
    codeAlt: 'EC',
  },
  {
    name: 'Egypt',
    code: 'EGY',
    codeAlt: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SLV',
    codeAlt: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GNQ',
    codeAlt: 'GQ',
  },
  {
    name: 'Eritrea',
    code: 'ERI',
    codeAlt: 'ER',
  },
  {
    name: 'Estonia',
    code: 'EST',
    codeAlt: 'EE',
  },
  {
    name: 'Eswatini',
    code: 'SWZ',
    codeAlt: 'SZ',
  },
  {
    name: 'Ethiopia',
    code: 'ETH',
    codeAlt: 'ET',
  },
  {
    name: 'Falkland Islands',
    code: 'FLK',
    codeAlt: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: 'FRO',
    codeAlt: 'FO',
  },
  {
    name: 'Fiji',
    code: 'FJI',
    codeAlt: 'FJ',
  },
  {
    name: 'Finland',
    code: 'FIN',
    codeAlt: 'FI',
  },
  {
    name: 'France',
    code: 'FRA',
    codeAlt: 'FR',
  },
  {
    name: 'French Polynesia',
    code: 'PYF',
    codeAlt: 'PF',
  },
  {
    name: 'Gabon',
    code: 'GAB',
    codeAlt: 'GA',
  },
  {
    name: 'Gambia',
    code: 'GMB',
    codeAlt: 'GM',
  },
  {
    name: 'Georgia',
    code: 'GEO',
    codeAlt: 'GE',
  },
  {
    name: 'Germany',
    code: 'DEU',
    codeAlt: 'DE',
  },
  {
    name: 'Ghana',
    code: 'GHA',
    codeAlt: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GIB',
    codeAlt: 'GI',
  },
  {
    name: 'Greece',
    code: 'GRC',
    codeAlt: 'GR',
  },
  {
    name: 'Greenland',
    code: 'GRL',
    codeAlt: 'GL',
  },
  {
    name: 'Grenada',
    code: 'GRD',
    codeAlt: 'GD',
  },
  {
    name: 'Guam',
    code: 'GUM',
    codeAlt: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GTM',
    codeAlt: 'GT',
  },
  {
    name: 'Guernsey',
    code: 'GGY',
    codeAlt: 'GG',
  },
  {
    name: 'Guinea',
    code: 'GIN',
    codeAlt: 'GN',
  },
  {
    name: 'Guinea Bissau',
    code: 'GNB',
    codeAlt: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GUY',
    codeAlt: 'GY',
  },
  {
    name: 'Haiti',
    code: 'HTI',
    codeAlt: 'HT',
  },
  {
    name: 'Holy See',
    code: 'VAT',
    codeAlt: 'VA',
  },
  {
    name: 'Honduras',
    code: 'HND',
    codeAlt: 'HN',
  },
  {
    name: 'Hong Kong (China)',
    code: 'HKG',
    codeAlt: 'HK',
  },
  {
    name: 'Hungary',
    code: 'HUN',
    codeAlt: 'HU',
  },
  {
    name: 'Iceland',
    code: 'ISL',
    codeAlt: 'IS',
  },
  {
    name: 'India',
    code: 'IND',
    codeAlt: 'IN',
  },
  {
    name: 'Indonesia',
    code: 'IDN',
    codeAlt: 'ID',
  },
  {
    name: 'Iran',
    code: 'IRN',
    codeAlt: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IRQ',
    codeAlt: 'IQ',
  },
  {
    name: 'Ireland',
    code: 'IRL',
    codeAlt: 'IE',
  },
  {
    name: 'Isle of Man',
    code: 'IMN',
    codeAlt: 'IM',
  },
  {
    name: 'Israel',
    code: 'ISR',
    codeAlt: 'IL',
  },
  {
    name: 'Italy',
    code: 'ITA',
    codeAlt: 'IT',
  },
  {
    name: 'Jamaica',
    code: 'JAM',
    codeAlt: 'JM',
  },
  {
    name: 'Japan',
    code: 'JPN',
    codeAlt: 'JP',
  },
  {
    name: 'Jersey',
    code: 'JEY',
    codeAlt: 'JE',
  },
  {
    name: 'Jordan',
    code: 'JOR',
    codeAlt: 'JO',
  },
  {
    name: 'Kazakhstan',
    code: 'KAZ',
    codeAlt: 'KZ',
  },
  {
    name: 'Kenya',
    code: 'KEN',
    codeAlt: 'KE',
  },
  {
    name: 'Kiribati',
    code: 'KIR',
    codeAlt: 'KI',
  },
  {
    name: 'Korea',
    code: 'KOR',
    codeAlt: 'KR',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    codeAlt: 'XK',
  },
  {
    name: 'Kuwait',
    code: 'KWT',
    codeAlt: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KGZ',
    codeAlt: 'KG',
  },
  {
    name: "Lao People's Democratic Republic",
    code: 'LAO',
    codeAlt: 'LA',
  },
  {
    name: 'Latvia',
    code: 'LVA',
    codeAlt: 'LV',
  },
  {
    name: 'Lebanon',
    code: 'LBN',
    codeAlt: 'LB',
  },
  {
    name: 'Lesotho',
    code: 'LSO',
    codeAlt: 'LS',
  },
  {
    name: 'Liberia',
    code: 'LBR',
    codeAlt: 'LR',
  },
  {
    name: 'Libya',
    code: 'LBY',
    codeAlt: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LIE',
    codeAlt: 'LI',
  },
  {
    name: 'Lithuania',
    code: 'LTU',
    codeAlt: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LUX',
    codeAlt: 'LU',
  },
  {
    name: 'Macao (China)',
    code: 'MAC',
    codeAlt: 'MO',
  },
  {
    name: 'Madagascar',
    code: 'MDG',
    codeAlt: 'MG',
  },
  {
    name: 'Malawi',
    code: 'MWI',
    codeAlt: 'MW',
  },
  {
    name: 'Malaysia',
    code: 'MYS',
    codeAlt: 'MY',
  },
  {
    name: 'Maldives',
    code: 'MDV',
    codeAlt: 'MV',
  },
  {
    name: 'Mali',
    code: 'MLI',
    codeAlt: 'ML',
  },
  {
    name: 'Malta',
    code: 'MLT',
    codeAlt: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: 'MHL',
    codeAlt: 'MH',
  },
  {
    name: 'Mauritania',
    code: 'MRT',
    codeAlt: 'MR',
  },
  {
    name: 'Mauritius',
    code: 'MUS',
    codeAlt: 'MU',
  },
  {
    name: 'Mexico',
    code: 'MEX',
    codeAlt: 'MX',
  },
  {
    name: 'Micronesia',
    code: 'FSM',
    codeAlt: 'FM',
  },
  {
    name: 'Moldova',
    code: 'MDA',
    codeAlt: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MCO',
    codeAlt: 'MC',
  },
  {
    name: 'Mongolia',
    code: 'MNG',
    codeAlt: 'MN',
  },
  {
    name: 'Montenegro',
    code: 'MNE',
    codeAlt: 'ME',
  },
  {
    name: 'Montserrat',
    code: 'MSR',
    codeAlt: 'MS',
  },
  {
    name: 'Morocco',
    code: 'MAR',
    codeAlt: 'MA',
  },
  {
    name: 'Mozambique',
    code: 'MOZ',
    codeAlt: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MMR',
    codeAlt: 'MM',
  },
  {
    name: 'Namibia',
    code: 'NAM',
    codeAlt: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NRU',
    codeAlt: 'NR',
  },
  {
    name: 'Nepal',
    code: 'NPL',
    codeAlt: 'NP',
  },
  {
    name: 'Netherlands Kingdom of',
    code: 'NLD',
    codeAlt: 'NL',
  },
  {
    name: 'New Caledonia',
    code: 'NCL',
    codeAlt: 'NC',
  },
  {
    name: 'New Zealand',
    code: 'NZL',
    codeAlt: 'NZ',
  },
  {
    name: 'Nicaragua',
    code: 'NIC',
    codeAlt: 'NI',
  },
  {
    name: 'Niger',
    code: 'NER',
    codeAlt: 'NE',
  },
  {
    name: 'Nigeria',
    code: 'NGA',
    codeAlt: 'NG',
  },
  {
    name: 'Niue',
    code: 'NIU',
    codeAlt: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: 'NFK',
    codeAlt: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MNP',
    codeAlt: 'MP',
  },
  {
    name: 'Norway',
    code: 'NOR',
    codeAlt: 'NO',
  },
  {
    name: 'Oman',
    code: 'OMN',
    codeAlt: 'OM',
  },
  {
    name: 'Pakistan',
    code: 'PAK',
    codeAlt: 'PK',
  },
  {
    name: 'Palau',
    code: 'PLW',
    codeAlt: 'PW',
  },
  {
    name: 'Palestinian Authority',
    code: 'PSE',
    codeAlt: 'PS',
  },
  {
    name: 'Panama',
    code: 'PAN',
    codeAlt: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: 'PNG',
    codeAlt: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PRY',
    codeAlt: 'PY',
  },
  {
    name: 'Peru',
    code: 'PER',
    codeAlt: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PHL',
    codeAlt: 'PH',
  },
  {
    name: 'Pitcairn Islands',
    code: 'PCN',
    codeAlt: 'PN',
  },
  {
    name: 'Poland',
    code: 'POL',
    codeAlt: 'PL',
  },
  {
    name: 'Portugal',
    code: 'PRT',
    codeAlt: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: 'PRI',
    codeAlt: 'PR',
  },
  {
    name: 'Qatar',
    code: 'QAT',
    codeAlt: 'QA',
  },
  {
    name: 'Republic of North Macedonia',
    code: 'MKD',
    codeAlt: 'MK',
  },
  {
    name: 'Romania',
    code: 'ROU',
    codeAlt: 'RO',
  },
  {
    name: 'Russian Federation',
    code: 'RUS',
    codeAlt: 'RU',
  },
  {
    name: 'Rwanda',
    code: 'RWA',
    codeAlt: 'RW',
  },
  {
    name: 'Saint Barthélemy',
    code: 'BLM',
    codeAlt: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SHN',
    codeAlt: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KNA',
    codeAlt: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: 'LCA',
    codeAlt: 'LC',
  },
  {
    name: 'Saint Martin',
    code: 'MAF',
    codeAlt: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'SPM',
    codeAlt: 'PM',
  },
  {
    name: 'Saint Vincent & the Grenadines',
    code: 'VCT',
    codeAlt: 'VC',
  },
  {
    name: 'Samoa',
    code: 'WSM',
    codeAlt: 'WS',
  },
  {
    name: 'San Marino',
    code: 'SMR',
    codeAlt: 'SM',
  },
  {
    name: 'Sao Tomé and Principe',
    code: 'STP',
    codeAlt: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: 'SAU',
    codeAlt: 'SA',
  },
  {
    name: 'Senegal',
    code: 'SEN',
    codeAlt: 'SN',
  },
  {
    name: 'Serbia',
    code: 'SRB',
    codeAlt: 'RS',
  },
  {
    name: 'Seychelles',
    code: 'SYC',
    codeAlt: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SLE',
    codeAlt: 'SL',
  },
  {
    name: 'Singapore',
    code: 'SGP',
    codeAlt: 'SG',
  },
  {
    name: 'Sint Maarten Kingdom of the Netherlands',
    code: 'SXM',
    codeAlt: 'SX',
  },
  {
    name: 'Slovak Republic',
    code: 'SVK',
    codeAlt: 'SK',
  },
  {
    name: 'Slovenia',
    code: 'SVN',
    codeAlt: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: 'SLB',
    codeAlt: 'SB',
  },
  {
    name: 'Somalia',
    code: 'SOM',
    codeAlt: 'SO',
  },
  {
    name: 'South Africa',
    code: 'ZAF',
    codeAlt: 'ZA',
  },
  // https://stablehouse-workspace.slack.com/archives/C01T7NZSBC3/p1692801789448859?thread_ts=1692788888.177749&cid=C01T7NZSBC3
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'SGS',
    codeAlt: 'GS',
  },
  {
    name: 'South Sudan',
    code: 'SSD',
    codeAlt: 'SS',
  },
  {
    name: 'Spain',
    code: 'ESP',
    codeAlt: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: 'LKA',
    codeAlt: 'LK',
  },
  {
    name: 'Sudan',
    code: 'SDN',
    codeAlt: 'SD',
  },
  {
    name: 'Suriname',
    code: 'SUR',
    codeAlt: 'SR',
  },
  {
    name: 'Sweden',
    code: 'SWE',
    codeAlt: 'SE',
  },
  {
    name: 'Switzerland',
    code: 'CHE',
    codeAlt: 'CH',
  },
  {
    name: 'Syria',
    code: 'SYR',
    codeAlt: 'SY',
  },
  {
    name: 'Tajikistan',
    code: 'TJK',
    codeAlt: 'TJ',
  },
  {
    name: 'Tanzania',
    code: 'TZA',
    codeAlt: 'TZ',
  },
  {
    name: 'Thailand',
    code: 'THA',
    codeAlt: 'TH',
  },
  {
    name: 'Timor Leste',
    code: 'TLS',
    codeAlt: 'TL',
  },
  {
    name: 'Togo',
    code: 'TGO',
    codeAlt: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TKL',
    codeAlt: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TON',
    codeAlt: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TTO',
    codeAlt: 'TT',
  },
  {
    name: 'Tunisia',
    code: 'TUN',
    codeAlt: 'TN',
  },
  {
    name: 'Turkey',
    code: 'TUR',
    codeAlt: 'TR',
  },
  {
    name: 'Turkmenistan',
    code: 'TKM',
    codeAlt: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TCA',
    codeAlt: 'TC',
  },
  {
    name: 'Tuvalu',
    code: 'TUV',
    codeAlt: 'TV',
  },
  {
    name: 'U.S. Virgin Islands',
    code: 'VIR',
    codeAlt: 'VI',
  },
  {
    name: 'Uganda',
    code: 'UGA',
    codeAlt: 'UG',
  },
  {
    name: 'Ukraine',
    code: 'UKR',
    codeAlt: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: 'ARE',
    codeAlt: 'AE',
  },
  {
    name: 'United Kingdom',
    code: 'GBR',
    codeAlt: 'GB',
  },
  {
    name: 'United States of America',
    code: 'USA',
    codeAlt: 'US',
  },
  {
    name: 'Uruguay',
    code: 'URY',
    codeAlt: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: 'UZB',
    codeAlt: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: 'VUT',
    codeAlt: 'VU',
  },
  {
    name: 'Venezuela',
    code: 'VEN',
    codeAlt: 'VE',
  },
  {
    name: 'Vietnam',
    code: 'VNM',
    codeAlt: 'VN',
  },
  {
    name: 'Virgin Islands (UK)',
    code: 'VGB',
    codeAlt: 'VG',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WLF',
    codeAlt: 'WF',
  },
  {
    name: 'Western Sahara',
    code: 'ESH',
    codeAlt: 'EH',
  },
  {
    name: 'Yemen',
    code: 'YEM',
    codeAlt: 'YE',
  },
  {
    name: 'Zambia',
    code: 'ZMB',
    codeAlt: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZWE',
    codeAlt: 'ZW',
  },
].filter(({ code }) => !excludeList.includes(code));

export const countryNamesByCode: Record<string, string> = {};

countries.forEach(country => {
  countryNamesByCode[country.code] = country.name;
});

// https://stablehouse.atlassian.net/browse/SH-5228
// https://stablehouse-workspace.slack.com/archives/C01U8744400/p1701110961970589?thread_ts=1701097410.321539&cid=C01U8744400
// https://docs.google.com/spreadsheets/d/1dsFsdlMa9AVQjCMWFyXqRAAD4T-Ryk1Kfv3BjIhuiwo/edit#gid=1621133835
const authorizedCountryCodes = [
  'ALA',
  'ALB',
  'ASM',
  'AND',
  'AGO',
  'AIA',
  'ATG',
  'ARG',
  'ARM',
  'ABW',
  'AUS',
  'AUT',
  'AZE',
  'BHS',
  'BHR',
  'BRB',
  'BEL',
  'BLZ',
  'BEN',
  'BMU',
  'BTN',
  'BIH',
  'BWA',
  'BRA',
  'VGB',
  'BRN',
  'BGR',
  'BFA',
  'KHM',
  'CMR',
  'CAN',
  'CPV',
  'CYM',
  'CAF',
  'TCD',
  'CHL',
  'CXR',
  'CCK',
  'COL',
  'COM',
  'COK',
  'CRI',
  'HRV',
  'CUW',
  'CYP',
  'CZE',
  'DNK',
  'DJI',
  'DMA',
  'DOM',
  'COD',
  'TLS',
  'ECU',
  'SLV',
  'GNQ',
  'ERI',
  'EST',
  'SWZ',
  'ETH',
  'FLK',
  'FRO',
  'FJI',
  'FIN',
  'FRA',
  'PYF',
  'GAB',
  'GMB',
  'GEO',
  'DEU',
  'GHA',
  'GIB',
  'GRC',
  'GRL',
  'GRD',
  'GUM',
  'GTM',
  'GGY',
  'GIN',
  'GNB',
  'GUY',
  'HTI',
  'HND',
  'HKG',
  'HUN',
  'ISL',
  'IND',
  'IDN',
  'IRL',
  'IMN',
  'ISR',
  'ITA',
  'CIV',
  'JAM',
  'JPN',
  'JEY',
  'JOR',
  'KAZ',
  'KEN',
  'KIR',
  'XK',
  'KWT',
  'KGZ',
  'LAO',
  'LVA',
  'LBN',
  'LSO',
  'LBR',
  'LIE',
  'LTU',
  'LUX',
  'MAC',
  'MDG',
  'MWI',
  'MYS',
  'MDV',
  'MLI',
  'MLT',
  'MHL',
  'MRT',
  'MUS',
  'MEX',
  'FSM',
  'MDA',
  'MCO',
  'MNG',
  'MNE',
  'MSR',
  'MOZ',
  'NAM',
  'NRU',
  'NLD',
  'NCL',
  'NZL',
  'NIC',
  'NER',
  'NGA',
  'NIU',
  'NFK',
  'MKD',
  'MNP',
  'NOR',
  'OMN',
  'PAK',
  'PLW',
  'PSE',
  'PAN',
  'PNG',
  'PRY',
  'PER',
  'PHL',
  'PCN',
  'POL',
  'PRT',
  'PRI',
  'QAT',
  'ROU',
  'RUS',
  'RWA',
  'BLM',
  'SHN',
  'KNA',
  'LCA',
  'MAF',
  'SPM',
  'VCT',
  'WSM',
  'SMR',
  'STP',
  'SAU',
  'SEN',
  'SRB',
  'SYC',
  'SLE',
  'SGP',
  'SXM',
  'SVK',
  'SVN',
  'SLB',
  'SOM',
  'ZAF',
  'KOR',
  'SSD',
  'ESP',
  'LKA',
  'SUR',
  'SWE',
  'CHE',
  'TWN',
  'TJK',
  'TZA',
  'THA',
  'TGO',
  'TKL',
  'TON',
  'TTO',
  'TUN',
  'TUR',
  'TKM',
  'TCA',
  'TUV',
  'VIR',
  'UGA',
  'UKR',
  'ARE',
  'GBR',
  'USA',
  'URY',
  'UZB',
  'VUT',
  'VAT',
  'VEN',
  'VNM',
  'WLF',
  'ESH',
  'YEM',
  'ZMB',
  'ZWE',
].filter(i => !excludeList.includes(i));

export function isCountryAuthorized(countryCode: string) {
  return authorizedCountryCodes.includes(countryCode);
}

export const authorizedCountries = countries.filter(({ code }) =>
  authorizedCountryCodes.includes(code)
);
