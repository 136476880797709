export enum FilterField {
  accountId = 'accountId',
  dateRange = 'dateRange',
}

export enum DateFilterField {
  startDate = 'startDate',
  endDate = 'endDate',
}

export interface FilterProps {
  [FilterField.dateRange]: string;
  [DateFilterField.startDate]: Date | null;
  [DateFilterField.endDate]: Date | null;
  [FilterField.accountId]: string;
}
